import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { LoginModal } from './LoginModal';
import { useDisclosure } from '@chakra-ui/react';

interface AuthGuardProps {
  children: React.ReactNode;
  onlyTriggerWhenClicked?: boolean;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ children, onlyTriggerWhenClicked = false }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Move useEffect to the top, before any conditional returns
  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !onlyTriggerWhenClicked) {
      onOpen();
    }
  }, [isLoading, isAuthenticated, onOpen, onlyTriggerWhenClicked]);

  // Only check authentication when clicked if onlyTriggerWhenClicked is true
  const handleClick = (e: React.MouseEvent) => {
    if (!isAuthenticated && onlyTriggerWhenClicked) {
      e.preventDefault();
      e.stopPropagation();
      onOpen();
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div onClick={handleClick}>
        {children}
      </div>
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}; 