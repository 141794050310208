import React from "react";
import {
  Box,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Button,
  Skeleton,
  useToast,
  HStack,
  IconButton,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Select,
  Spacer,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { ApolloCache, gql, useMutation, useQuery } from "@apollo/client";
import { EditIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate, Link } from "react-router-dom";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { FaWallet, FaGlobe } from "react-icons/fa";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { NavigationButtons } from '../components/NavigationButtons';

enum ServiceType {
  HDWALLET = "HDWALLET",
  WEBSITE = "WEBSITE"
}

enum ServiceStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED"
}

enum WalletType {
  LEDGER = "LEDGER",
  METAMASK = "METAMASK",
  TREZOR = "TREZOR",
  OTHER = "OTHER"
}

enum StepStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED"
}

interface StepProgress {
  stepId: string;
  status: StepStatus;
  completedAt?: Date;
}

interface BaseService {
  id: string;
  type: ServiceType;
  serviceStartDate: Date;
  status: ServiceStatus | null;
  jobSummary?: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  client: Client | null;
  hdWalletService?: HDWalletServiceDetails;
  websiteService?: WebsiteServiceDetails;
}

interface Client {
  id: string;
  fName: string;
  lName: string;
  email: string;
  phoneNumber: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  services?: Service[];
}

interface HDWalletServiceDetails {
  id: string;
  type: ServiceType;
  startDate: Date;
  walletType: WalletType;
  currentStepId: string | null;
  completedAt?: Date;
  completionHash?: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  progress: StepProgress[];
}

interface WebsiteServiceDetails {
  id: string;
  type: ServiceType;
  websiteName?: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
}

type Service = BaseService;

const StatCard = ({ label, value, colorScheme = "blue" }: { label: string; value: number; colorScheme?: string }) => (
  <Box 
    p={4} 
    borderRadius="lg" 
    bg={`${colorScheme}.50`} 
    borderWidth="1px"
    borderColor={`${colorScheme}.200`}
  >
    <Text fontSize="sm" color={`${colorScheme}.600`}>{label}</Text>
    <Text fontSize="2xl" fontWeight="bold" color={`${colorScheme}.700`}>{value}</Text>
  </Box>
);

const GET_ALL_SERVICES = gql`
  query GetAllServices {
    services {
      id
      type
      serviceStartDate
      status
      jobSummary
      tenantId
      createdAt
      updatedAt
      client {
        id
        fName
        lName
        email
        phoneNumber
        tenantId
        createdAt
        updatedAt
      }
      hdWalletService {
        id
        type
        startDate
        walletType
        currentStepId
        completedAt
        completionHash
        tenantId
        createdAt
        updatedAt
        progress {
          stepId
          status
          completedAt
        }
      }
      websiteService {
        id
        type
        websiteName
        tenantId
        createdAt
        updatedAt
      }
    }
  }
`;

const DELETE_SERVICE = gql`
  mutation DeleteService($id: ID!) {
    deleteService(id: $id)
  }
`;

const UPDATE_SERVICE_STATUS = gql`
  mutation UpdateServiceStatus($id: ID!, $input: UpdateServiceStatusInput!) {
    updateServiceStatus(id: $id, input: $input) {
      id
      status
    }
  }
`;

const ServicesList = () => {
  const navigate = useNavigate();
  const { loading, data, refetch } = useQuery(GET_ALL_SERVICES);
  console.log('Raw services data to work with:', data);
  const toast = useToast();
  const [deleteServiceMutation, { loading: deleteLoading }] = useMutation(DELETE_SERVICE, {
    onCompleted: (data) => {
      if (data.deleteService) {
        toast({
          title: "Service deleted",
          description: "Service has been successfully removed.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        refetch();
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message || "Failed to delete service",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [serviceToDelete, setServiceToDelete] = React.useState<Service | null>(null);
  const [typeFilter, setTypeFilter] = React.useState<string>("ALL");
  const [statusFilter, setStatusFilter] = React.useState<string>("ALL");
  const [updateServiceStatus, { loading: updateStatusLoading }] = useMutation(UPDATE_SERVICE_STATUS);

  const formatDate = (dateString: string | Date | undefined | null) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleEdit = (service: Service) => {
    navigate(`/services/${service.id}`);
  };

  const handleDelete = (service: Service) => {
    setServiceToDelete(service);
    onOpen();
  }; 
  

  const handleViewService = (service: Service) => {
    if (service.type === "HDWALLET") {
      navigate(`/hdwalletservice/${service.id}`);
    } else if (service.type === "WEBSITE") {
      navigate(`/websiteservice/${service.id}`);
    } else {
      toast({
        title: "Error",
        description: "Unable to determine service type",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const confirmDelete = async () => {
    if (!serviceToDelete) return;

    try {
      await deleteServiceMutation({
        variables: { id: serviceToDelete.id },
      });
      
      onClose(); // Close the confirmation dialog
      setServiceToDelete(null);
    } catch (error) {
      console.error('Delete error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to delete service",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStatusChange = async (service: Service, newStatus: ServiceStatus) => {
    console.log('Attempting to update service:', {
      serviceId: service.id,
      currentStatus: service.status,
      newStatus: newStatus
    });

    try {
      const response = await updateServiceStatus({
        variables: {
          id: service.id,
          input: {
            status: newStatus as ServiceStatus
          }
        },
        refetchQueries: ['GetAllServices'],
        onCompleted: (data) => {
          console.log('Update successful:', data);
          toast({
            title: "Status updated",
            description: `Service status has been updated to ${data.updateServiceStatus.status}`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.error('Update failed:', error);
          toast({
            title: "Error updating status",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });

      console.log('Mutation response:', response);
    } catch (error) {
      console.error('Caught error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update status",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const filteredServices = React.useMemo(() => {
    if (!data?.services) return [];
    
    return data.services.filter((service: Service) => {
      const matchesType = typeFilter === "ALL" || service.type === typeFilter;
      const matchesStatus = statusFilter === "ALL" || service.status === statusFilter;
      return matchesType && matchesStatus;
    });
  }, [data?.services, typeFilter, statusFilter]);

  const getServiceStats = (services: Service[]) => {
    return {
      total: services.length,
      active: services.filter(s => s.status === "ACTIVE").length,
      pending: services.filter(s => s.status === "PENDING").length,
      completed: services.filter(s => s.status === "COMPLETED").length,
      hdWallets: services.filter(s => s.type === "HDWALLET").length,
      websites: services.filter(s => s.type === "WEBSITE").length,
    };
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        <Card>
          <CardHeader>
            <HStack 
              spacing={4} 
              align="center" 
              direction={{ base: "column", md: "row" }}
              width="100%"
            >
              <Heading size="lg">Services</Heading>
              <Spacer />
              <Stack 
                direction={{ base: "column", md: "row" }} 
                spacing={4} 
                width={{ base: "100%", md: "auto" }}
              >
                <Button
                  colorScheme="green"
                  leftIcon={<FaWallet />}
                  onClick={() => navigate('/service/hdwallet/new')}
                >
                  New HD Wallet Service
                </Button>
                <Button
                  colorScheme="purple"
                  leftIcon={<FaGlobe />}
                  onClick={() => navigate('/service/website/new')}
                >
                  New Website Service
                </Button>
                <Button
                  colorScheme="teal"
                  onClick={() => navigate('/newclient')}
                >
                  New Client
                </Button>
                <Select
                  width={{ base: "100%", md: "200px" }}
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e.target.value)}
                >
                  <option value="ALL">All Types</option>
                  <option value="HDWALLET">HD Wallet</option>
                  <option value="WEBSITE">Website</option>
                </Select>
                <Select
                  width={{ base: "100%", md: "200px" }}
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="ALL">All Statuses</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                  <option value="PENDING">Pending</option>
                  <option value="SUSPENDED">Suspended</option>
                  <option value="TERMINATED">Terminated</option>
                  <option value="COMPLETED">Completed</option>
                </Select>
              </Stack>
            </HStack>
          </CardHeader>

          <Box px={6} py={4}>
            <SimpleGrid 
              columns={{ base: 2, sm: 3, md: 4, lg: 6 }} 
              spacing={{ base: 2, md: 4 }}
            >
              {data?.services && (
                <>
                  <StatCard 
                    label="Total Services" 
                    value={getServiceStats(data.services).total} 
                    colorScheme="blue"
                  />
                  <StatCard 
                    label="Active Services" 
                    value={getServiceStats(data.services).active} 
                    colorScheme="green"
                  />
                  <StatCard 
                    label="Pending Services" 
                    value={getServiceStats(data.services).pending} 
                    colorScheme="orange"
                  />
                  <StatCard 
                    label="Completed Services" 
                    value={getServiceStats(data.services).completed} 
                    colorScheme="gray"
                  />
                  <StatCard 
                    label="HD Wallets" 
                    value={getServiceStats(data.services).hdWallets} 
                    colorScheme="purple"
                  />
                  <StatCard 
                    label="Websites" 
                    value={getServiceStats(data.services).websites} 
                    colorScheme="teal"
                  />
                </>
              )}
            </SimpleGrid>
          </Box>

          <CardBody>
            <Box overflowX="auto" maxWidth="100%">
              <Table variant="simple" size={{ base: "sm", md: "md" }}>
                <Thead>
                  <Tr>
                    <Th display={{ base: "none", md: "table-cell" }}>Service ID</Th>
                    <Th>Client</Th>
                    <Th>Type</Th>
                    <Th display={{ base: "none", lg: "table-cell" }}>Service Details</Th>
                    <Th display={{ base: "none", md: "table-cell" }}>Dates</Th>
                    <Th>Status</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    [...Array(3)].map((_, index) => (
                      <Tr key={`skeleton-${index}`}>
                        {[...Array(7)].map((_, cellIndex) => (
                          <Td key={cellIndex}><Skeleton height="20px" /></Td>
                        ))}
                      </Tr>
                    ))
                  ) : filteredServices.length ? (
                    filteredServices.map((service: Service) => (
                      <Tr key={service.id}>
                        <Td>
                          <Text fontSize="sm" color="gray.600">
                            ID: {service.id}
                            <br />
                            Tenant: {service.tenantId}
                          </Text>
                        </Td>
                        <Td>
                          <Box display={{ base: "none", md: "block" }}>
                            <Text fontSize="sm">
                              Name:{" "}
                              <Link
                                to={`/client/${service.client?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue.500', textDecoration: 'underline' }}
                              >
                                {service.client?.fName || 'N/A'} {service.client?.lName || 'N/A'}
                              </Link>
                              <br />
                              Email: {service.client?.email || 'N/A'}
                              <br />
                              Phone: {service.client?.phoneNumber || 'N/A'}
                            </Text>
                          </Box>
                          <Box display={{ base: "block", md: "none" }}>
                            <Link
                              to={`/client/${service.client?.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: 'blue.500', textDecoration: 'underline' }}
                            >
                              {service.client ? `${service.client.fName} ${service.client.lName}` : 'No Client'}
                            </Link>
                          </Box>
                        </Td>
                        <Td>
                          <HStack>
                            {service.type === "HDWALLET" ? (
                              <FaWallet title={service.hdWalletService?.walletType} />
                            ) : (
                              <FaGlobe title={service.websiteService?.websiteName} />
                            )}
                            <Text>{service.type}</Text>
                          </HStack>
                        </Td>
                        <Td>
                          {service.type === "HDWALLET" && service.hdWalletService ? (
                            <Text fontSize="sm">
                              Wallet Type: {service.hdWalletService.walletType || 'N/A'}
                              <br />
                              Current Step: {service.hdWalletService.currentStepId || 'N/A'}
                              <br />
                              Completion Hash: {service.hdWalletService.completionHash || 'N/A'}
                              <br />
                              Progress: {service.hdWalletService.progress.length} steps
                              {service.jobSummary && (
                                <>
                                  <br />
                                  Summary: {service.jobSummary}
                                </>
                              )}
                            </Text>
                          ) : service.type === "WEBSITE" && service.websiteService ? (
                            <Text fontSize="sm">
                              Website Name: {service.websiteService.websiteName || 'N/A'}
                              {service.jobSummary && (
                                <>
                                  <br />
                                  Summary: {service.jobSummary}
                                </>
                              )}
                            </Text>
                          ) : (
                            <Text color="gray.500">No service details</Text>
                          )}
                        </Td>
                        <Td>
                          <Text fontSize="sm">
                            Start: {formatDate(service.serviceStartDate)}
                            <br />
                            Created: {formatDate(service.createdAt)}
                            <br />
                            Updated: {formatDate(service.updatedAt)}
                            {service.hdWalletService?.completedAt && (
                              <>
                                <br />
                                Completed: {formatDate(service.hdWalletService.completedAt)}
                              </>
                            )}
                          </Text>
                        </Td>
                        <Td>
                          <Select
                            size="sm"
                            width="140px"
                            value={service.status || ""}
                            onChange={(e) => handleStatusChange(service, e.target.value as ServiceStatus)}
                            isDisabled={updateStatusLoading}
                          >
                            {Object.values(ServiceStatus).map((status) => (
                              <option key={status} value={status}>
                                {status}
                              </option>
                            ))}
                          </Select>
                        </Td>
                        <Td>
                          <HStack spacing={2}>
                            <IconButton
                              aria-label="Edit service"
                              icon={<EditIcon />}
                              size="sm"
                              colorScheme="blue"
                              onClick={() => handleEdit(service)}
                            />
                            <IconButton
                              aria-label="Delete service"
                              icon={<DeleteIcon />}
                              size="sm"
                              colorScheme="red"
                              onClick={() => handleDelete(service)}
                            />
                            <IconButton
                              aria-label="View service"
                              icon={<ViewIcon />}
                              size="sm"
                              colorScheme="teal"
                              onClick={() => handleViewService(service)}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={7} textAlign="center" py={8}>
                        <Text color="gray.500">No services found matching the selected filters</Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </CardBody>
        </Card>
        <FooterWithFourColumns />
      </Container>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Service
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this service? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmDelete}
                ml={3}
                isLoading={deleteLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ServicesList;
