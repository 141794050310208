import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Container,
  Grid,
  Image,
  Text,
  Button,
  Heading,
  Stack,
  HStack,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  Badge,
  Card,
  CardBody,
  IconButton,
  Skeleton,
  useToast,
  SimpleGrid,
  Tag,
  TagLabel,
  Flex,
  Spacer,
  Center,
  Spinner,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { SearchIcon, EditIcon, ViewIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { JWTDebugger } from '../components/JWTDebugger';
import { AuthGuard } from '../components/AuthGuard';
import { motion } from 'framer-motion';


const GET_PRODUCTS = gql`
  query AllPublicProducts($filter: ProductFilterInput) {
    allPublicProducts(filter: $filter) {
      id
      name
      price
      images
      isBestSeller
      isFeatured
      variants {
        price
        title
      }
      categories
      tags
      stockLevel
      isActive
      seller {
        id
        businessName
      }
      tenantId
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
`;

interface ProductVariant {
  price: number;
  title: string;
}

interface Product {
  id: string;
  name: string;
  price: number;
  images: string[];
  isBestSeller: boolean;
  isFeatured: boolean;
  variants: ProductVariant[];
  categories: string[];
  tags: string[];
  stockLevel: number;
  isActive: boolean;
  seller: {
    businessName: string | null;
  };
  tenantId: string;
}

interface PriceRange {
  min: number;
  max: number;
}

interface ProductFilterInput {
  categories?: string[];
  priceRange?: PriceRange;
}

const ProductCard = ({ product }: { product: Product }) => {
  const navigate = useNavigate();
  const mainImage = product.images?.[0] || 'https://via.placeholder.com/300';
  const lowestVariantPrice = product.variants?.length
    ? Math.min(...product.variants.map(v => v.price))
    : product.price;
  const toast = useToast();

  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  const handleDelete = async (productId: string) => {
    try {
      const { data } = await deleteProduct({
        variables: { id: productId },
        update(cache) {
          const existingProducts = cache.readQuery<{ products: Product[] }>({
            query: GET_PRODUCTS
          });

          if (existingProducts) {
            cache.writeQuery({
              query: GET_PRODUCTS,
              data: {
                products: existingProducts.products.filter(
                  product => product.id !== productId
                )
              }
            });
          }
        }
      });

      if (data.deleteProduct) {
        toast({
          title: "Product deleted successfully",
          status: "success",
          duration: 3000,
        });
      }
    } catch (error) {
      toast({
        title: "Error deleting product",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 5000,
      });
    }
  };

  return (
    <Card
      overflow="hidden"
      variant="outline"
      transition="all 0.3s ease"
      _hover={{
        transform: "translateY(-4px)",
        boxShadow: "xl",
        borderColor: "blue.200"
      }}
      position="relative"
      bg="white"
      borderRadius="2xl"
      height={{ base: "auto", md: "600px" }} 
       display="flex"
      flexDirection="column"
    >
      <Box position="relative" overflow="hidden">
        <Image
         src={mainImage}
         alt={product.name}
         height="100%"
         width="100%"
         objectFit="cover"
         bg="gray.50"
         transition="transform 0.3s ease"
         _hover={{ transform: "scale(1.05)" }}
         fallback={<Skeleton height="100%" />}
        />
        <HStack
          position="absolute"
          top={2}
          right={2}
          spacing={2}
          zIndex={2}
        >
          {product.isFeatured && (
            <Badge
              colorScheme="purple"
              borderRadius="full"
              px={3}
              py={1}
              backdropFilter="blur(4px)"
              bg="rgba(128, 90, 213, 0.9)"
            >
              Featured
            </Badge>
          )}
          {product.isBestSeller && (
            <Badge
              colorScheme="green"
              borderRadius="full"
              px={3}
              py={1}
              backdropFilter="blur(4px)"
              bg="rgba(72, 187, 120, 0.9)"
            >
              Best Seller
            </Badge>
          )}
        </HStack>
      </Box>

      <CardBody flex="1" display="flex" flexDirection="column"> 
        <VStack spacing={4} align="stretch" height="100%">  
          <VStack align="stretch" spacing={2}>
            <Heading
              size="md"
              noOfLines={2}
              _hover={{ color: "blue.500" }}
              cursor="pointer"
              onClick={() => navigate(`/products/${product.id}`)}
            >
              {product.name}
            </Heading>

            <HStack justify="space-between" align="center">
              <Box>
                <Text
                  fontWeight="bold"
                  fontSize="xl"
                  color="blue.600"
                >
                  ${product.price.toFixed(2)}
                </Text>
                {lowestVariantPrice !== product.price && (
                  <Text fontSize="sm" color="gray.500">
                    From ${lowestVariantPrice.toFixed(2)}
                  </Text>
                )}
              </Box>
              <Badge
                colorScheme={product.isActive ? "green" : "red"}
                variant="subtle"
                borderRadius="full"
                px={3}
                py={1}
              >
                {product.isActive ? "Active" : "Inactive"}
              </Badge>
            </HStack>
          </VStack>

          {product.variants?.length > 0 && (
            <Text
              fontSize="sm"
              color="gray.600"
              bg="gray.50"
              p={2}
              borderRadius="md"
            >
              {product.variants.length} variants available
            </Text>
          )}

          <Box>
            <HStack flexWrap="wrap" spacing={2}>
              {product.categories?.slice(0, 2).map((category, idx) => (
                <Tag
                  size="sm"
                  key={idx}
                  colorScheme="blue"
                  borderRadius="full"
                  variant="subtle"
                >
                  <TagLabel>{category}</TagLabel>
                </Tag>
              ))}
            </HStack>
          </Box>

          <Divider />

          <HStack spacing={2} justify="space-between">
            <Text fontSize="sm" color="gray.600">
              Tenant: {product.tenantId}
            </Text>
            <Text fontSize="sm" color="gray.600">
              Seller: {product.seller?.businessName || 'Unknown'}
            </Text>
          </HStack>

          <HStack spacing={2}>
            <Button
              size="sm"
              leftIcon={<ViewIcon />}
              onClick={() => navigate(`/products/${product.id}`)}
              flex={1}
              colorScheme="blue"
              variant="solid"
              _hover={{
                transform: "translateY(-1px)",
                boxShadow: "sm"
              }}
            >
              View
            </Button>
            <IconButton
              aria-label="Edit product"
              icon={<EditIcon />}
              size="sm"
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate(`/products/${product.id}`)}
              _hover={{
                transform: "translateY(-1px)",
                boxShadow: "sm"
              }}
            />
            <IconButton
              aria-label="Delete product"
              icon={<DeleteIcon />}
              size="sm"
              colorScheme="red"
              variant="ghost"
              onClick={() => handleDelete(product.id)}
              _hover={{
                transform: "translateY(-1px)",
                bg: "red.50"
              }}
            />
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

const AllProducts = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isAuthenticated, isLoading: authLoading } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [sortBy, setSortBy] = useState('name');
  const [expiryCountdown, setExpiryCountdown] = useState<string>('');
  const [loginTime, setLoginTime] = useState<string>('');
  const [totalDuration, setTotalDuration] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [priceRange, setPriceRange] = useState<PriceRange>({ min: 0, max: 100 });

  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: {
      filter: categoryFilter !== 'all' ? { categories: [categoryFilter] } : undefined
    }
  });

  const allCategories = useMemo(() => {
    if (!data?.allPublicProducts) return [];
    const categoriesSet = new Set<string>();
    data.allPublicProducts.forEach((product: Product) => {
      product.categories?.forEach(category => categoriesSet.add(category));
    });
    return ['all', ...Array.from(categoriesSet)];
  }, [data]);

  const filteredAndSortedProducts = useMemo(() => {
    if (!data?.allPublicProducts) return [];

    let filtered = [...data.allPublicProducts];

    if (searchTerm) {
      filtered = filtered.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.tags?.some((tag: string) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    filtered.sort((a, b) => {
      switch (sortBy) {
        case 'price-low':
          return (a.price || 0) - (b.price || 0);
        case 'price-high':
          return (b.price || 0) - (a.price || 0);
        case 'stock':
          return (b.stockLevel || 0) - (a.stockLevel || 0);
        default:
          return a.name.localeCompare(b.name);
      }
    });

    return filtered;
  }, [data, searchTerm, sortBy]);



  useEffect(() => {
    const updateCountdown = () => {
      const token = localStorage.getItem('auth_token');
      if (token) {
        const decoded = jwtDecode<{ exp: number; iat: number }>(token);
        const expiryTime = decoded.exp * 1000; // Convert to milliseconds
        const issuedTime = decoded.iat * 1000; // Convert to milliseconds
        const now = Date.now();
        const diff = expiryTime - now;

        // Calculate total duration
        const totalDurationMs = expiryTime - issuedTime;
        const totalDays = Math.floor(totalDurationMs / (1000 * 60 * 60 * 24));
        setTotalDuration(`${totalDays} days`);

        // Format login time
        setLoginTime(new Date(issuedTime).toLocaleString());

        // Calculate countdown
        if (diff > 0) {
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          setExpiryCountdown(`${hours} hours, ${minutes} minutes`);
        } else {
          setExpiryCountdown('Expired');
        }
      }
    };

    // Update immediately
    updateCountdown();

    // Then update every minute
    const interval = setInterval(updateCountdown, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAdmin(storedPassword === "123456");
  }, []);

  if (authLoading) {
    return (
      <Box>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Center h="50vh">
            <Spinner size="xl" />
          </Center>
        </Container>
        <FooterWithFourColumns />
      </Box>
    );
  }



  return (
    // <AuthGuard>
      <Box minH="100vh" display="flex" flexDirection="column">
        <NavbarWithCallToAction />

        <Box
          bgGradient="linear(to-br, blue.50, purple.50)"
          minH="100vh"
          py={8}
        >
          <Container maxW="container.xl">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Stack spacing={8}>
                {/* Header Section */}
                <Card
                  bg="rgba(255, 255, 255, 0.9)"
                  backdropFilter="blur(10px)"
                  borderRadius="2xl"
                  boxShadow="0 8px 32px rgba(0, 0, 0, 0.12)"
                  border="1px solid"
                  borderColor="gray.100"
                  overflow="hidden"
                  transition="all 0.2s"
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "xl"
                  }}
                >
                  <CardBody>
                    <Stack spacing={6}>
                      <HStack justify="space-between" wrap="wrap" spacing={4}>
                        <VStack align="start" spacing={2}>
                          <Text
                            fontSize="sm"
                            color="gray.500"
                            textTransform="uppercase"
                            letterSpacing="wider"
                          >
                            Product Catalog
                          </Text>
                          <Heading size="lg">All Products</Heading>
                        </VStack>
                        <Button
                          leftIcon={<AddIcon />}
                          colorScheme="blue"
                          onClick={() => navigate('/products/new')}
                          size="lg"
                        >
                          Add New Physical Product
                        </Button>
                      </HStack>

                      {/* Search and Filter Section */}
                      <Grid
                        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
                        gap={4}
                      >
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                          </InputLeftElement>
                          <Input
                            placeholder="Search products..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            bg="white"
                            _hover={{ borderColor: "blue.400" }}
                          />
                        </InputGroup>

                        <Select
                          value={categoryFilter}
                          onChange={(e) => setCategoryFilter(e.target.value)}
                          bg="white"
                          _hover={{ borderColor: "blue.400" }}
                        >
                          {allCategories.map(category => (
                            <option key={category} value={category}>
                              {category.charAt(0).toUpperCase() + category.slice(1)}
                            </option>
                          ))}
                        </Select>

                        <Select
                          value={sortBy}
                          onChange={(e) => setSortBy(e.target.value)}
                          bg="white"
                          _hover={{ borderColor: "blue.400" }}
                        >
                          <option value="name">Name</option>
                          <option value="price-low">Price: Low to High</option>
                          <option value="price-high">Price: High to Low</option>
                          <option value="stock">Stock Level</option>
                        </Select>
                      </Grid>

                      <Grid templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }} gap={4}>
                        <HStack>
                          <Input
                            type="number"
                            placeholder="Min Price"
                            value={priceRange.min}
                            onChange={(e) => setPriceRange(prev => ({ ...prev, min: Number(e.target.value) }))}
                          />
                          <Input
                            type="number"
                            placeholder="Max Price"
                            value={priceRange.max}
                            onChange={(e) => setPriceRange(prev => ({ ...prev, max: Number(e.target.value) }))}
                          />
                        </HStack>
                      </Grid>
                    </Stack>
                  </CardBody>
                </Card>

                {/* Products Grid */}
                <Card
                  bg="rgba(255, 255, 255, 0.9)"
                  backdropFilter="blur(10px)"
                  borderRadius="2xl"
                  boxShadow="0 8px 32px rgba(0, 0, 0, 0.12)"
                  border="1px solid"
                  borderColor="gray.100"
                >
                  <CardBody>
                    {loading ? (
                      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                        {[...Array(6)].map((_, i) => (
                          <Skeleton key={i} height="400px" />
                        ))}
                      </SimpleGrid>
                    ) : (
                      <>
                        {filteredAndSortedProducts.length > 0 ? (
                          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                            {filteredAndSortedProducts.map((product: Product) => (
                              <motion.div
                                key={product.id}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.4 }}
                              >
                                <ProductCard product={product} />
                              </motion.div>
                            ))}
                          </SimpleGrid>
                        ) : (
                          <Box textAlign="center" py={10}>
                            <Text fontSize="lg" color="gray.500">
                              No products found matching your criteria
                            </Text>
                          </Box>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>

                {/* JWT Debugger Section */}
                <Card
                  bg="rgba(255, 255, 255, 0.9)"
                  backdropFilter="blur(10px)"
                  borderRadius="2xl"
                  boxShadow="0 8px 32px rgba(0, 0, 0, 0.12)"
                  border="1px solid"
                  borderColor="gray.100"
                >
                  <CardBody>
                    <JWTDebugger />
                  </CardBody>
                </Card>
              </Stack>
            </motion.div>
          </Container>
        </Box>

        <FooterWithFourColumns />
      </Box>
    // </AuthGuard>
  );
};

export default AllProducts; 