import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Button,
} from '@chakra-ui/react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_MEMBERS_COUNT = gql`
  query GetClients {
    clients {
      id
    }
  }
`;

const Welcome = () => {
  const navigate = useNavigate();
  const { data } = useQuery(GET_MEMBERS_COUNT);
  const memberNumber = data?.clients?.length || 0;

  return (
    <Box minHeight="100vh">
      <NavbarWithCallToAction />
      <Container maxW="container.md" py={20}>
        <VStack spacing={10} textAlign="center">
          <Heading size="2xl">
            Welcome to Life Essentials Club!
          </Heading>
          
          <Text fontSize="xl" color="blue.600" fontWeight="bold">
            You are member #{memberNumber}
          </Text>
          
          <Text fontSize="lg">
            Congratulations on becoming one of our founding members! 
            Your lifetime membership has been secured.
          </Text>

          <Button
            size="lg"
            colorScheme="blue"
            onClick={() => navigate('/products')}
          >
            Start Exploring Products
          </Button>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </Box>
  );
};

export default Welcome; 