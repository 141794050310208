import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Box,
  Select,
  Textarea,
  HStack,
  Image,
  IconButton,
  SimpleGrid,
  Switch,
  FormHelperText,
  Stack,
  Progress,
  Text,
} from '@chakra-ui/react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CloseIcon } from '@chakra-ui/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface PhotoUpload {
  url: string;
  description?: string;
  fileType?: string;
}

enum TaskStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

const CREATE_TASK = gql`
  mutation CreateTask($input: TaskInput!) {
    createTask(input: $input) {
      id
      description
      status
      projectId
      assignedTo {
        id
        email
      }
      media {
        url
        description
        fileType
      }
      billed
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $input: TaskInput!) {
    updateTask(id: $id, input: $input) {
      id
      description
      status
      projectId
      assignedTo {
        id
        email
      }
      media {
        url
        description
        fileType
      }
      billed
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadToPinata(file: $file)
  }
`;

const GET_CLIENTS = gql`
  query GetClients {
    clients {
      id
      fName
      lName
      email
      businessName
    }
  }
`;

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  onTaskUpdated?: () => void;
  existingTask?: {
    id: string;
    description: string;
    status: string;
    assignedTo?: { id: string }[];
    media?: {  // Changed from photos to media
        url: string;
        description?: string;
        fileType: string;  // Add this field
    }[];
    billed: boolean;
  };
}

const isPDF = (url: string): boolean => {
  return url.endsWith('.pdf') || 
         url.includes('application/pdf') || 
         url.includes('%2Fpdf') ||  // URL encoded /pdf
         url.toLowerCase().includes('pdf');
};

export const TaskModal: React.FC<TaskModalProps> = ({ 
  isOpen, 
  onClose, 
  projectId, 
  onTaskUpdated,
  existingTask 
}) => {
  // Add console log to debug initial state
  console.log('TaskModal initialization:', {
    existingTask,
    isNewTask: !existingTask
  });

  const defaultClientId = '6760ec476be9cc253e78285c';
  const [assignedToIds, setAssignedToIds] = useState<string[]>(() => {
    if (existingTask?.assignedTo) {
      return existingTask.assignedTo.map(user => user.id);
    }
    // If no existing task (new task mode), use default client ID
    return [defaultClientId];
  });

  const [description, setDescription] = useState(existingTask?.description || '');
  const [status, setStatus] = useState(existingTask?.status || 'PENDING');
  const [photos, setPhotos] = useState<PhotoUpload[]>(
    existingTask?.media || []
  );
  const [billed, setBilled] = useState(existingTask?.billed || false);
  const toast = useToast();

  const [createTask, { loading: createLoading }] = useMutation(CREATE_TASK);
  const [updateTask, { loading: updateLoading }] = useMutation(UPDATE_TASK);
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Get clients for dropdown
  const { data: clientsData, loading: clientsLoading } = useQuery(GET_CLIENTS);

  // Add state for PDF preview
  const [selectedPDF, setSelectedPDF] = useState<PhotoUpload | null>(null);

  // Reset form when modal opens/closes or when existingTask changes
  useEffect(() => {
    if (existingTask) {
      setDescription(existingTask.description);
      setStatus(existingTask.status);
      setAssignedToIds(existingTask.assignedTo?.map(user => user.id) || []);
      setPhotos(existingTask.media?.map(media => ({
        url: media.url,
        description: media.description || '',
        fileType: media.fileType || 'image/*'
      })) || []);
      setBilled(existingTask.billed);
    } else {
      setDescription('');
      setStatus('PENDING');
      setAssignedToIds([defaultClientId]); // Set default client ID for new tasks
      setPhotos([]);
      setBilled(false);
    }
    setSelectedPDF(null);
  }, [existingTask, isOpen]);

  // Add logging for component lifecycle and state changes
  useEffect(() => {
    console.log('TaskModal - Component mounted or updated');
    console.log('Current photos:', photos);
    console.log('Existing task:', existingTask);
  }, [photos, existingTask]);

  const handleSubmit = async () => {
    try {
      const input = {
        description: description.trim(),
        projectId,
        status: status as TaskStatus,
        assignedTo: assignedToIds.length > 0 ? assignedToIds : undefined,
        media: photos.length > 0 ? photos.map(photo => ({
          url: photo.url,
          description: photo.description || undefined,
          fileType: photo.fileType || 'image/*'
        })) : undefined,
        billed: billed || undefined
      };

      console.log(`${existingTask ? 'Updating' : 'Creating'} task with input:`, input);

      const { data } = existingTask 
        ? await updateTask({
            variables: { 
              id: existingTask.id,
              input 
            }
          })
        : await createTask({
            variables: { input }
          });

      toast({
        title: `Task ${existingTask ? 'updated' : 'created'}`,
        status: "success",
        duration: 3000,
      });
      onTaskUpdated?.();
      handleClose();
    } catch (error) {
      console.error(`${existingTask ? 'Update' : 'Create'} task error:`, error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : `Failed to ${existingTask ? 'update' : 'create'} task`,
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleClose = () => {
    setDescription('');
    setStatus('PENDING');
    setAssignedToIds([]);
    setPhotos([]);
    setBilled(false);
    setSelectedPDF(null); // Reset PDF preview
    onClose();
  };

  // Add logging to file upload handler
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;

    const file = e.target.files[0];
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/pdf',
      'video/mp4'
    ];

    if (!allowedTypes.includes(file.type)) {
      toast({
        title: "Invalid file type",
        description: "Please upload an image, PDF, or MP4 file",
        status: "error",
        duration: 3000,
      });
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 95) {
            clearInterval(progressInterval);
            return prev;
          }
          return prev + 5;
        });
      }, 100);

      const { data } = await uploadFile({
        variables: { file }
      });

      clearInterval(progressInterval);
      setUploadProgress(100);

      if (data.uploadToPinata) {
        const newPhoto = { 
          url: data.uploadToPinata, 
          description: '',
          fileType: file.type
        };
        setPhotos([...photos, newPhoto]);
        toast({
          title: "File uploaded",
          status: "success",
          duration: 3000,
        });
      }
    } catch (error) {
      console.error('File upload error:', error);
      toast({
        title: "Upload failed",
        description: error instanceof Error ? error.message : "Failed to upload file",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handlePhotoDescriptionChange = (index: number, description: string) => {
    const newPhotos = [...photos];
    newPhotos[index].description = description;
    setPhotos(newPhotos);
  };

  const removePhoto = (index: number) => {
    setPhotos(photos.filter((_, i) => i !== index));
  };

  // Add logging for PDF rendering
  const renderFilePreview = (photo: PhotoUpload, index: number) => {
    console.log('Rendering file preview:', {
      url: photo.url,
      fileType: photo.fileType,
      isPDF: photo.fileType === 'application/pdf' || photo.url.toLowerCase().includes('pdf'),
      index
    });

    if (photo.fileType === 'application/pdf' || photo.url.toLowerCase().includes('pdf')) {
      return (
        <Box 
          maxH="100px" 
          overflow="hidden"
          cursor="pointer"
          onClick={() => setSelectedPDF(photo)}
        >
          <Document
            file={photo.url}
            onLoadSuccess={({ numPages }) => {
              console.log('PDF loaded successfully:', { url: photo.url, numPages });
            }}
          >
            <Page pageNumber={1} height={100} scale={0.5} />
          </Document>
        </Box>
      );
    }

    // Add video handling
    if (photo.fileType === 'video/mp4' || photo.url.endsWith('.mp4')) {
      return (
        <Box maxH="100px" overflow="hidden">
          <video
            src={photo.url}
            controls
            style={{ width: '100%', maxHeight: '100px', objectFit: 'contain' }}
          >
            Your browser does not support the video tag.
          </video>
        </Box>
      );
    }

    return (
      <Box maxH="100px" overflow="hidden">
        <Image
          src={photo.url}
          alt={`Upload ${index + 1}`}
          maxH="100px"
          objectFit="contain"
        />
      </Box>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{existingTask ? 'Edit Task' : 'Add New Task'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter task description"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="PENDING">Pending</option>
                <option value="IN_PROGRESS">In Progress</option>
                <option value="COMPLETED">Completed</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Assign To</FormLabel>
              <Stack direction="row" spacing={4}>
                <Select
                  value={assignedToIds[0] || ''}
                  onChange={(e) => setAssignedToIds(e.target.value ? [e.target.value] : [])}
                  placeholder="Select client"
                  isDisabled={clientsLoading}
                >
                  {clientsData?.clients?.map((client: any) => (
                    <option key={client.id} value={client.id}>
                      {client.businessName ? `${client.businessName} - ` : ''}
                      {client.fName} {client.lName} ({client.email})
                    </option>
                  ))}
                </Select>
                <Button
                  colorScheme="green"
                  onClick={() => {/* Add navigation to new client page */}}
                >
                  New Client
                </Button>
              </Stack>
            </FormControl>

            <FormControl>
              <FormLabel>Task Evidence</FormLabel>
              <Input
                type="file"
                accept="image/*,video/mp4,application/pdf"
                onChange={handleFileUpload}
                disabled={isUploading}
              />
              <FormHelperText>
                Upload images, videos, or PDFs as proof of work (screenshots, recordings, documents, etc.)
              </FormHelperText>
              {isUploading && (
                <Box mt={2}>
                  <Text>Uploading... {uploadProgress.toFixed(2)}%</Text>
                  <Progress value={uploadProgress} size="sm" colorScheme="blue" />
                </Box>
              )}
            </FormControl>

            {photos.length > 0 && (
              <SimpleGrid columns={2} spacing={4} w="full">
                {photos.map((photo, index) => (
                  <Box key={index} borderWidth={1} p={2} borderRadius="md">
                    {renderFilePreview(photo, index)}
                    <Input
                      mt={2}
                      size="sm"
                      placeholder="File description"
                      value={photo.description}
                      onChange={(e) => handlePhotoDescriptionChange(index, e.target.value)}
                    />
                    <IconButton
                      aria-label="Remove file"
                      icon={<CloseIcon />}
                      size="sm"
                      position="absolute"
                      right={2}
                      top={2}
                      onClick={() => removePhoto(index)}
                    />
                  </Box>
                ))}
              </SimpleGrid>
            )}

            <FormControl>
              <FormLabel>Billed</FormLabel>
              <Switch
                isChecked={billed}
                onChange={(e) => setBilled(e.target.checked)}
              />
              <FormHelperText>Mark if this task has been billed</FormHelperText>
            </FormControl>

            <HStack spacing={4} width="full" justify="flex-end">
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={createLoading || updateLoading}
                disabled={!description}
              >
                {existingTask ? 'Update Task' : 'Create Task'}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
      {selectedPDF && (
        <Modal isOpen={!!selectedPDF} onClose={() => setSelectedPDF(null)} size="6xl">
          <ModalOverlay />
          <ModalContent maxW="90vw" maxH="90vh">
            <ModalCloseButton zIndex="popover" />
            <ModalBody p={0}>
              <Box height="85vh" overflow="auto">
                <Document file={selectedPDF.url}>
                  <Page
                    pageNumber={1}
                    width={window.innerWidth * 0.8}
                  />
                </Document>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Modal>
  );
}; 