import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

interface JWTPayload {
  email: string;
  exp: number;
}

export const useAuth = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      try {
        const decoded = jwtDecode<JWTPayload>(token);
        if (decoded.exp * 1000 > Date.now()) {
          setEmail(decoded.email);
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('auth_token');
          setEmail(null);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('auth_token');
      }
    }
    setIsLoading(false);
  }, []);

  const logout = () => {
    localStorage.removeItem('auth_token');
    setEmail(null);
    setIsAuthenticated(false);
  };

  return {
    email,
    isAuthenticated,
    isLoading,
    logout
  };
}; 