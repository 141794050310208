import { Box, Center, Container, Stack, Text, Button, Collapse } from '@chakra-ui/react'
import { Step } from './Step'
import { steps, Step as StepType, StepStatus } from './data'
import { useStep } from './useStep'
import { useState } from 'react'
import { StepStatus as StepStatusEnum } from './data'

interface VerticalProgressBarProps {
  steps: StepType[];
  onStepStatusUpdate: (stepId: string) => void;
  currentStepId?: string;
  isAuthorized: boolean;
}

export const VerticalProgressBar: React.FC<VerticalProgressBarProps> = ({
  steps,
  onStepStatusUpdate,
  currentStepId,
  isAuthorized
}) => {
  const [currentStep, { setStep }] = useStep({ maxStep: steps.length, initialStep: 0 })
  const [expandedStep, setExpandedStep] = useState<string | null>(null)

  const handleMarkAsComplete = (stepId: string) => {
    onStepStatusUpdate(stepId);
  };

  const toggleStep = (stepId: string) => {
    setExpandedStep(expandedStep === stepId ? null : stepId)
  }

  return (
    <Box bg="bg.surface">
      <Container py={{ base: '4', md: '8' }}>
        <Center>
          <Stack spacing="0" width="full">
            {steps.map((step: StepType, id: number) => (
              <Stack key={id} width="full">
                <Step
                  step={step}
                  cursor={isAuthorized ? "pointer" : "not-allowed"}
                  onClick={() => toggleStep(step.id)}
                  isActive={currentStep === id}
                  isCompleted={step.status === StepStatusEnum.COMPLETED}
                  isLastStep={steps.length === id + 1}
                  _hover={isAuthorized ? { bg: "gray.50" } : undefined}
                />
                
                <Collapse in={expandedStep === step.id} animateOpacity>
                  <Box 
                    ml={16}
                    p={4}
                    bg="white"
                    borderRadius="md"
                    borderWidth="1px"
                    shadow="sm"
                  >
                    <Stack spacing={4}>
                      <Text>{step.description}</Text>
                      
                      {step.status !== StepStatusEnum.COMPLETED && (
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => handleMarkAsComplete(step.id)}
                          opacity={isAuthorized ? 1 : 0.7}
                          cursor={isAuthorized ? "pointer" : "not-allowed"}
                        >
                          Mark as Complete
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Collapse>
              </Stack>
            ))}
          </Stack>
        </Center>
      </Container>
    </Box>
  )
}
