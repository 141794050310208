import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { FooterWithFourColumns } from "../chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavbarWithCallToAction } from "../chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { VerticalProgressBar } from "../chakra/VerticalProgressBar/VerticalProgressBar";
import { steps, StepStatus } from "../chakra/VerticalProgressBar/data";

const GET_SERVICE_PROGRESS = gql`
  query GetServiceProgress($id: ID!) {
    service(id: $id) {
      ... on HDWalletService {
        id
        currentStepId
        progress {
          stepId
          status
          completedAt
        }
        completionHash
        completedAt
        verificationStatus
      }
    }
  }
`;

const COMPLETE_STEP = gql`
  mutation CompleteStep($serviceId: ID!, $input: CompleteStepInput!) {
    completeStep(serviceId: $serviceId, input: $input) {
      id
      type
      currentStepId
      progress {
        stepId
        status
        completedAt
      }
    }
  }
`;

interface HDWalletServiceProps {
  service: {
    id: string;
    client: {
      fName: string;
      lName: string;
      email: string;
    };
    serviceStartDate: string;
  };
}

export const HDWalletServiceComponent: React.FC<HDWalletServiceProps> = ({ service }) => {
  const toast = useToast();
  const [isAuthorized, setIsAuthorized] = useState(false);
  
  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAuthorized(storedPassword === "123456");
  }, []);

  const { data, loading, error, refetch } = useQuery(GET_SERVICE_PROGRESS, {
    variables: { id: service.id },
    fetchPolicy: "network-only",
  });

  const [completeStep] = useMutation(COMPLETE_STEP);

  const handleStepStatusUpdate = async (stepId: string) => {
    if (!isAuthorized) {
      toast({
        title: 'Action Not Permitted',
        description: 'This step can only be completed by your service provider. Please contact them for assistance.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const input = {
        stepId,
        stepStatus: StepStatus.COMPLETED
      };

      console.log('Submitting mutation with variables:', {
        serviceId: service.id,
        input
      });

      const response = await completeStep({
        variables: {
          serviceId: service.id,
          input
        }
      });

      console.log('Mutation response:', response);

      refetch();

      toast({
        title: 'Step completed',
        description: 'Progress has been updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

    } catch (error: unknown) {
      console.error('Error completing step:', {
        error,
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
        serviceId: service.id,
        input: {
          stepId,
          stepStatus: StepStatus.COMPLETED
        }
      });
      
      toast({
        title: 'Error',
        description: 'Failed to complete step',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const mappedSteps = steps.map((step) => {
    const stepProgress = data?.service?.progress?.find(
      (p: any) => p.stepId === step.id
    );
    return {
      ...step,
      status: stepProgress?.status || StepStatus.PENDING,
      completedDate: stepProgress?.completedAt,
    };
  });

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        <Card>
          <CardHeader>
            <Heading size="lg">HD Wallet Service Details</Heading>
          </CardHeader>
          <CardBody>
            <Stack spacing={4}>
              <Box>
                <Heading size="sm">Client</Heading>
                <Text>{`${service.client.fName} ${service.client.lName}`}</Text>
              </Box>
              <Box>
                <Heading size="sm">Start Date</Heading>
                <Text>{new Date(service.serviceStartDate).toLocaleDateString()}</Text>
              </Box>
              
              {loading ? (
                <Text>Loading progress...</Text>
              ) : error ? (
                <Text color="red.500">Error loading progress</Text>
              ) : (
                <VerticalProgressBar
                  steps={mappedSteps}
                  onStepStatusUpdate={handleStepStatusUpdate}
                  currentStepId={data?.service?.currentStepId}
                  isAuthorized={isAuthorized}
                />
              )}
            </Stack>
          </CardBody>
        </Card>
        <FooterWithFourColumns />
      </Container>
    </>
  );
}; 