// components/chakra/NavbarWithCallToAction/NavbarWithCallToAction.tsx

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Logo } from '../../Logo';
import { MobileDrawer } from './MobileNavbar';
import { ToggleButton } from './ToggleButton';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const NavbarWithCallToAction = () => {
  const isDesktop = useBreakpointValue({ base: false, '3xl': true });
  const mobileNavbar = useDisclosure();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAuthorized(storedPassword === "123456");
  }, []);

  const handleAuthAction = async () => {
    if (user) {
      await logout();
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Box>
      <Box borderBottomWidth="1px" bg="bg.surface" position="relative" zIndex="tooltip">
        <Container py="4" maxW="6xl">
          <HStack justify="space-between">
            <Box onClick={handleLogoClick} cursor="pointer">
              <Logo />
            </Box>
            <ToggleButton
              onClick={mobileNavbar.onToggle}
              isOpen={mobileNavbar.isOpen}
              aria-label="Open Menu"
            />
            <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
          </HStack>
        </Container>
      </Box>
    </Box>
  );
};