import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    SimpleGrid,
    Icon,
    Button,
    Flex,
} from "@chakra-ui/react";
import { FaCode, FaMobile, FaRocket, FaSearch } from "react-icons/fa";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";

export const CustomWebsiteDevelopment = () => {
    return (
        <Flex direction="column" minHeight="100vh" bg="gray.50">
            <NavbarWithCallToAction />

            <Container maxW="6xl" py={20}>
                <VStack spacing={12} align="stretch">
                    {/* Hero Section */}
                    <Box 
                        textAlign="center" 
                        mb={10}
                        p={8}
                        backdropFilter="blur(10px)"
                        borderRadius="2xl"
                        bg="rgba(255, 255, 255, 0.8)"
                        boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                    >
                        <Heading 
                            as="h1" 
                            size="2xl" 
                            mb={6}
                            bgGradient="linear(to-r, #007AFF, #5856D6)"
                            bgClip="text"
                        >
                            Custom Website Development
                        </Heading>
                        <Text fontSize="xl" color="gray.700" mb={8}>
                            Building modern, responsive, and scalable websites tailored to your business needs
                        </Text>
                    </Box>

                    {/* Services Grid */}
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                        {/* Feature 1 */}
                        <Box
                            p={8}
                            bg="white"
                            borderRadius="xl"
                            boxShadow="md"
                            _hover={{ transform: "translateY(-4px)", transition: "0.3s" }}
                        >
                            <Icon as={FaCode} w={10} h={10} color="blue.500" mb={4} />
                            <Heading size="md" mb={4}>Modern Technologies</Heading>
                            <Text color="gray.600">
                                Built with React, Next.js, and other cutting-edge frameworks to ensure 
                                optimal performance and maintainability.
                            </Text>
                        </Box>

                        {/* Feature 2 */}
                        <Box
                            p={8}
                            bg="white"
                            borderRadius="xl"
                            boxShadow="md"
                            _hover={{ transform: "translateY(-4px)", transition: "0.3s" }}
                        >
                            <Icon as={FaMobile} w={10} h={10} color="blue.500" mb={4} />
                            <Heading size="md" mb={4}>Responsive Design</Heading>
                            <Text color="gray.600">
                                Websites that look and function perfectly on all devices, from mobile 
                                phones to desktop computers.
                            </Text>
                        </Box>

                        {/* Feature 3 */}
                        <Box
                            p={8}
                            bg="white"
                            borderRadius="xl"
                            boxShadow="md"
                            _hover={{ transform: "translateY(-4px)", transition: "0.3s" }}
                        >
                            <Icon as={FaSearch} w={10} h={10} color="blue.500" mb={4} />
                            <Heading size="md" mb={4}>SEO Optimized</Heading>
                            <Text color="gray.600">
                                Built with search engine optimization in mind to help your website 
                                rank higher in search results.
                            </Text>
                        </Box>

                        {/* Feature 4 */}
                        <Box
                            p={8}
                            bg="white"
                            borderRadius="xl"
                            boxShadow="md"
                            _hover={{ transform: "translateY(-4px)", transition: "0.3s" }}
                        >
                            <Icon as={FaRocket} w={10} h={10} color="blue.500" mb={4} />
                            <Heading size="md" mb={4}>Performance First</Heading>
                            <Text color="gray.600">
                                Optimized for speed and performance to provide the best possible 
                                user experience.
                            </Text>
                        </Box>
                    </SimpleGrid>

                    {/* CTA Section */}
                    <Box 
                        textAlign="center" 
                        py={10}
                        p={8}
                        backdropFilter="blur(10px)"
                        bg="rgba(255, 255, 255, 0.8)"
                        borderRadius="2xl"
                        boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                    >
                        <Heading size="lg" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                            Ready to Start Your Project?
                        </Heading>
                        <Text mb={6} color="gray.700">
                            Let's discuss your website development needs and create something amazing together.
                        </Text>
                        <Button 
                            bgGradient="linear(to-r, #007AFF, #5856D6)"
                            _hover={{
                                bgGradient: "linear(to-r, #0066CC, #4845AA)",
                            }}
                            color="white"
                            size="lg"
                            as="a" 
                            href="https://calendly.com/lifeessentialsclub-tom/free-consulting-call"
                            target="_blank"
                            rel="noopener noreferrer"
                            boxShadow="0 4px 12px rgba(0, 122, 255, 0.3)"
                            borderRadius="xl"
                        >
                            Schedule a Consultation
                        </Button>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Flex>
    );
};

export default CustomWebsiteDevelopment; 