import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Center
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Logo } from '../../Logo'
// import { links } from './_data'
import InviteLink from '../../../components/InviteLink';
import { useAuth } from '../../../contexts/AuthContext';
import { CartPreview } from '../../CartPreview';






export const FooterWithFourColumns = () => {
  const { user } = useAuth();


  const links = [
    {
      // title: 'Company',
      links: [

        // { label: 'New Website', href: '/newwebsite' },
        // { label: 'Websites', href: '/websites' },
        // { label: 'Services', href: '/services' },
        // { label: 'New Service', href: '/services/new' },
        ...(user ? [{ label: 'Marketplace', href: '/marketplace' }] : []),
        ...(user ? [{ label: 'My Purchases', href: '/mybtcpurchases' }] : []),
        ...(user ? [{ label: 'View Trial Membership', href: '/trialmembership' }] : []),
        ...(user ? [{ label: 'Add Product', href: '/addproduct' }] : []),
        // { label: 'Careers', href: '#' },
        // { label: 'Press', href: '#' },
        // { label: 'FAQs', href: '/earn' },
      ],
    },
    {
      // title: 'Product',
      links: [
        { label: 'Admin', href: '/admin' },
        // { label: 'New Client', href: '/newclient' },
        // { label: 'Our Clients', href: '/clients' },
        // ...(user ? [{ label: 'How it Works', href: '/earn' }] : []),
        // ...(user ? [{ label: 'Our Locations', href: '/our-location-hubs' }] : []),
        ...(user ? [{ label: 'Our Producers', href: '/producers' }] : []),
        ...(user ? [{ label: 'Our Impact', href: '/farm-sales-dashboard' }] : []),
        ...(user ? [{ label: 'Earn With Us', href: '/earn' }] : []),
        // { label: 'Cultivating Resiliance', href: '/cultivating-resilience-infographic' },
        // ...(user ? [{ label: 'Our Impact', href: '/farm-sales-dashboard' }] : []),
        // { label: 'Get Started', href: 'start' },
        // { label: 'Use Cases', href: '/earn' },
        // { label: 'Integrations', href: '#' },
        // { label: 'SAML SSO', href: '#' },
      ],
    },
    {
      title: 'Resources',
      links: [
        { label: 'Blog', href: 'https://blog.lifeessentials.club' },
        ...(user ? [{ label: 'News', href: '/news' }] : []),
        ...(user ? [{ label: 'SATS=>AUD', href: 'https://coincodex.com/convert/satoshi-sats/aud/1000/' }] : []),
        // { label: 'Case studies', href: '#' },
        // { label: 'Media Assets', href: '#' },
      ],
    },
    {
      title: 'Admin',
      links: [
        ...(user?.permissions?.includes('admin') ? [{ label: 'All Users (admin)', href: '/allusers' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: 'All Purchases (admin)', href: '/alluserspurchases' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: 'Node Info (admin)', href: '/nodeinfo' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: 'BTCPay Invoices', href: '/btcpayinvoices' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: 'All Orders', href: '/allorders' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '3 Day Challenge', href: '/3daychallenge' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '1 Lead Magnet', href: '/leadmagnet' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '2 Webinar Invite', href: '/relationships/free-event' }] : []),
        ...(user?.permissions?.includes('admin') ? [{ label: '3 Sales Page', href: '/courses/relationships/start' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: '2 Webinar Sales Page', href: '/relationships/free-event' }] : []),
        // ...(user?.permissions?.includes('admin') ? [{ label: 'Relationship Registrations', href: '/admin/relationshipcourseregistrations' }] : []),
        // { label: 'Privacy Policy', href: '#' },
        // { label: 'Offer terms', href: '#' },
        // { label: 'License', href: '#' },
        { label: 'About Tom', href: '/about/tom' },
      ],
    },
  ]



  return (<>

    <Box bg="bg.surface" m={4}>
      {/* <InviteLink /> */}
      <Container as="footer" role="contentinfo" maxW="6xl" >
        <Stack
          justify="space-between"
          align="start"
          direction={{ base: 'column', lg: 'row' }}
          py={{ base: '12', md: '16' }}
          spacing="4"
        >

          <Stack spacing={{ base: '3', md: '8' }} align="start">

            <Logo />
           
          </Stack>
          <SimpleGrid columns={{ base: 2, md: 4 }} gap="8" width={{ base: 'full', lg: 'auto' }}>
            {links.map((group, idx) => (
              <Stack key={idx} spacing="3" minW={{ lg: '40' }}>

                <Stack spacing="3" shouldWrapChildren>

                  {group.links.map((link, idx, title) => (<>

                    <Button key={idx} as="a" variant="text" colorScheme="gray" href={link.href} size="xs">
                      {link.label}
                    </Button>

                  </>

                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
        <Divider />
        <Stack
          pt="8"
          pb="12"
          justify="space-between"
          direction={{ base: 'column-reverse', md: 'row' }}
          align="center"
        >

          <Box w="full">
            <Center w="full">
              <Stack spacing={2} textAlign="center" w="full">
                <Text fontSize="sm" color="fg.subtle">
                  &copy; {new Date().getFullYear()} Life Essential Services
                </Text>
                <Text fontSize="sm" color="gray.600">
                  Blockchain Solutions • Web Development • Supply Chain Innovation
                </Text>
                <Text fontSize="xs" color="gray.500">
                  Based in Australia • Serving Clients Globally
                </Text>
              </Stack>
            </Center>
          </Box>
          {/* <ButtonGroup variant="tertiary">
            <IconButton as="a" href="https://x.com/ThriveResilient" target="_blank" aria-label="Twitter" icon={<FaTwitter />} />
          </ButtonGroup> */}
        </Stack>
      </Container>
    </Box>

    <Box
      position="fixed"
      bottom={4}
      right={4}
      zIndex={999}
    >
      <CartPreview />
    </Box>
  </>
  )
}