import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Select,
    Stack,
    Textarea,
    useToast,
    VStack,
    useColorModeValue,
} from "@chakra-ui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NavbarWithCallToAction } from "../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { motion } from "framer-motion";

// Query to get all clients for the dropdown
const GET_CLIENTS = gql`
  query GetClients {
    clients {
      id
      fName
      lName
      email
    }
  }
`;

// Mutation to create a new project
const CREATE_PROJECT = gql`
  mutation CreateProject($input: ProjectInput!) {
    createProject(input: $input) {
      id
      projectName
      projectGoal
      billingClient {
        id
        fName
        lName
        email
      }
      members {
        id
      }
      tasks {
        id
        description
        status
        assignedTo {
          id
        }
        media {
          url
          description
        }
        billed
      }
      createdAt
      updatedAt
    }
  }
`;

interface FormData {
    projectName: string;
    projectGoal: string;
    clientId: string;
}

const NewProject = () => {
    const [searchParams] = useSearchParams();
    const clientId = searchParams.get('clientId');
    
    // Modify initial form data to include clientId from URL
    const initialFormData: FormData = {
        projectName: "",
        projectGoal: "",
        clientId: clientId || "", // Pre-select client if ID exists
    };

    const [formData, setFormData] = useState<FormData>(initialFormData);
    const navigate = useNavigate();
    const toast = useToast();

    // Color mode values
    const bgGradient = useColorModeValue(
        'linear(to-br, gray.50, white)',
        'linear(to-br, gray.900, gray.800)'
    );
    const cardBg = useColorModeValue('white', 'gray.800');

    // Get clients for dropdown
    const { data: clientsData, loading: clientsLoading } = useQuery(GET_CLIENTS);

    // Create project mutation
    const [createProject, { loading: createLoading }] = useMutation(CREATE_PROJECT, {
        onCompleted: (data) => {
            console.log('Mutation completed successfully:', data);
            toast({
                title: "Project Created",
                description: "New project has been created successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate(`/project/${data.createProject.id}`);
        },
        onError: (error) => {
            console.error('Detailed mutation error:', {
                message: error.message,
                graphQLErrors: error.graphQLErrors,
                networkError: error.networkError,
                extraInfo: error.extraInfo,
                clientErrors: error.clientErrors
            });

            // More user-friendly error message
            const errorMessage = error.graphQLErrors?.[0]?.extensions?.validationErrors
                ? JSON.stringify(error.graphQLErrors[0].extensions.validationErrors, null, 2)
                : error.message;

            toast({
                title: "Error creating project",
                description: errorMessage,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    // Add useEffect to set client when data loads
    useEffect(() => {
        if (clientId && clientsData?.clients) {
            setFormData(prev => ({
                ...prev,
                clientId: clientId
            }));
        }
    }, [clientId, clientsData]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!formData.clientId) {
            toast({
                title: "Error",
                description: "Please select a client",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        // Enhanced logging before submission
        console.log('=== Project Submission ===');
        console.log('Form Data:', {
            raw: formData,
            processed: {
                projectName: formData.projectName,
                projectGoal: formData.projectGoal,
                billingClient: formData.clientId,
            }
        });

        try {
            const mutationInput = {
                projectName: formData.projectName,
                projectGoal: formData.projectGoal,
                billingClient: formData.clientId,
            };

            console.log('Mutation Input:', mutationInput);

            const response = await createProject({
                variables: {
                    input: mutationInput
                },
            });

            console.log('Mutation Response:', response);

        } catch (error: any) {
            console.error("Error creating project:", {
                error,
                formData,
                errorType: error.constructor.name,
                errorStack: error.stack
            });
        }
    };

    return (
        <>
            <NavbarWithCallToAction />
            <Box
                bgGradient={bgGradient}
                minH="100vh"
                py={8}
            >
                <Container maxW="container.md">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Card
                            bg={cardBg}
                            backdropFilter="blur(10px)"
                            borderRadius="2xl"
                            boxShadow={`0 8px 32px rgba(0, 0, 0, 0.12)`}
                            border="1px solid"
                            borderColor="gray.100"
                            overflow="hidden"
                            transition="all 0.2s"
                            _hover={{
                                transform: "translateY(-2px)",
                                boxShadow: "xl"
                            }}
                        >
                            <CardHeader>
                                <VStack align="start" spacing={2}>
                                    <Heading size="lg">Create New Project</Heading>
                                </VStack>
                            </CardHeader>

                            <CardBody>
                                <form onSubmit={handleSubmit}>
                                    <Stack spacing={6}>
                                        <FormControl isRequired>
                                            <FormLabel>Client</FormLabel>
                                            <Stack direction="row" spacing={4}>
                                                <Select
                                                    name="clientId"
                                                    value={formData.clientId}
                                                    onChange={handleChange}
                                                    placeholder="Select client"
                                                    isDisabled={clientsLoading}
                                                >
                                                    {clientsData?.clients?.map((client: any) => (
                                                        <option key={client.id} value={client.id}>
                                                            {client.fName} {client.lName} ({client.email})
                                                        </option>
                                                    ))}
                                                </Select>
                                                <Button
                                                    colorScheme="green"
                                                    onClick={() => navigate('/newclient')}
                                                >
                                                    New Client
                                                </Button>
                                            </Stack>
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel>Project Name</FormLabel>
                                            <Input
                                                name="projectName"
                                                value={formData.projectName}
                                                onChange={handleChange}
                                                placeholder="Enter project name"
                                            />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel>Project Goal</FormLabel>
                                            <Textarea
                                                name="projectGoal"
                                                value={formData.projectGoal}
                                                onChange={handleChange}
                                                placeholder="Enter project goal"
                                                rows={4}
                                            />
                                        </FormControl>

                                        <Button
                                            type="submit"
                                            colorScheme="blue"
                                            isLoading={createLoading}
                                            size="lg"
                                            width="full"
                                        >
                                            Create Project
                                        </Button>
                                    </Stack>
                                </form>
                            </CardBody>
                        </Card>
                    </motion.div>
                </Container>
            </Box>
            <FooterWithFourColumns />
        </>
    );
};

export default NewProject; 