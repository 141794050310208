import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    Select,
    Stack,
    Container,
    useToast,
    Input,
    Textarea,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";

// Query to get all clients for the dropdown
const GET_CLIENTS = gql`
  query GetClients {
    clients {
      id
      fName
      lName
    }
  }
`;

// Mutation to create a new website service
const CREATE_WEBSITE_SERVICE = gql`
  mutation CreateWebsiteService($input: AddServiceInput!, $clientId: ID!) {
    addService(input: $input, clientId: $clientId) {
      id
      type
      websiteService {
        id
        websiteName
        createdAt
        updatedAt
      }
      serviceStartDate
      status
      createdAt
      updatedAt
    }
  }
`;

interface FormData {
    clientId: string;
    websiteName: string;
    serviceStartDate: string;
    jobSummary: string;
}

const initialFormData: FormData = {
    clientId: "",
    websiteName: "",
    serviceStartDate: new Date().toISOString().split('T')[0],
    jobSummary: "",
};

const NewWebsiteService = () => {
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const navigate = useNavigate();
    const toast = useToast();

    const { data: clientsData, loading: clientsLoading } = useQuery(GET_CLIENTS);

    const [addWebsiteService, { loading: createLoading }] = useMutation(CREATE_WEBSITE_SERVICE, {
        refetchQueries: ['GetServices'],
        onCompleted: (data) => {
            toast({
                title: "Website Service Created",
                description: `New website service has been created successfully`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate("/services");
        },
        onError: (error) => {
            toast({
                title: "Error creating service",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!formData.clientId) {
            toast({
                title: "Error",
                description: "Please select a client",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const serviceInput = {
            type: "WEBSITE" as const,
            serviceStartDate: new Date(formData.serviceStartDate).toISOString(),
            websiteName: formData.websiteName,
            status: "PENDING" as const,
            jobSummary: formData.jobSummary || undefined
        };

        try {
            await addWebsiteService({
                variables: {
                    input: serviceInput,
                    clientId: formData.clientId,
                },
            });
        } catch (error) {
            console.error("Error creating website service:", error);
        }
    };

    return (<>
        <NavbarWithCallToAction />
        <Container maxW="container.md" py={8}>
            <Card>
                <CardHeader>
                    <Heading size="lg">New Website Service</Heading>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={4}>
                            <FormControl isRequired>
                                <FormLabel>Client</FormLabel>
                                <Stack direction="row" spacing={4}>
                                    <Select
                                        name="clientId"
                                        value={formData.clientId}
                                        onChange={handleChange}
                                        placeholder="Select client"
                                        isDisabled={clientsLoading}
                                    >
                                        {clientsData?.clients?.map((client: any) => (
                                            <option key={client.id} value={client.id}>
                                                {client.fName} {client.lName}
                                            </option>
                                        ))}
                                    </Select>
                                    <Button
                                        colorScheme="green"
                                        onClick={() => navigate('/newclient')}
                                    >
                                        New Client
                                    </Button>
                                </Stack>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Website Name</FormLabel>
                                <Input
                                    name="websiteName"
                                    value={formData.websiteName}
                                    onChange={handleChange}
                                    placeholder="Enter website name"
                                />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Service Start Date</FormLabel>
                                <Input
                                    type="date"
                                    name="serviceStartDate"
                                    value={formData.serviceStartDate}
                                    onChange={handleChange}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Job Summary</FormLabel>
                                <Textarea
                                    name="jobSummary"
                                    value={formData.jobSummary}
                                    onChange={handleChange}
                                    placeholder="Enter job summary (optional)"
                                    rows={4}
                                    resize="vertical"
                                />
                            </FormControl>

                            <Button
                                type="submit"
                                colorScheme="blue"
                                isLoading={createLoading}
                            >
                                Create Website Service
                            </Button>
                        </Stack>
                    </form>
                </CardBody>
            </Card>

            </Container>
            <FooterWithFourColumns />
        </>
    );
};

export default NewWebsiteService;
