import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Text,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Skeleton,
  Alert,
  AlertIcon,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  SimpleGrid,
  Switch,
  useToast,
  useClipboard,
  IconButton,
  Button,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { CopyIcon, CheckIcon, EditIcon } from '@chakra-ui/icons';
// import { LoginModal } from '../components/LoginModal';

enum BillStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT'
}

const GET_BILL = gql`
  query BillById($id: ID!) {
    billById(id: $id) {
      id
      isPaid
      status
      lineItems {
        id
        description
        amount
        createdAt
        updatedAt
      }
      tenantId
      createdAt
      updatedAt
    }
  }
`;

const TOGGLE_BILL_PAYMENT_STATUS = gql`
  mutation ToggleBillPaymentStatus($id: ID!) {
    toggleBillPaymentStatus(id: $id) {
      id
      isPaid
    }
  }
`;

const UPDATE_BILL_STATUS = gql`
  mutation UpdateBillStatus($id: ID!, $status: BillStatus!) {
    updateBillStatus(id: $id, status: $status) {
      id
      status
      lineItems {
        id
        description
        amount
      }
      isPaid
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_LINE_ITEM = gql`
  mutation UpdateLineItem($billId: ID!, $lineItemId: ID!, $amount: Float, $description: String) {
    updateLineItem(
      billId: $billId
      lineItemId: $lineItemId
      amount: $amount
      description: $description
    ) {
      id
      lineItems {
        id
        description
        amount
        createdAt
        updatedAt
      }
      status
      isPaid
      updatedAt
    }
  }
`;

const GET_CLIENT_BY_BILL = gql`
  query GetClientDetailsByBillId($billId: ID!) {
    getClientDetailsByBillId(billId: $billId) {
      id
      fName
      lName
      email
      phoneNumber
    }
  }
`;

const BillDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useQuery(GET_BILL, {
    variables: { id },
  });

  const { data: clientData, loading: clientLoading } = useQuery(GET_CLIENT_BY_BILL, {
    variables: { billId: id },
  });

  console.log('Raw data:', data);
  console.log('Bill:', data?.billById);
  console.log('Line Items:', data?.billById?.lineItems);

  const client = useApolloClient();
  const toast = useToast();
  const { onCopy } = useClipboard('');

  const {
    onCopy: onCopyBsb,
    hasCopied: bsbCopied
  } = useClipboard("772-772");

  const {
    onCopy: onCopyAccNumber,
    hasCopied: accNumberCopied
  } = useClipboard("703051490");

  const {
    onCopy: onCopyAccountName,
    hasCopied: accountNameCopied
  } = useClipboard("Thomas Miller");

  const [updateBillStatus] = useMutation(UPDATE_BILL_STATUS);
  const [updateLineItem] = useMutation(UPDATE_LINE_ITEM);

  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [editValues, setEditValues] = React.useState<{
    description: string;
    amount: number;
  }>({ description: '', amount: 0 });

  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  // React.useEffect(() => {
  //   const authToken = localStorage.getItem('auth_token');
  //   if (!authToken) {
  //     onOpen();
  //   } else {
  //     setIsAuthenticated(true);
  //   }
  // }, [onOpen]);

  const handleTogglePayment = async (billId: string) => {
    try {
      await client.mutate({
        mutation: TOGGLE_BILL_PAYMENT_STATUS,
        variables: { id: billId },
        refetchQueries: [{ query: GET_BILL, variables: { id: billId } }]
      });

      toast({
        title: `Bill marked as ${bill.isPaid ? 'unpaid' : 'paid'}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error toggling payment status:', error);
      toast({
        title: 'Error updating payment status',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSendBill = async () => {
    try {
      await updateBillStatus({
        variables: {
          id: bill.id,
          status: BillStatus.SENT
        },
        refetchQueries: [{ query: GET_BILL, variables: { id: bill.id } }]
      });
      
      toast({
        title: 'Bill sent successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error sending bill',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <VStack spacing={6}>
            <Skeleton height="60px" width="100%" />
            <Skeleton height="200px" width="100%" />
            <Skeleton height="300px" width="100%" />
          </VStack>
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Alert status="error">
            <AlertIcon />
            Error loading bill details: {error.message}
          </Alert>
        </Container>
      </>
    );
  }

  const bill = data?.billById;
  if (!bill) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Alert status="info">
            <AlertIcon />
            Bill not found
          </Alert>
        </Container>
      </>
    );
  }

  const formatDate = (dateString: string | { $date: string }) => {
    const date = dateString instanceof Object ? dateString.$date : dateString;
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });
  };

  return (
    <>
      <NavbarWithCallToAction />
      {/* {!isAuthenticated ? (
        <>
          <Container maxW="container.xl" py={8}>
            <Alert status="warning">
              <AlertIcon />
              Please log in to view bill details
            </Alert>
          </Container>
          <LoginModal 
            isOpen={isOpen} 
            onClose={() => {
              onClose();
              const authToken = localStorage.getItem('auth_token');
              if (authToken) {
                setIsAuthenticated(true);
              }
            }} 
          />
        </>
      ) : ( */}
        <Container maxW="container.xl" py={8}>
          <VStack spacing={6} align="stretch">
            <Card>
              <CardHeader>
                <VStack spacing={4} width="100%">
                  <HStack justify="space-between" width="100%">
                    <VStack align="start" spacing={2}>
                      <Heading size={{ base: "md", md: "lg" }}>Bill Details</Heading>
                      <Text color="gray.500" fontSize={{ base: "sm", md: "md" }}>Bill ID: {bill.id}</Text>
                    </VStack>
                    <HStack spacing={{ base: 2, md: 4 }} flexWrap="wrap">
                      <Button
                        colorScheme="blue"
                        size={{ base: "sm", md: "md" }}
                        onClick={handleSendBill}
                        isDisabled={bill.status === BillStatus.SENT}
                      >
                        Send Bill
                      </Button>
                      <Badge
                        fontSize={{ base: "md", md: "lg" }}
                        colorScheme={bill.status === BillStatus.SENT ? 'blue' : 'gray'}
                        p={2}
                        borderRadius="md"
                      >
                        {bill.status}
                      </Badge>
                    </HStack>
                  </HStack>

                  <Card p={6} width="100%" bg="gray.50" borderRadius="lg">
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                      <VStack align="start" spacing={4}>
                        <HStack justify="flex-start" spacing={4} flexWrap="wrap">
                          <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold">Payment Status:</Text>
                          <Switch
                            size="lg"
                            isChecked={bill.isPaid}
                            onChange={() => handleTogglePayment(bill.id)}
                            colorScheme="green"
                            sx={{
                              'span.chakra-switch__track': {
                                w: '4rem',
                                h: '2rem',
                              },
                              'span.chakra-switch__thumb': {
                                w: '1.75rem',
                                h: '1.75rem',
                              }
                            }}
                          />
                          <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold" color={bill.isPaid ? 'green.500' : 'gray.500'}>
                            {bill.isPaid ? 'PAID' : 'UNPAID'}
                          </Text>
                        </HStack>
                      </VStack>
                    </SimpleGrid>
                  </Card>
                </VStack>
              </CardHeader>

              <CardBody>
                <VStack spacing={6} align="stretch">
                  <Box>
                    <Heading size="md" mb={4}>Bill Information</Heading>
                    <Card variant="outline">
                      <CardBody>
                        <SimpleGrid columns={2} spacing={4}>
                          <Box>
                            <Text fontWeight="bold">Created Date</Text>
                            <Text>{formatDate(bill.createdAt)}</Text>
                          </Box>
                          <Box>
                            <Text fontWeight="bold">Last Updated</Text>
                            <Text>{formatDate(bill.updatedAt)}</Text>
                          </Box>
                          <Box>
                            <Text fontWeight="bold">Tenant ID</Text>
                            <Text>{bill.tenantId}</Text>
                          </Box>
                        </SimpleGrid>
                      </CardBody>
                    </Card>
                  </Box>

                  <Box>
                    <Heading size="md" mb={4}>Client Information</Heading>
                    <Card variant="outline">
                      <CardBody>
                        {clientLoading ? (
                          <Skeleton height="100px" />
                        ) : clientData?.getClientDetailsByBillId ? (
                          <SimpleGrid columns={2} spacing={4}>
                            <Box>
                              <Text fontWeight="bold">Name</Text>
                              <Text>{clientData.getClientDetailsByBillId.fName} {clientData.getClientDetailsByBillId.lName}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight="bold">Email</Text>
                              <Text>{clientData.getClientDetailsByBillId.email}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight="bold">Phone</Text>
                              <Text>{clientData.getClientDetailsByBillId.phoneNumber}</Text>
                            </Box>
                          </SimpleGrid>
                        ) : (
                          <Text color="gray.500">No client information available</Text>
                        )}
                      </CardBody>
                    </Card>
                  </Box>

                  <Box>
                    <Heading size="md" mb={4}>Line Items</Heading>
                    <Card variant="outline">
                      <CardBody>
                        <Box overflowX="auto" width="100%">
                          <Table variant="simple" size={{ base: "sm", md: "md" }}>
                            <Thead>
                              <Tr>
                                <Th>Description</Th>
                                <Th display={{ base: "none", md: "table-cell" }}>Created</Th>
                                <Th display={{ base: "none", md: "table-cell" }}>Updated</Th>
                                <Th isNumeric>Amount</Th>
                                <Th>Actions</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {bill.lineItems && bill.lineItems.map((item: any) => {
                                const isEditing = editingId === (item._id?.$oid || item.id);
                                
                                const handleEdit = () => {
                                  console.log('Setting edit ID to:', item._id?.$oid || item.id);
                                  setEditingId(item._id?.$oid || item.id);
                                  setEditValues({
                                    description: item.description,
                                    amount: item.amount
                                  });
                                };

                                const handleSave = async () => {
                                  try {
                                    await updateLineItem({
                                      variables: {
                                        billId: bill.id,
                                        lineItemId: item._id?.$oid || item.id,
                                        description: editValues.description,
                                        amount: parseFloat(editValues.amount.toString())
                                      },
                                      refetchQueries: [{ query: GET_BILL, variables: { id: bill.id } }]
                                    });
                                    
                                    setEditingId(null);
                                    toast({
                                      title: "Line item updated",
                                      status: "success",
                                      duration: 3000,
                                      isClosable: true,
                                    });
                                  } catch (error) {
                                    toast({
                                      title: "Error updating line item",
                                      description: error instanceof Error ? error.message : "Unknown error occurred",
                                      status: "error",
                                      duration: 5000,
                                      isClosable: true,
                                    });
                                  }
                                };

                                return (
                                  <Tr key={item._id?.$oid || item.id}>
                                    <Td maxW={{ base: "150px", md: "300px" }} overflow="hidden" textOverflow="ellipsis">
                                      {isEditing ? (
                                        <Input
                                          value={editValues.description}
                                          onChange={(e) => setEditValues(prev => ({
                                            ...prev,
                                            description: e.target.value
                                          }))}
                                          size={{ base: "sm", md: "md" }}
                                        />
                                      ) : (
                                        item.description
                                      )}
                                    </Td>
                                    <Td display={{ base: "none", md: "table-cell" }}>{formatDate(item.createdAt)}</Td>
                                    <Td display={{ base: "none", md: "table-cell" }}>{formatDate(item.updatedAt)}</Td>
                                    <Td isNumeric>
                                      {isEditing ? (
                                        <Input
                                          type="number"
                                          value={editValues.amount}
                                          onChange={(e) => setEditValues(prev => ({
                                            ...prev,
                                            amount: parseFloat(e.target.value)
                                          }))}
                                          textAlign="right"
                                          size={{ base: "sm", md: "md" }}
                                        />
                                      ) : (
                                        `$${Number(item.amount).toFixed(2)}`
                                      )}
                                    </Td>
                                    <Td>
                                      <HStack spacing={2}>
                                        {isEditing ? (
                                          <>
                                            <Button 
                                              size="sm" 
                                              colorScheme="green" 
                                              onClick={handleSave}
                                              px={{ base: 2, md: 4 }}
                                            >
                                              Save
                                            </Button>
                                            <Button 
                                              size="sm" 
                                              onClick={() => setEditingId(null)}
                                              px={{ base: 2, md: 4 }}
                                            >
                                              Cancel
                                            </Button>
                                          </>
                                        ) : (
                                          <IconButton
                                            aria-label="Edit line item"
                                            icon={<EditIcon />}
                                            size="sm"
                                            colorScheme="blue"
                                            onClick={handleEdit}
                                          />
                                        )}
                                      </HStack>
                                    </Td>
                                  </Tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </Box>
                      </CardBody>
                    </Card>
                  </Box>
                </VStack>
              </CardBody>
            </Card>
          </VStack>
        </Container>
      {/* )} */}
      <FooterWithFourColumns />
    </>
  );
};

export default BillDetails; 