import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Select,
  Switch,
  Button,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_SERVICES = gql`
  query GetServices {
    services {
      id
      type
      jobSummary
      client {
        fName
        lName
      }
    }
  }
`;

const CREATE_BILL = gql`
  mutation CreateBill($input: BillInput!) {
    createBill(input: $input) {
      id
      isPaid
      paymentMethod
      lineItems {
        id
        amount
        description
      }
      service {
        id
        type
      }
      createdAt
      updatedAt
    }
  }
`;

interface LineItem {
  description: string;
  amount: number;
}

const NewBill = () => {
  const navigate = useNavigate();
  const toast = useToast();
  
  const [selectedService, setSelectedService] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<'AUD_TRANSFER' | 'CRYPTO' | 'STRIPE'>('CRYPTO');
  const [lineItems, setLineItems] = useState<LineItem[]>([{ description: '', amount: 0 }]);

  const { loading: servicesLoading, error: servicesError, data: servicesData } = useQuery(GET_SERVICES);
  const [createBill, { loading: creatingBill }] = useMutation(CREATE_BILL);

  const handleAddLineItem = () => {
    setLineItems([...lineItems, { description: '', amount: 0 }]);
  };

  const handleRemoveLineItem = (index: number) => {
    setLineItems(lineItems.filter((_, i) => i !== index));
  };

  const handleLineItemChange = (index: number, field: keyof LineItem, value: string | number) => {
    const newLineItems = [...lineItems];
    newLineItems[index] = {
      ...newLineItems[index],
      [field]: field === 'amount' ? parseFloat(value as string) || 0 : value,
    };
    setLineItems(newLineItems);
  };

  const handleSubmit = async () => {
    if (!selectedService) {
      toast({
        title: 'Error',
        description: 'Please select a service',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const { data } = await createBill({
        variables: {
          input: {
            serviceId: selectedService,
            isPaid,
            paymentMethod,
            lineItems: lineItems.filter(item => item.description && item.amount > 0),
          },
        },
      });

      toast({
        title: 'Success',
        description: 'Bill created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate(`/bill/${data.createBill.id}`);
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to create bill',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <Heading>Create New Bill</Heading>

          <FormControl>
            <FormLabel>Service</FormLabel>
            <Select
              placeholder="Select service"
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
              isDisabled={servicesLoading}
            >
              {servicesData?.services.map((service: any) => (
                <option key={service.id} value={service.id}>
                  {service.client.fName} {service.client.lName} - {service.type} - {service.jobSummary}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Payment Method</FormLabel>
            <Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value as any)}
            >
              <option value="CRYPTO">Crypto</option>
              <option value="AUD_TRANSFER">AUD Transfer</option>
              <option value="STRIPE">Stripe</option>
            </Select>
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Is Paid?</FormLabel>
            <Switch isChecked={isPaid} onChange={(e) => setIsPaid(e.target.checked)} />
          </FormControl>

          <Box>
            <Heading size="md" mb={4}>Line Items</Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Description</Th>
                  <Th>Amount</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {lineItems.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      <Input
                        value={item.description}
                        onChange={(e) => handleLineItemChange(index, 'description', e.target.value)}
                        placeholder="Item description"
                      />
                    </Td>
                    <Td>
                      <Input
                        type="number"
                        value={item.amount}
                        onChange={(e) => handleLineItemChange(index, 'amount', e.target.value)}
                        placeholder="Amount"
                      />
                    </Td>
                    <Td>
                      <IconButton
                        aria-label="Remove line item"
                        icon={<DeleteIcon />}
                        onClick={() => handleRemoveLineItem(index)}
                        isDisabled={lineItems.length === 1}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Button
              leftIcon={<AddIcon />}
              onClick={handleAddLineItem}
              mt={4}
              size="sm"
            >
              Add Line Item
            </Button>
          </Box>

          <HStack spacing={4}>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={creatingBill}
            >
              Create Bill
            </Button>
            <Button onClick={() => navigate('/bills')}>
              Cancel
            </Button>
          </HStack>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
};

export default NewBill; 