import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Container,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Code,
  Box,
  useToast,
  Image,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { NavbarWithCallToAction } from "../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../../components/chakra/FooterWithFourColumns/FooterWithFourColumns";

const UPLOAD_UNENCRYPTED_FILE = gql`
  mutation UploadUnencryptedFile($file: Upload!) {
    uploadUnencryptedFile(file: $file)
  }
`;

const TestUploadUnencryptedFile: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadResult, setUploadResult] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const toast = useToast();

  const [uploadFile, { loading: isUploading }] = useMutation(UPLOAD_UNENCRYPTED_FILE);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "No file selected",
        status: "error",
        duration: 3000,
      });
      return;
    }

    try {
      const { data } = await uploadFile({
        variables: { file },
        context: {
          headers: {
            'apollo-require-preflight': 'true',
            'x-apollo-operation-name': 'UploadUnencryptedFile'
          }
        }
      });

      console.log('Upload response:', data);
      const hash = data.uploadUnencryptedFile;
      setUploadResult(hash);
      
      const lighthouseUrl = `https://gateway.lighthouse.storage/ipfs/${hash}`;
      setFileUrl(lighthouseUrl);

      toast({
        title: "Upload successful",
        description: "File uploaded successfully!",
        status: "success",
        duration: 5000,
      });

    } catch (error) {
      console.error('Upload error:', error);
      toast({
        title: "Upload failed",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 5000,
      });
    }
  };

  const renderFilePreview = () => {
    if (!fileUrl) return null;

    const fileExtension = file?.name.split('.').pop()?.toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '');
    const isPDF = fileExtension === 'pdf';

    return (
      <Box mt={4} p={4} borderWidth={1} borderRadius="md">
        <Text fontWeight="bold" mb={2}>File Preview:</Text>
        
        {isImage && (
          <Image 
            src={fileUrl} 
            alt="Uploaded file preview" 
            maxH="400px" 
            objectFit="contain"
          />
        )}
        
        {isPDF && (
          <iframe
            src={fileUrl}
            width="100%"
            height="500px"
            title="PDF preview"
          />
        )}
        
        {!isImage && !isPDF && (
          <Text>
            This file type cannot be previewed. {' '}
            <Link href={fileUrl} isExternal color="blue.500">
              Click here to download/view the file
            </Link>
          </Text>
        )}
      </Box>
    );
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.md" py={8}>
        <VStack spacing={6} align="stretch">
          <Heading>Test File Upload (Backend)</Heading>

          <FormControl>
            <FormLabel>Select File to Upload</FormLabel>
            <Input
              type="file"
              onChange={handleFileChange}
              accept="*/*"
              disabled={isUploading}
            />
          </FormControl>

          {file && (
            <Box>
              <Text>Selected file: {file.name}</Text>
              <Text>Size: {(file.size / 1024 / 1024).toFixed(2)} MB</Text>
            </Box>
          )}

          <Button
            colorScheme="blue"
            onClick={handleUpload}
            isLoading={isUploading}
            loadingText="Uploading..."
            disabled={!file}
          >
            Upload File
          </Button>

          {uploadResult && (
            <Box mt={4} p={4} borderWidth={1} borderRadius="md">
              <Text fontWeight="bold">Upload Result:</Text>
              <Code display="block" whiteSpace="pre" p={2} mt={2}>
                File Hash: {uploadResult}
                <Link href={fileUrl || ''} isExternal>
                  <Button size="sm" ml={2}>
                    Open in new tab
                  </Button>
                </Link>
              </Code>
            </Box>
          )}

          {renderFilePreview()}
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
};

export default TestUploadUnencryptedFile; 