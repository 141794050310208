import React from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
  Avatar,
  Icon,
  Button,
  useColorModeValue,
  Skeleton,
  useToast,
  Progress,
} from '@chakra-ui/react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { FiUsers, FiCheckCircle, FiDollarSign, FiTrash2 } from 'react-icons/fi';
import { motion } from 'framer-motion';

const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    projects {
      id
      projectName
      projectGoal
      progress
      billingClient {
        id
        fName
        lName
        email
        businessName
      }
      members {
        id
        fName
        lName
        email
      }
      tasks {
        id
        description
        status
        assignedTo {
          id
          fName
          lName
        }
        billed
      }
      bills {
        id
        lineItems {
          id
          amount
        }
        isPaid
        status
      }
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`;

const ProjectCard = ({ project, onDelete }: { project: any, onDelete: () => void }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const cardBg = useColorModeValue('white', 'gray.800');
  
  const handleClick = (e: React.MouseEvent, action: 'view' | 'delete') => {
    e.stopPropagation();
    if (action === 'delete') {
      if (window.confirm('Are you sure you want to delete this project?')) {
        onDelete();
      }
    } else {
      navigate(`/project/${project.id}`);
    }
  };

  const completedTasks = project.tasks.filter((t: any) => t.status === 'COMPLETED').length;
  const billedTasks = project.tasks.filter((t: any) => t.billed).length;
  const totalBilled = project.bills.reduce((sum: number, bill: any) => 
    sum + (bill.lineItems?.reduce((itemSum: number, item: any) => itemSum + (item.amount || 0), 0) || 0), 
  0);
  const paidBills = project.bills.filter((b: any) => b.isPaid).length;
  
  return (
    <Card
      as={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 } as any}
      bg={cardBg}
      cursor="pointer"
      onClick={(e) => handleClick(e, 'view')}
      _hover={{
        transform: 'translateY(-4px)',
        boxShadow: 'xl',
        transition: 'all 0.2s'
      }}
    >
      <CardHeader>
        <HStack justify="space-between" width="full">
          <VStack align="start" spacing={2}>
            <Heading size="md">{project.projectName}</Heading>
            <HStack spacing={2} flexWrap="wrap">
              {project.billingClient.businessName && (
                <Badge colorScheme="blue">{project.billingClient.businessName}</Badge>
              )}
              <Badge colorScheme="green">
                {completedTasks}/{project.tasks.length} Tasks
              </Badge>
              <Badge colorScheme="purple">
                ${totalBilled.toLocaleString()}
              </Badge>
            </HStack>
          </VStack>
          <Button
            size="sm"
            colorScheme="red"
            variant="ghost"
            onClick={(e) => handleClick(e, 'delete')}
          >
            <Icon as={FiTrash2} />
          </Button>
        </HStack>
      </CardHeader>
      
      <CardBody>
        <VStack align="start" spacing={4}>
          <Text noOfLines={2} color="gray.500">
            {project.projectGoal}
          </Text>
          
          <Box width="full">
            <HStack justify="space-between" mb={1}>
              <Text fontSize="sm" fontWeight="medium">Project Progress</Text>
              <Text fontSize="sm" color="gray.600">{project.progress || 0}%</Text>
            </HStack>
            <Progress
              value={project.progress || 0}
              size="sm"
              colorScheme={
                (project.progress || 0) >= 100 ? 'green' :
                (project.progress || 0) >= 50 ? 'blue' : 'yellow'
              }
              borderRadius="full"
              hasStripe
              isAnimated
            />
          </Box>
          
          <SimpleGrid columns={4} spacing={4} width="full">
            <VStack>
              <Icon as={FiUsers} color="blue.500" boxSize={5} />
              <Text fontWeight="bold">{project.members.length}</Text>
              <Text fontSize="sm">Members</Text>
            </VStack>
            
            <VStack>
              <Icon as={FiCheckCircle} color="green.500" boxSize={5} />
              <Text fontWeight="bold">{completedTasks}/{project.tasks.length}</Text>
              <Text fontSize="sm">Tasks Done</Text>
            </VStack>
            
            <VStack>
              <Icon as={FiDollarSign} color="purple.500" boxSize={5} />
              <Text fontWeight="bold">${totalBilled}</Text>
              <Text fontSize="sm">Billed</Text>
            </VStack>

            <VStack>
              <Icon as={FiCheckCircle} color="teal.500" boxSize={5} />
              <Text fontWeight="bold">{billedTasks}/{project.tasks.length}</Text>
              <Text fontSize="sm">Tasks Billed</Text>
            </VStack>
          </SimpleGrid>
          
          <HStack spacing={2} wrap="wrap">
            {project.members.slice(0, 3).map((member: any) => (
              <Avatar
                key={member.id}
                size="sm"
                name={`${member.fName} ${member.lName}`}
                title={`${member.fName} ${member.lName}`}
              />
            ))}
            {project.members.length > 3 && (
              <Avatar
                size="sm"
                name={`+${project.members.length - 3}`}
                bg="gray.400"
              />
            )}
          </HStack>

          {project.bills.length > 0 && (
            <HStack spacing={2}>
              <Badge colorScheme={paidBills === project.bills.length ? 'green' : 'yellow'}>
                {paidBills}/{project.bills.length} Bills Paid
              </Badge>
              <Badge colorScheme="blue">
                {billedTasks}/{project.tasks.length} Tasks Billed
              </Badge>
            </HStack>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

const ProjectsList = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(GET_ALL_PROJECTS);
  const bgGradient = useColorModeValue(
    'linear(to-br, gray.50, white)',
    'linear(to-br, gray.900, gray.800)'
  );

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      toast({
        title: 'Project deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      refetch(); // Refresh the projects list
    },
    onError: (error) => {
      toast({
        title: 'Error deleting project',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleDeleteProject = async (projectId: string) => {
    try {
      await deleteProject({
        variables: { id: projectId },
      });
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  if (error) {
    console.error('GraphQL Error:', error);
    return <Text>Error: {error.message}</Text>;
  }

  return (
    <>
      <NavbarWithCallToAction />
      <Box bgGradient={bgGradient} minH="100vh" py={8}>
        <Container maxW="container.xl">
          <VStack spacing={8} align="stretch">
            <HStack justify="space-between">
              <Heading size="lg">Projects</Heading>
              <Button
                colorScheme="blue"
                onClick={() => navigate('/projects/new')}
              >
                New Project
              </Button>
            </HStack>

            {loading ? (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {[...Array(6)].map((_, i) => (
                  <Skeleton key={i} height="300px" borderRadius="lg" />
                ))}
              </SimpleGrid>
            ) : (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {data?.projects.map((project: any) => (
                  <ProjectCard 
                    key={project.id} 
                    project={project} 
                    onDelete={() => handleDeleteProject(project.id)}
                  />
                ))}
              </SimpleGrid>
            )}
          </VStack>
        </Container>
      </Box>
      <FooterWithFourColumns />
    </>
  );
};

export default ProjectsList; 