import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Container,
  VStack,
  Heading,
  Box,
  Button,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  HStack,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FiShoppingCart, FiTrash2, FiCreditCard } from 'react-icons/fi';
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { useNavigate } from 'react-router-dom';


// GraphQL Queries and Mutations
const GET_CART = gql`
  query GetCart($cartId: ID!) {
    cart(id: $cartId) {
      id
      client {
        id
        email
      }
      items {
        id
        product {
          id
          name
          price
          variants {
            variantId
            title
            price
          }
        }
        quantity
        selectedVariantId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

const DELETE_CART = gql`
  mutation DeleteCart($cartId: ID!) {
    deleteCart(cartId: $cartId)
  }
`;

const CREATE_PRODUCT_ORDER = gql`
  mutation CreateProductOrder($input: ProductOrderInput!) {
    createProductOrder(input: $input) {
      id
      client {
        id
        email
        fName
        lName
      }
      items {
        product {
          id
          name
          price
        }
        quantity
        priceAtTime
        selectedVariantId
      }
      totalAmount
      status
      payment {
        status
        method
      }
      createdAt
      updatedAt
    }
  }
`;

// TypeScript Interfaces
interface ProductVariant {
  variantId: string;
  title: string;
  price: number;
}

interface CartItem {
  id: string;
  product: {
    id: string;
    name: string;
    price: number;
    variants?: ProductVariant[];
  };
  quantity: number;
  selectedVariantId?: string;
}

const Cart = () => {
  const cartId = localStorage.getItem('cart_id');
  const toast = useToast();
  const bgGradient = "linear(to-br, blue.50, purple.50)";
  const cardBg = "rgba(255, 255, 255, 0.9)";
  const navigate = useNavigate();

  // Query for existing cart
  const { loading: cartLoading, error: cartError, data: cartData } = useQuery(GET_CART, {
    variables: { cartId },
    skip: !cartId,
  });

  // Delete cart mutation
  const [deleteCart, { loading: deleteLoading }] = useMutation(DELETE_CART, {
    onCompleted: () => {
      localStorage.removeItem('cart_id');
      toast({
        title: "Cart deleted",
        description: "Cart has been successfully deleted",
        status: "success",
        duration: 5000,
      });
    },
    onError: (error) => {
      toast({
        title: "Error deleting cart",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    },
  });

  // Add create order mutation
  const [createOrder, { loading: createOrderLoading }] = useMutation(CREATE_PRODUCT_ORDER, {
    onCompleted: (data) => {
      toast({
        title: "Order created successfully",
        description: "Redirecting to order details...",
        status: "success",
        duration: 3000,
      });
      navigate(`/order/${data.createProductOrder.id}`);
    },
    onError: (error) => {
      toast({
        title: "Error creating order",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    },
  });

  const handleDeleteCart = () => {
    if (cartId) {
      deleteCart({ variables: { cartId } });
    }
  };

  const handleCheckout = async () => {
    if (!cartData?.cart?.items?.length) {
        console.log('No items in cart, returning');
        return;
    }

    console.group('Checkout Process Debug');
    
    const orderInput = {
        clientId: cartData.cart.client.id,
        items: cartData.cart.items.map((item: CartItem) => ({
            productId: item.product.id,
            quantity: item.quantity,
            selectedVariantId: item.selectedVariantId,
        })),
        payment: {
            status: "PENDING",
            method: "STRIPE"
        }
    };

    try {
        const result = await createOrder({
            variables: { input: orderInput },
            context: {
                headers: {
                    'x-apollo-operation-name': 'CreateProductOrder',
                    'apollo-require-preflight': 'true',
                    'content-type': 'application/json'
                }
            }
        });
        
        // Order created successfully, no need for verification
        if (result.data?.createProductOrder?.id) {
            console.log('Order created successfully:', result.data.createProductOrder);
        }
    } catch (error) {
        console.error('Create Order Error:', error);
    }
    console.groupEnd();
};

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <NavbarWithCallToAction />
      
      <Box
        bgGradient={bgGradient}
        minH="100vh"
        py={8}
        px={4}
      >
        <Container maxW="container.xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <VStack spacing={8} align="stretch">
              <Card
                bg={cardBg}
                backdropFilter="blur(10px)"
                borderRadius="2xl"
                boxShadow={`0 8px 32px rgba(0, 0, 0, 0.12)`}
                border="1px solid"
                borderColor="gray.100"
                overflow="hidden"
                transition="all 0.2s"
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "xl"
                }}
              >
                <CardHeader>
                  <HStack justify="space-between" align="center" wrap="wrap">
                    <VStack align="start" spacing={2}>
                      <Text
                        fontSize="sm"
                        color="gray.500"
                        textTransform="uppercase"
                        letterSpacing="wider"
                      >
                        Shopping Cart
                      </Text>
                      <Heading size="lg">Your Items</Heading>
                    </VStack>
                  </HStack>
                </CardHeader>

                <CardBody>
                  {cartLoading ? (
                    <Box textAlign="center" py={8}>
                      <Spinner size="xl" />
                    </Box>
                  ) : cartError ? (
                    <Box p={4} borderRadius="md" bg="red.50">
                      <Text color="red.500">Error loading cart: {cartError.message}</Text>
                    </Box>
                  ) : !cartId || !cartData?.cart?.items?.length ? (
                    <Box p={8} textAlign="center">
                      <Box
                        as={FiShoppingCart}
                        size="48px"
                        color="gray.300"
                        mb={4}
                        mx="auto"
                      />
                      <Text fontSize="lg" mb={4} color="gray.600">Your cart is empty</Text>
                      <Button
                        colorScheme="blue"
                        size="lg"
                        onClick={() => window.location.href = '/products'}
                        leftIcon={<FiShoppingCart />}
                      >
                        Browse Products
                      </Button>
                    </Box>
                  ) : (
                    <Grid templateColumns={{ base: "1fr", lg: "2fr 1fr" }} gap={4}>
                      <GridItem>
                        <Box overflowX="auto" mx={-4} px={4}>
                          <Table variant="simple" size={{ base: "sm", md: "md" }}>
                            <Thead>
                              <Tr>
                                <Th>Product</Th>
                                <Th display={{ base: "none", md: "table-cell" }}>Variant</Th>
                                <Th>Qty</Th>
                                <Th display={{ base: "none", md: "table-cell" }}>Price</Th>
                                <Th>Total</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {cartData.cart.items.map((item: CartItem) => {
                                const selectedVariant = item.selectedVariantId 
                                  ? item.product.variants?.find(v => v.variantId === item.selectedVariantId)
                                  : null;
                                
                                const price = selectedVariant ? selectedVariant.price : item.product.price;
                                const variantTitle = selectedVariant ? selectedVariant.title : 'Base Product';

                                return (
                                  <motion.tr
                                    key={item.id}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                  >
                                    <Td fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                      {item.product.name}
                                      <Text
                                        display={{ base: "block", md: "none" }}
                                        fontSize="xs"
                                        color="gray.600"
                                        mt={1}
                                      >
                                        {variantTitle}
                                      </Text>
                                    </Td>
                                    <Td display={{ base: "none", md: "table-cell" }}>
                                      <Badge colorScheme="blue" variant="subtle">
                                        {variantTitle}
                                      </Badge>
                                    </Td>
                                    <Td>{item.quantity}</Td>
                                    <Td display={{ base: "none", md: "table-cell" }}>${price.toFixed(2)}</Td>
                                    <Td fontWeight="bold">${(item.quantity * price).toFixed(2)}</Td>
                                  </motion.tr>
                                );
                              })}
                            </Tbody>
                          </Table>
                        </Box>
                      </GridItem>

                      <GridItem>
                        <Box
                          p={{ base: 4, md: 6 }}
                          bg="gray.50"
                          borderRadius="xl"
                        >
                          <Text fontWeight="semibold" fontSize="lg" mb={4}>Order Summary</Text>
                          <VStack spacing={4} align="stretch">
                            <Box>
                              <HStack justify="space-between" mb={2}>
                                <Text color="gray.600">Subtotal</Text>
                                <Text fontWeight="bold">
                                  ${cartData.cart.items.reduce((total: number, item: CartItem) => {
                                    const selectedVariant = item.selectedVariantId 
                                      ? item.product.variants?.find(v => v.variantId === item.selectedVariantId)
                                      : null;
                                    const price = selectedVariant ? selectedVariant.price : item.product.price;
                                    return total + (item.quantity * price);
                                  }, 0).toFixed(2)}
                                </Text>
                              </HStack>
                              <HStack justify="space-between" mb={4}>
                                <Text color="gray.600">Shipping</Text>
                                <Text>Calculated at checkout</Text>
                              </HStack>
                              <Box pt={4} borderTopWidth={1} borderColor="gray.200">
                                <HStack justify="space-between">
                                  <Text fontWeight="bold">Total</Text>
                                  <Text fontSize="xl" fontWeight="bold">
                                    ${cartData.cart.items.reduce((total: number, item: CartItem) => {
                                      const selectedVariant = item.selectedVariantId 
                                        ? item.product.variants?.find(v => v.variantId === item.selectedVariantId)
                                        : null;
                                      const price = selectedVariant ? selectedVariant.price : item.product.price;
                                      return total + (item.quantity * price);
                                    }, 0).toFixed(2)}
                                  </Text>
                                </HStack>
                              </Box>
                            </Box>
                            <VStack spacing={3}>
                              <Button
                                colorScheme="blue"
                                size="lg"
                                width="full"
                                leftIcon={<FiCreditCard />}
                                onClick={handleCheckout}
                                isLoading={createOrderLoading}
                              >
                                Proceed to Checkout
                              </Button>
                              <Button
                                variant="ghost"
                                colorScheme="red"
                                width="full"
                                leftIcon={<FiTrash2 />}
                                onClick={handleDeleteCart}
                                isLoading={deleteLoading}
                              >
                                Clear Cart
                              </Button>
                            </VStack>
                          </VStack>
                        </Box>
                      </GridItem>
                    </Grid>
                  )}
                </CardBody>
              </Card>
            </VStack>
          </motion.div>
        </Container>
      </Box>

      <FooterWithFourColumns />
    </Box>
  );
};

export default Cart; 