import React from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  useToast,
  HStack,
  Box,
  Grid,
} from "@chakra-ui/react";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { AddIcon } from "@chakra-ui/icons";

interface FormData {
  fName: string;
  lName: string;
  email: string;
  phoneNumber: string;
}

const GET_CLIENT = gql`
  query GetClient($id: ID!) {
    client(id: $id) {
      id
      fName
      lName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: ID!, $input: UpdateClientInput!) {
    updateClient(id: $id, input: $input) {
      id
      fName
      lName
      email
      phoneNumber
    }
  }
`;

const GET_SERVICES_BY_CLIENT = gql`
  query GetServicesByClientId($clientId: ID!) {
    servicesByClientId(clientId: $clientId) {
      id
      type
      serviceStartDate
      status
      jobSummary
      client {
        id
        fName
        email
      }
      hdWalletService {
        id
        type
        startDate
        walletType
        currentStepId
        completedAt
        completionHash
        progress {
          stepId
          status
          completedAt
          notes
          step
        }
      }
      websiteService {
        id
        type
        websiteName
      }
    }
  }
`;

export default function ClientDetails() {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const [isEditing, setIsEditing] = React.useState(false);
  const [formData, setFormData] = React.useState({
    fName: "",
    lName: "",
    email: "",
    phoneNumber: "",
  });

  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_CLIENT, {
    variables: { id },
    onCompleted: (data) => {
      setFormData({
        fName: data.client.fName,
        lName: data.client.lName,
        email: data.client.email,
        phoneNumber: data.client.phoneNumber,
      });
    },
  });

  const [updateClient, { loading: updateLoading }] = useMutation(
    UPDATE_CLIENT,
    {
      onCompleted: () => {
        toast({
          title: "Success",
          description: "Client information updated successfully",
          status: "success",
          duration: 5000,
        });
        setIsEditing(false);
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
        });
      },
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateClient({
      variables: {
        id,
        input: formData,
      },
    });
  };

  const handleServiceClick = (service: any) => {
    console.log('Service:', service);
    console.log('Service type:', service.type);
    
    // Convert type to uppercase for consistent comparison
    const serviceType = service.type?.toUpperCase();
    
    if (serviceType === 'HDWALLET' || serviceType === 'HD_WALLET') {
      console.log('HD Wallet Service:', service.hdWalletService);
      navigate(`/hdwalletservice/${service.id}`);
    } else if (serviceType === 'WEBSITE') {
      console.log('Website Service:', service.websiteService);
      navigate(`/websiteservice/${service.id}`);
    } else {
      // navigate(`/service/${service.id}`);
      console.log('Service:', service);
    }
  };

  const { data: servicesData, loading: servicesLoading } = useQuery(GET_SERVICES_BY_CLIENT, {
    variables: { clientId: id },
  });

  if (loading) return <div className="p-4">Loading...</div>;
  if (error)
    return <div className="p-4 text-red-500">Error: {error.message}</div>;

  const client = data?.client;

  if (!client) return <div className="p-4">Client not found</div>;

  return (
    <Box backgroundColor="gray.50" minHeight="100vh">
      <NavbarWithCallToAction />
      <Container 
        maxW="6xl" 
        py={12}
        px={4}
      >
        <Card
          boxShadow="sm"
          borderRadius="lg"
          backgroundColor="white"
          border="1px"
          borderColor="gray.100"
        >
          <CardHeader>
            <HStack className="justify-between">
              <Heading size="lg">Client Details</Heading>
              {isEditing ? (
                <HStack spacing={2}>
                  <Button
                    onClick={() => setIsEditing(false)}
                    variant="outline"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    isLoading={updateLoading}
                    colorScheme="blue"
                  >
                    Save Changes
                  </Button>
                </HStack>
              ) : (
                <Button onClick={() => setIsEditing(true)}>
                  Edit
                </Button>
              )}
            </HStack>
          </CardHeader>

          <CardBody>
            <form onSubmit={handleSubmit}>
              <Stack className="space-y-6">
                <Grid className="grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Basic Information */}
                  <Box className="space-y-4">
                    <Heading size="sm">Basic Information</Heading>
                    <FormControl>
                      <FormLabel>First Name</FormLabel>
                      <Input
                        name="fName"
                        value={formData.fName}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        name="lName"
                        value={formData.lName}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Email</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Phone Number</FormLabel>
                      <Input
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                      />
                    </FormControl>
                  </Box>
                </Grid>

                {/* Add Services Section */}
                <Box className="space-y-4">
                  <HStack justify="space-between" align="center">
                    <Heading size="sm">Client Services</Heading>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => navigate(`/projects/new?clientId=${id}`)}
                      leftIcon={<AddIcon />}
                    >
                      New Project
                    </Button>
                  </HStack>
                  {servicesLoading ? (
                    <Text>Loading services...</Text>
                  ) : servicesData?.servicesByClientId?.length > 0 ? (
                    <Grid className="grid-cols-1 gap-4">
                      {servicesData.servicesByClientId.map((service: any) => (
                        <Box
                          key={service.id}
                          p={4}
                          border="1px"
                          borderColor="gray.200"
                          borderRadius="md"
                          cursor="pointer"
                          onClick={() => handleServiceClick(service)}
                          _hover={{
                            backgroundColor: "gray.50",
                            borderColor: "blue.500",
                          }}
                          transition="all 0.2s"
                        >
                          <Grid className="grid-cols-2 gap-4">
                            <Text fontWeight="bold">Service Type: {service.type}</Text>
                            <Text color={
                              service.status === 'ACTIVE' ? 'green.500' :
                                service.status === 'PENDING' ? 'orange.500' :
                                  'red.500'
                            }>
                              Status: {service.status}
                            </Text>
                            <Text>Start Date: {new Date(service.serviceStartDate).toLocaleDateString()}</Text>
                            {service.jobSummary && (
                              <Text className="col-span-2">Summary: {service.jobSummary}</Text>
                            )}
                            {service.websiteService && (
                              <Text className="col-span-2">Website Service ID: {service.websiteService.id}</Text>
                            )}
                            {service.hdWalletService && (
                              <Text className="col-span-2">HD Wallet Service ID: {service.hdWalletService.id}</Text>
                            )}
                          </Grid>
                        </Box>
                      ))}
                    </Grid>
                  ) : (
                    <Text>No services found for this client.</Text>
                  )}
                </Box>

                {/* Additional Information */}
                <Box className="space-y-4">
                  <Heading size="sm">Additional Information</Heading>
                  <Grid className="grid-cols-1 md:grid-cols-2 gap-4">
                    <Text>Tenant ID: {client?.tenantId}</Text>
                    <Text>Created: {new Date(client?.createdAt).toLocaleDateString()}</Text>
                    <Text>Last Updated: {new Date(client?.updatedAt).toLocaleDateString()}</Text>
                  </Grid>
                </Box>
              </Stack>
            </form>
          </CardBody>
        </Card>
        <Box mt={12}>
          <FooterWithFourColumns />
        </Box>
      </Container>
    </Box>
  );
}
