import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Card,
  CardBody,
  Image,
  Stack,
  Tag,
  HStack,
  Button,
  IconButton,
  AspectRatio,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { FiPlayCircle, FiClock, FiUser, FiTag } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  image: string;
  author: string;
  date: string;
  readTime: string;
  tags: string[];
  type: 'article' | 'podcast' | 'video';
  ipfsHash?: string;
}

const samplePosts: BlogPost[] = [
  {
    id: '1',
    title: 'Why Your Private Keys Are More Important Than Ever',
    excerpt: 'In an age where digital assets are becoming increasingly valuable, understanding the importance of private key management is crucial...',
    image: 'https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5',
    author: 'Tom Wilson',
    date: '2024-03-15',
    readTime: '5 min read',
    tags: ['Security', 'Hardware Wallets', 'Privacy'],
    type: 'article'
  },
  {
    id: '2',
    title: 'The Ultimate Guide to Hardware Wallet Security',
    excerpt: 'Learn how to properly secure your crypto assets with a step-by-step guide to hardware wallet setup and best practices...',
    image: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0',
    author: 'Tom Wilson',
    date: '2024-03-14',
    readTime: '8 min read',
    tags: ['Guide', 'Hardware Wallets', 'Security'],
    type: 'article'
  },
  {
    id: '3',
    title: 'Privacy in the Digital Age: A Discussion',
    excerpt: 'Join our discussion about the importance of privacy in today\'s digital landscape and how to protect yourself...',
    image: 'https://images.unsplash.com/photo-1633265486064-086b219458ec',
    author: 'Tom Wilson',
    date: '2024-03-13',
    readTime: '45 min',
    tags: ['Podcast', 'Privacy', 'Digital Security'],
    type: 'podcast'
  },
  {
    id: '4',
    title: 'Hardware Wallet Setup Tutorial',
    excerpt: 'A comprehensive video guide on setting up your hardware wallet securely...',
    image: 'https://images.unsplash.com/photo-1642104704074-907c0698cbd9',
    author: 'Tom Wilson',
    date: '2024-03-12',
    readTime: '20 min',
    tags: ['Tutorial', 'Hardware Wallets', 'Video'],
    type: 'video',
    ipfsHash: 'QmXyZ...' // Replace with actual IPFS hash
  },
];

const BlogPostCard: React.FC<{ post: BlogPost }> = ({ post }) => {
  const cardBg = useColorModeValue('white', 'gray.800');
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card
        overflow="hidden"
        variant="outline"
        bg={cardBg}
        _hover={{
          transform: 'translateY(-4px)',
          boxShadow: 'xl',
        }}
        transition="all 0.2s"
      >
        <Box position="relative">
          <Image
            src={post.image}
            alt={post.title}
            height="200px"
            width="100%"
            objectFit="cover"
          />
          {post.type !== 'article' && (
            <IconButton
              aria-label="Play media"
              icon={<FiPlayCircle />}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              size="lg"
              colorScheme="blue"
              rounded="full"
              opacity={0.9}
            />
          )}
        </Box>

        <CardBody>
          <Stack spacing={4}>
            <HStack spacing={2}>
              {post.type === 'podcast' && (
                <Tag colorScheme="purple">Podcast</Tag>
              )}
              {post.type === 'video' && (
                <Tag colorScheme="red">Video</Tag>
              )}
              {post.tags.map((tag) => (
                <Tag key={tag} colorScheme="blue" variant="subtle">
                  {tag}
                </Tag>
              ))}
            </HStack>

            <Heading size="md">{post.title}</Heading>
            <Text noOfLines={3}>{post.excerpt}</Text>

            <HStack spacing={4} fontSize="sm" color="gray.500">
              <HStack>
                <FiUser />
                <Text>{post.author}</Text>
              </HStack>
              <HStack>
                <FiClock />
                <Text>{post.readTime}</Text>
              </HStack>
            </HStack>

            <Button
              rightIcon={<ExternalLinkIcon />}
              colorScheme="blue"
              variant="outline"
              size="sm"
            >
              Read More
            </Button>
          </Stack>
        </CardBody>
      </Card>
    </motion.div>
  );
};

const FeaturedPost: React.FC<{ post: BlogPost }> = ({ post }) => {
  return (
    <Card
      direction={{ base: 'column', md: 'row' }}
      overflow='hidden'
      variant='outline'
      mb={8}
    >
      <Image
        objectFit='cover'
        maxW={{ base: '100%', md: '400px' }}
        src={post.image}
        alt={post.title}
      />

      <Stack>
        <CardBody>
          <Heading size='lg'>{post.title}</Heading>
          <Text py={2}>{post.excerpt}</Text>
          <HStack spacing={4} fontSize="sm" color="gray.500" mb={4}>
            <HStack>
              <FiUser />
              <Text>{post.author}</Text>
            </HStack>
            <HStack>
              <FiClock />
              <Text>{post.readTime}</Text>
            </HStack>
          </HStack>
          <Button colorScheme="blue">Read Full Article</Button>
        </CardBody>
      </Stack>
    </Card>
  );
};

const Blog = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const bgGradient = "linear(to-br, blue.50, purple.50)";

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <NavbarWithCallToAction />
      
      <Box bgGradient={bgGradient} minH="100vh" py={8}>
        <Container maxW="container.xl">
          <VStack spacing={8} align="stretch">
            {/* Header */}
            <Box textAlign="center" mb={8}>
              <Heading size="2xl" mb={4}>
                Privacy & Security Blog
              </Heading>
              <Text fontSize="xl" color="gray.600">
                Explore the latest insights on digital privacy, hardware wallets, and security
              </Text>
            </Box>

            {/* Search Bar */}
            <InputGroup maxW="600px" mx="auto" mb={8}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search articles..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                bg="white"
                size="lg"
              />
            </InputGroup>

            {/* Featured Post */}
            <FeaturedPost post={samplePosts[0]} />

            <Divider />

            {/* Latest Posts Grid */}
            <Box>
              <Heading size="lg" mb={6}>Latest Posts</Heading>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {samplePosts.map(post => (
                  <BlogPostCard key={post.id} post={post} />
                ))}
              </SimpleGrid>
            </Box>

            {/* Newsletter Signup */}
            <Box
              bg="white"
              p={8}
              borderRadius="xl"
              textAlign="center"
              mt={8}
            >
              <Heading size="md" mb={4}>
                Stay Updated
              </Heading>
              <Text mb={4}>
                Subscribe to our newsletter for the latest articles and updates
              </Text>
              <HStack maxW="500px" mx="auto">
                <Input placeholder="Enter your email" />
                <Button colorScheme="blue">Subscribe</Button>
              </HStack>
            </Box>
          </VStack>
        </Container>
      </Box>

      <FooterWithFourColumns />
    </Box>
  );
};

export default Blog; 