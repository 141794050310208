export enum StepStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  VERIFIED = "VERIFIED"
}

export interface Step {
  id: string;
  title: string;
  description: string;
  status: StepStatus;
  completedDate?: string;
}

export const steps: Step[] = [
  {
    id: 'initial-consultation',
    title: 'Initial Consultation',
    description: 'Understanding your needs and requirements for secure cryptocurrency storage.',
    status: StepStatus.PENDING,
    completedDate: undefined
  },
  {
    id: 'wallet-selection',
    title: 'Hardware Wallet Selection',
    description: 'Your hardware wallet has been selected based on your security needs and usage requirements.',
    status: StepStatus.PENDING,
    completedDate: undefined
  },
  {
    id: 'secure-delivery',
    title: 'Secure Delivery Confirmed',
    description: 'Verify the authenticity and security of your received hardware wallet.',
    status: StepStatus.PENDING,
    completedDate: undefined
  },
  {
    id: 'setup-security',
    title: 'Setup & Security Training',
    description: 'Essential security practices and proper wallet setup procedures.',
    status: StepStatus.PENDING,
    completedDate: undefined
  },
  {
    id: 'initial-transfer',
    title: 'Initial Asset Transfer',
    description: 'Complete your first secure transfer to verify operational status.',
    status: StepStatus.PENDING,
    completedDate: undefined
  },
  {
    id: 'transaction-verification',
    title: 'Final Verification',
    description: 'Verify complete operational security and understanding.',
    status: StepStatus.PENDING,
    completedDate: undefined
  }
];