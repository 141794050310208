import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    SimpleGrid,
    Button,
    VStack,
    useColorModeValue,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Center,
} from "@chakra-ui/react";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";

const WalletCard = ({ name, description, imagePath }: { name: string; description: string; imagePath: string }) => {
    return (
        <Box
            p={6}
            borderRadius="xl"
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow="lg"
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-5px)', boxShadow: 'xl' }}
        >
            <Center mb={4} h="200px">
                <Box position="relative" h="100%" display="flex" alignItems="center">
                    <Image
                        src={imagePath || "/placeholder-wallet.jpg"}
                        alt={name}
                        maxH="100%"
                        objectFit="contain"
                        zIndex={1}
                    />
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bg="blue.500"
                        opacity={0.3}
                        borderRadius="md"
                        style={{
                            width: '100%',
                            height: '100%',
                            maskImage: 'url(' + imagePath + ')',
                            WebkitMaskImage: 'url(' + imagePath + ')',
                            maskSize: 'contain',
                            WebkitMaskSize: 'contain',
                            maskPosition: 'center',
                            WebkitMaskPosition: 'center',
                            maskRepeat: 'no-repeat',
                            WebkitMaskRepeat: 'no-repeat'
                        }}
                    />
                </Box>
            </Center>
            <Heading size="md" mb={3}>{name}</Heading>
            <Text color={useColorModeValue('gray.600', 'gray.300')}>{description}</Text>
        </Box>
    );
};

const wallets = [
    {
        name: "Ledger",
        description: "Known for its certified crypto asset hardware wallets, Ledger offers devices like the Ledger Nano S and Ledger Nano X, which provide optimal protection for various cryptocurrencies.",
        imagePath: "/wallets/ledger.png"
    },
    {
        name: "Trezor",
        description: "One of the pioneers in hardware wallets, Trezor offers models like the Trezor Model T and Trezor Model One. These wallets are known for their security features and support for multiple cryptocurrencies.",
        imagePath: "/wallets/trezor.png"
    },
    {
        name: "SafePal",
        description: "SafePal provides hardware wallets that support over 100 blockchain networks and millions of NFTs and tokens. Their devices include the SafePal S1 and SafePal S1 Pro, which feature a self-destruct mechanism for added security.",
        imagePath: "/wallets/safepal.png"
    },
    {
        name: "CoolWallet",
        description: "CoolWallet offers a unique credit card-sized hardware wallet that is convenient for users on the go. It supports multiple cryptocurrencies and integrates with mobile apps for easy management.",
        imagePath: "/wallets/coolwallet.jpg"
    },
    {
        name: "BitBox",
        description: "BitBox provides a compact and secure hardware wallet that focuses on ease of use and robust security features. It is designed to be user-friendly while maintaining high levels of security.",
        imagePath: "/wallets/bitbox.png"
    },
    {
        name: "CryptoTag",
        description: "CryptoTag offers a titanium backup solution for hardware wallets, ensuring that your seed phrases are securely stored offline.",
        imagePath: "/wallets/cryptotag.png"
    },
    {
        name: "Cryptosteel",
        description: "Cryptosteel provides a stainless-steel backup tool for your hardware wallet seed phrases, offering durability and resistance to various environmental factors.",
        imagePath: "/wallets/cryptosteel.png"
    },
    {
        name: "DCENT",
        description: "DCENT offers biometric hardware wallets that use fingerprint scanning for added security. These wallets support multiple cryptocurrencies and integrate with mobile apps.",
        imagePath: "/wallets/dcent.png"
    },
    {
        name: "Ellipal",
        description: "Ellipal Titan is an air-gapped hardware wallet that provides robust security against both digital and physical threats. It is fully metal sealed and uses anti-tamper technology to protect your cryptocurrencies.",
        imagePath: "/wallets/Ellipal.png"
    },
    {
        name: "KeepKey",
        description: "KeepKey is a hardware wallet that supports multiple cryptocurrencies and integrates with the ShapeShift platform for easy trading and management of digital assets.",
        imagePath: "/wallets/keepkey.jpg"
    },
    {
        name: "Ngrave",
        description: "Ngrave Zero is a high-security hardware wallet designed for institutional investors or those with large amounts of cryptocurrencies. It features a unique private key generation process and integrates with Ngrave Graphene for physical key backup.",
        imagePath: "/wallets/Ngrave.png"
    },
    {
        name: "GridPlus",
        description: "GridPlus offers the Lattice1 hardware wallet, which focuses on providing a secure and user-friendly experience for managing cryptocurrencies.",
        imagePath: "/wallets/GridPlus.png"
    },
    {
        name: "Cypherock",
        description: "Cypherock provides a hardware wallet that combines security with ease of use, making it a reliable option for storing cryptocurrencies.",
        imagePath: "/wallets/Cypherock.png"
    },
    {
        name: "Tangem",
        description: "Tangem offers a hardware wallet in the form of a smart card, providing a convenient and secure way to store and manage cryptocurrencies.",
        imagePath: "/wallets/tangem.jpg"
    }
];

export const HardwareWallets = () => {
    return (
        <Box minH="100vh" bg="gray.50">
            <NavbarWithCallToAction />

            <Container maxW="7xl" py={12}>
                <VStack spacing={12}>
                    {/* Header */}
                    <Box textAlign="center" mb={8}>
                        <Heading
                            size="2xl"
                            mb={4}
                            bgGradient="linear(to-r, #007AFF, #5856D6)"
                            bgClip="text"
                        >
                            Hardware Wallet Solutions
                        </Heading>
                        <Text fontSize="xl" color="gray.600">
                            Secure your digital assets with industry-leading hardware wallets
                        </Text>
                    </Box>

                    {/* Wallets Grid */}
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} w="full">
                        {wallets.map((wallet, index) => (
                            <WalletCard key={index} {...wallet} />
                        ))}
                    </SimpleGrid>

                    {/* Contact Form */}
                    <Box
                        w="full"
                        maxW="2xl"
                        mx="auto"
                        mt={16}
                        p={8}
                        bg="white"
                        borderRadius="xl"
                        boxShadow="xl"
                    >
                        <VStack spacing={6}>
                            <Heading size="lg" textAlign="center">Not Sure Which Wallet Is Right For You?</Heading>
                            <Text textAlign="center" color="gray.600">
                                Let us help you choose and set up the perfect hardware wallet for your needs.
                            </Text>

                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input placeholder="Your name" />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input type="email" placeholder="Your email" />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Your Requirements</FormLabel>
                                <Textarea
                                    placeholder="Tell us about your cryptocurrency needs and any specific requirements you have..."
                                    rows={4}
                                />
                            </FormControl>

                            <Button
                                w="full"
                                size="lg"
                                bgGradient="linear(to-r, #007AFF, #5856D6)"
                                color="white"
                                _hover={{
                                    bgGradient: "linear(to-r, #0066CC, #4845AA)",
                                }}
                            >
                                Get Expert Advice
                            </Button>
                        </VStack>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Box>
    );
};

export default HardwareWallets; 