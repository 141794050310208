// src/apollo/client.ts
import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { getApiUrl } from '../utils/helpers';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

// Create the upload link
const uploadLink = (createUploadLink as any)({
  uri: getApiUrl(),
});

// Create the auth link to inject headers
const authLink = setContext((operation, { headers }) => {
  const token = localStorage.getItem('auth_token');
  
  const operationName = operation.operationName;
  const isFileUpload = operationName?.includes('UploadFile');
  
  // Only include tenant ID for operations that need it
  const shouldIncludeTenantId = !operationName?.includes('AllPublicProducts');
  
  const finalHeaders = {
    ...headers,
    ...(shouldIncludeTenantId && { 'x-tenant-id': '678484d219501aaf7f4c09bb' }),
    'apollo-require-preflight': 'true',
    'x-apollo-operation-name': operationName,
    'content-type': isFileUpload ? 'multipart/form-data' : 'application/json',
    authorization: token ? `Bearer ${token}` : '',
  };

  return { headers: finalHeaders };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        services: {
          merge(existing = [], incoming) {
            return [...incoming];
          },
          keyArgs: false,
        }
      }
    },
    HDWalletService: {
      keyFields: ["id"],
    },
    WebsiteService: {
      keyFields: ["id"],
    }
  }
});

// Create WebSocket link
const wsLink = new GraphQLWsLink(createClient({
  url: getApiUrl().replace('http', 'ws'), // Convert HTTP URL to WebSocket URL
}));

// Combine the links
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  from([authLink, uploadLink])
);

// Update Apollo Client configuration
export const apolloClient = new ApolloClient({
  link: splitLink,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  }
});

console.log('API URL:', getApiUrl());