import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  UnorderedList,
  ListItem,
  OrderedList,
  Checkbox,
  Button,
  useToast,
  HStack,
  Badge,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const EcosystemAgreement = () => {
  const navigate = useNavigate();
  const toast = useToast();
  
  // State for tracking checkbox status
  const [agreements, setAgreements] = useState({
    benefits: false,
    responsibilities: false,
    principles: false,
    terms: false,
    privacy: false,
    disputes: false,
    updates: false
  });

  // Check if all boxes are checked
  const allChecked = Object.values(agreements).every(value => value === true);

  const handleSubmit = () => {
    if (allChecked) {
      // Show success toast
      toast({
        title: "Agreement Accepted",
        description: "Thank you for accepting the ecosystem agreement.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      
      // Navigate to products page
      navigate('/products');
    }
  };

  return (
    <Box minHeight="100vh">
      <NavbarWithCallToAction />

      <Container maxW="750px" py={12}>
        <VStack spacing={8} align="stretch">
          <HStack spacing={4} align="center">
            <Heading as="h1" size="2xl">
              Ecosystem Agreement
            </Heading>
            <Badge 
              colorScheme="orange" 
              fontSize="md" 
              p={2} 
              borderRadius="md"
            >
              DRAFT
            </Badge>
          </HStack>
          
          <Text color="gray.600" fontSize="lg">
            Last Updated: {new Date().toLocaleDateString()}
          </Text>

          <Text color="red.500" fontStyle="italic">
            * Subject to further review and updates
          </Text>

          <Text>
            Welcome to the Life Essentials Club Ecosystem. This agreement outlines the principles,
            rights, and responsibilities of being one of our first 100 founding members.
          </Text>

          <VStack spacing={6} align="stretch">
            <Heading as="h2" size="xl">1. Membership Benefits</Heading>
            <Text>
              As one of our first 100 founding members, you receive exclusive benefits including:
            </Text>
            <UnorderedList pl={4} spacing={2}>
              <ListItem>Permanent 15% discount on all products and services</ListItem>
              <ListItem>Early access to new products and collections</ListItem>
              <ListItem>Priority support and concierge service</ListItem>
              <ListItem>Voting rights on future product selections</ListItem>
              <ListItem>[Placeholder] Additional exclusive benefits</ListItem>
            </UnorderedList>
            <Box py={4}>
              <Checkbox 
                colorScheme="green"
                size="xl"
                isChecked={agreements.benefits}
                onChange={(e) => setAgreements({...agreements, benefits: e.target.checked})}
                sx={{
                  'span.chakra-checkbox__control': {
                    w: '32px',
                    h: '32px',
                  },
                  'span.chakra-checkbox__label': {
                    fontSize: 'lg',
                    fontWeight: 'medium',
                  }
                }}
              >
                I understand and agree to the membership benefits terms
              </Checkbox>
            </Box>

            <Heading as="h2" size="xl">2. Member Responsibilities</Heading>
            <Text>
              [Placeholder] Details about member responsibilities and expectations
            </Text>
            <OrderedList pl={4} spacing={2}>
              <ListItem>Community Engagement Guidelines</ListItem>
              <ListItem>Ethical Shopping Practices</ListItem>
              <ListItem>Feedback and Participation</ListItem>
              <ListItem>[Placeholder] Additional responsibilities</ListItem>
            </OrderedList>
            <Checkbox 
              colorScheme="green"
              size="xl"
              onChange={(e) => setAgreements({...agreements, responsibilities: e.target.checked})}
              sx={{
                'span.chakra-checkbox__control': {
                  w: '32px',
                  h: '32px',
                },
                'span.chakra-checkbox__label': {
                  fontSize: 'lg',
                  fontWeight: 'medium',
                }
              }}
            >
              I understand and accept my responsibilities as a member
            </Checkbox>

            <Heading as="h2" size="xl">3. Ecosystem Principles</Heading>
            <VStack spacing={4} align="stretch">
              <Text>
                [Placeholder] Our ecosystem is built on the following core principles:
              </Text>
              <UnorderedList pl={4} spacing={2}>
                <ListItem>Sustainability and Environmental Stewardship</ListItem>
                <ListItem>Fair Trade and Ethical Sourcing</ListItem>
                <ListItem>Community Support and Development</ListItem>
                <ListItem>Health and Wellness Promotion</ListItem>
                <ListItem>[Placeholder] Additional principles</ListItem>
              </UnorderedList>
            </VStack>
            <Checkbox 
              colorScheme="green"
              size="xl"
              onChange={(e) => setAgreements({...agreements, principles: e.target.checked})}
              sx={{
                'span.chakra-checkbox__control': {
                  w: '32px',
                  h: '32px',
                },
                'span.chakra-checkbox__label': {
                  fontSize: 'lg',
                  fontWeight: 'medium',
                }
              }}
            >
              I agree to uphold and support these ecosystem principles
            </Checkbox>

            <Heading as="h2" size="xl">4. Terms of Membership</Heading>
            <Text>
              [Placeholder] Detailed terms of the founding membership, including:
            </Text>
            <UnorderedList pl={4} spacing={2}>
              <ListItem>Lifetime Membership Status</ListItem>
              <ListItem>Transferability Conditions</ListItem>
              <ListItem>Account Management</ListItem>
              <ListItem>[Placeholder] Additional terms</ListItem>
            </UnorderedList>
            <Checkbox 
              colorScheme="green"
              size="xl"
              onChange={(e) => setAgreements({...agreements, terms: e.target.checked})}
              sx={{
                'span.chakra-checkbox__control': {
                  w: '32px',
                  h: '32px',
                },
                'span.chakra-checkbox__label': {
                  fontSize: 'lg',
                  fontWeight: 'medium',
                }
              }}
            >
              I accept the terms of membership
            </Checkbox>

            <Heading as="h2" size="xl">5. Privacy and Data</Heading>
            <Text>
              [Placeholder] Information about how we handle member data and privacy
            </Text>
            <Checkbox 
              colorScheme="green"
              size="xl"
              onChange={(e) => setAgreements({...agreements, privacy: e.target.checked})}
              sx={{
                'span.chakra-checkbox__control': {
                  w: '32px',
                  h: '32px',
                },
                'span.chakra-checkbox__label': {
                  fontSize: 'lg',
                  fontWeight: 'medium',
                }
              }}
            >
              I understand and agree to the privacy and data terms
            </Checkbox>

            <Heading as="h2" size="xl">6. Dispute Resolution</Heading>
            <Text>
              [Placeholder] Process for handling disputes and grievances
            </Text>
            <Checkbox 
              colorScheme="green"
              size="xl"
              onChange={(e) => setAgreements({...agreements, disputes: e.target.checked})}
              sx={{
                'span.chakra-checkbox__control': {
                  w: '32px',
                  h: '32px',
                },
                'span.chakra-checkbox__label': {
                  fontSize: 'lg',
                  fontWeight: 'medium',
                }
              }}
            >
              I agree to the dispute resolution process
            </Checkbox>

            <Heading as="h2" size="xl">7. Updates and Amendments</Heading>
            <Text>
              [Placeholder] How changes to this agreement will be handled and communicated
            </Text>
            <Checkbox 
              colorScheme="green"
              size="xl"
              onChange={(e) => setAgreements({...agreements, updates: e.target.checked})}
              sx={{
                'span.chakra-checkbox__control': {
                  w: '32px',
                  h: '32px',
                },
                'span.chakra-checkbox__label': {
                  fontSize: 'lg',
                  fontWeight: 'medium',
                }
              }}
            >
              I understand how updates and amendments will be handled
            </Checkbox>
          </VStack>

          <Box pt={8}>
            <Text fontStyle="italic" mb={6} color="gray.600">
              This ecosystem agreement is a living document that will evolve with input from our 
              founding members. All placeholder content will be replaced with detailed terms 
              developed in collaboration with our community.
            </Text>

            <Button
              colorScheme="blue"
              size="lg"
              width="full"
              height="60px"
              fontSize="xl"
              isDisabled={!allChecked}
              onClick={handleSubmit}
            >
              Submit Ecosystem Agreement
            </Button>

            {!allChecked && (
              <Text color="red.500" mt={2} fontSize="md" textAlign="center">
                Please review and accept all terms to continue
              </Text>
            )}
          </Box>
        </VStack>
      </Container>

      <FooterWithFourColumns />
    </Box>
  );
};

export default EcosystemAgreement; 