import React from "react";
import {
  Box,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Button,
  Skeleton,
  useToast,
  Badge,
  HStack,
  IconButton,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { ApolloCache, gql, useMutation, useQuery } from "@apollo/client";
import { EditIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { NavigationButtons } from '../components/NavigationButtons';
import { FiUsers, FiUserCheck, FiUserPlus, FiPhone } from "react-icons/fi";

const GET_CLIENTS = gql`
  query GetClients {
    clients {
      id
      fName
      lName
      email
      phoneNumber
    }
  }
`;

const DELETE_CLIENT = gql`
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id)
  }
`;

interface Client {
  id: string;
  fName: string;
  lName: string;
  email: string;
  phoneNumber: string;
}

interface DeleteClientResponse {
  deleteClient: boolean;
}

interface GetClientsResponse {
  clients: Client[];
}

const StatCard = ({ 
  title, 
  stat, 
  icon, 
  color 
}: { 
  title: string; 
  stat: number; 
  icon: React.ReactNode; 
  color: string; 
}) => {
  return (
    <Box
      p={6}
      bg="white"
      borderRadius="lg"
      boxShadow="sm"
      border="1px"
      borderColor="gray.100"
    >
      <HStack spacing={4}>
        <Box color={color}>
          {icon}
        </Box>
        <Stat>
          <StatLabel color="gray.500">{title}</StatLabel>
          <StatNumber fontSize="2xl" fontWeight="bold">
            {stat}
          </StatNumber>
        </Stat>
      </HStack>
    </Box>
  );
};

const getNewClientsThisMonth = (clients?: Client[]) => {
  if (!clients) return 0;
  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  // Note: This is just a placeholder since we don't have a creation date in the current client data
  // You might want to add a createdAt field to your client data to make this accurate
  return Math.floor(clients.length * 0.2); // Returns 20% of total clients as an example
};

const ClientsList = () => {
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(GET_CLIENTS);
  const toast = useToast();
  const [deleteClientMutation, { loading: deleteLoading }] = useMutation<
    DeleteClientResponse,
    { id: string }
  >(DELETE_CLIENT);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [clientToDelete, setClientToDelete] = React.useState<Client | null>(
    null
  );

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleEdit = (client: Client) => {
    navigate(`/client/${client.id}`);
  };

  const handleDelete = (client: Client) => {
    setClientToDelete(client);
    onOpen();
  };

  const handleViewClient = (client: Client) => {
    navigate(`/client/${client.id}`);
  };

  const confirmDelete = async () => {
    if (!clientToDelete) return;

    try {
      const response = await deleteClientMutation({
        variables: { id: clientToDelete.id },
        update(cache: ApolloCache<unknown>) {
          const existingData = cache.readQuery<GetClientsResponse>({
            query: GET_CLIENTS,
          });

          if (existingData) {
            cache.writeQuery({
              query: GET_CLIENTS,
              data: {
                clients: existingData.clients.filter(
                  (c) => c.id !== clientToDelete.id
                ),
              },
            });
          }
        },
      });

      if (response.data?.deleteClient) {
        toast({
          title: "Client deleted",
          description: `${clientToDelete.fName} ${clientToDelete.lName} has been successfully removed.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        setClientToDelete(null);
      } else {
        throw new Error("Failed to delete client");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to delete client",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (error) {
    toast({
      title: "Error loading clients",
      description: error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }

  return (
    <>
      <NavbarWithCallToAction /> 
      <Container maxW="container.xl" py={8}>
        <Card>
          <CardHeader>
            <HStack justify="space-between">
              <Heading size="lg">Clients</Heading>
              <Button
                colorScheme="green"
                onClick={() => navigate('/newclient')}
              >
                New Client
              </Button>
            </HStack>
          </CardHeader>

          <Box px={6} pb={6}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
              <StatCard
                title="Total Clients"
                stat={data?.clients?.length || 0}
                icon={<FiUsers size="3em" />}
                color="blue.500"
              />
              <StatCard
                title="Active Clients"
                stat={data?.clients?.filter((client: Client) => client.email).length || 0}
                icon={<FiUserCheck size="3em" />}
                color="green.500"
              />
              <StatCard
                title="New This Month"
                stat={getNewClientsThisMonth(data?.clients)}
                icon={<FiUserPlus size="3em" />}
                color="purple.500"
              />
              <StatCard
                title="With Phone Numbers"
                stat={data?.clients?.filter((client: Client) => client.phoneNumber).length || 0}
                icon={<FiPhone size="3em" />}
                color="orange.500"
              />
            </SimpleGrid>
          </Box>

          <CardBody>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Phone</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    // Loading state with skeleton rows
                    [...Array(3)].map((_, index) => (
                      <Tr key={`skeleton-${index}`}>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                        <Td>
                          <Skeleton height="20px" />
                        </Td>
                      </Tr>
                    ))
                  ) : data?.clients?.length ? (
                    // Actual data rows
                    data.clients.map((client: Client) => (
                      <Tr key={client.id}>
                        <Td>
                          <Text fontWeight="medium">{`${client.fName} ${client.lName}`}</Text>
                        </Td>
                        <Td>
                          <Text fontSize="sm">{client.email}</Text>
                        </Td>
                        <Td>
                          <Text fontSize="sm">{client.phoneNumber}</Text>
                        </Td>
                        <Td>
                          <HStack spacing={2}>
                            <IconButton
                              aria-label="Edit client"
                              icon={<EditIcon />}
                              size="sm"
                              colorScheme="blue"
                              onClick={() => handleEdit(client)}
                            />
                            <IconButton
                              aria-label="Delete client"
                              icon={<DeleteIcon />}
                              size="sm"
                              colorScheme="red"
                              onClick={() => handleDelete(client)}
                            />
                            <IconButton
                              aria-label="View client"
                              icon={<ViewIcon />}
                              size="sm"
                              colorScheme="teal"
                              onClick={() => handleViewClient(client)}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    // No data state
                    <Tr>
                      <Td colSpan={5} textAlign="center" py={8}>
                        <Text color="gray.500">No clients found</Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </CardBody>
        </Card>
        <FooterWithFourColumns />
      </Container>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Client
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete {clientToDelete?.fName} {clientToDelete?.lName}? This
              action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={confirmDelete}
                ml={3}
                isLoading={deleteLoading}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ClientsList;
