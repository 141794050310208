import React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  Button,
  Progress,
  Stat,
  StatNumber,
  StatLabel,
  Divider,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { gql, useQuery } from '@apollo/client';

// Add this query
const GET_MEMBERS_COUNT = gql`
  query GetClients {
    clients {
      id
    }
  }
`;

interface Client {
  id: string;
}

interface GetClientsResponse {
  clients: Client[];
}

// Add interfaces for the component props
interface CategoryCardProps {
  title: string;
  description: string;
  items: string[];
}

interface BenefitItemProps {
  title: string;
  description: string;
}

const CATEGORIES = [
  {
    title: "Healthy Home",
    description: "Products and services to create a safe, clean, and sustainable living environment.",
    items: [
      "Air Quality & Purifiers",
      "Water Quality & Filtration",
      "Eco-Friendly Living",
      "Cleaning Essentials",
      "Home Safety"
    ]
  },
  {
    title: "Healthy Foods",
    description: "Nutritious and accessible food options for optimal health.",
    items: [
      "Fresh & Organic Produce",
      "Pantry Essentials",
      "Superfoods & Supplements",
      "Meal Kits & Ready-To-Eat Options",
      "Beverages"
    ]
  },
  {
    title: "Personal Wellbeing",
    description: "Products and services to support mental, emotional, and physical health.",
    items: [
      "Fitness & Movement",
      "Mental Health Support",
      "Skincare & Self-Care",
      "Sleep & Relaxation",
      "Work-Life Balance Tools",
      "Digital Wellbeing"
    ]
  },
  {
    title: "Relationships",
    description: "Resources to nurture healthy interpersonal connections.",
    items: [
      "Couples Enrichment",
      "Parenting Support",
      "Conflict Resolution",
      "Social Wellbeing"
    ]
  },
  {
    title: "Family Development",
    description: "Tools and resources for building resilient, thriving families.",
    items: [
      "Child Development",
      "Financial Literacy for Families",
      "Multigenerational Support",
      "Life Events & Milestones"
    ]
  },
  {
    title: "Economic Wellbeing",
    description: "Resources to support financial health and independence.",
    items: [
      "Budgeting & Planning",
      "Income Generation",
      "Investment & Savings",
      "Debt Management"
    ]
  },
  {
    title: "Lifelong Learning & Skills",
    description: "Educational tools for personal and professional growth.",
    items: [
      "Online Courses & Webinars",
      "Books & Publications",
      "Hobby Development",
      "Tech Literacy"
    ]
  },
  {
    title: "Sustainability & Community Wellbeing",
    description: "Promoting eco-conscious living and community care.",
    items: [
      "Sustainable Products",
      "Community Care Kits",
      "Donation Matching",
      "Workshops & Events"
    ]
  },
  {
    title: "Emergency Preparedness",
    description: "Ready resources for unexpected events.",
    items: [
      "Emergency Kits",
      "Natural Disaster Prep",
      "Mental Resilience Tools",
      "Financial Crisis Resources"
    ]
  }
];

const HomePage = () => {
  const navigate = useNavigate();
  const { data } = useQuery<GetClientsResponse>(GET_MEMBERS_COUNT);

  // Calculate real numbers
  const TOTAL_LEGACY_MEMBERSHIPS = 100;
  const CURRENT_MEMBERS = data?.clients?.length || 0;
  const REMAINING_SPOTS = TOTAL_LEGACY_MEMBERSHIPS - CURRENT_MEMBERS;

  return (
    <>
      <NavbarWithCallToAction />
      <Box minH="100vh" bg={"#EFEDDC"}>
        <Container maxW="container.xl" py={20}>
          <VStack spacing={16}>
            {/* Hero Section */}
            <VStack spacing={6} textAlign="center">
              <Heading size="2xl">Life Essentials Club</Heading>
              <Text fontSize="xl" maxW="800px">
                Join our exclusive community of 100 founding members dedicated to living healthier, 
                more balanced lives through carefully curated products and services.
              </Text>
            </VStack>

            {/* Legacy Membership Status */}
            <Box w="full" maxW="800px" p={8} borderRadius="xl" bg="blue.50">
              <VStack spacing={6}>
                <Heading size="md">Legacy Membership Opportunity</Heading>
                <Text textAlign="center">
                  Be one of only {REMAINING_SPOTS} remaining founding members to secure lifetime benefits 
                  and help shape the future of Life Essentials Club.
                </Text>
                
                <Stat textAlign="center">
                  <StatLabel fontSize="lg">Available Legacy Memberships</StatLabel>
                  <StatNumber fontSize="4xl" color="blue.600">
                    {REMAINING_SPOTS} of {TOTAL_LEGACY_MEMBERSHIPS}
                  </StatNumber>
                </Stat>

                <Progress 
                  value={CURRENT_MEMBERS} 
                  max={TOTAL_LEGACY_MEMBERSHIPS} 
                  size="lg" 
                  colorScheme="blue" 
                  w="full"
                />
                
                <Text fontSize="sm" color="gray.600">
                  {CURRENT_MEMBERS} members have already joined
                </Text>

                <Button 
                  size="lg" 
                  colorScheme="blue"
                  onClick={() => navigate('/100members')}
                >
                  Secure Your Legacy Membership
                </Button>
              </VStack>
            </Box>

            {/* Category Showcase */}
            <Box w="full">
              <Heading size="xl" mb={8} textAlign="center">Our Product Categories</Heading>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                {CATEGORIES.map((category) => (
                  <CategoryCard
                    key={category.title}
                    title={category.title}
                    description={category.description}
                    items={category.items}
                  />
                ))}
              </SimpleGrid>

              <Box textAlign="center" mt={12}>
                <Button 
                  size="lg" 
                  colorScheme="blue" 
                  onClick={() => navigate('/100members')}
                >
                  Secure Your Legacy Membership
                </Button>
              </Box>
            </Box>

            {/* Member Benefits */}
            <Box w="full" bg="gray.50" p={8} borderRadius="xl">
              <VStack spacing={6}>
                <Heading size="lg">Legacy Member Benefits</Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <BenefitItem 
                    title="15% Lifetime Discount" 
                    description="Enjoy permanent savings on all products and services"
                  />
                  <BenefitItem 
                    title="Early Access" 
                    description="Be first to shop new products and collections"
                  />
                  <BenefitItem 
                    title="Exclusive Events" 
                    description="Access to member-only workshops and community events"
                  />
                  <BenefitItem 
                    title="Shape Our Future" 
                    description="Direct input on new products and services"
                  />
                </SimpleGrid>
              </VStack>
            </Box>
          </VStack>
        </Container>
      </Box>
      <FooterWithFourColumns />
    </>
  );
};

// Update the component definitions with types
const CategoryCard = ({ title, description, items }: CategoryCardProps) => (
  <Box p={6} borderRadius="lg" border="1px" borderColor="gray.200">
    <VStack align="start" spacing={4}>
      <Heading size="md">{title}</Heading>
      <Text>{description}</Text>
      <Divider />
      <VStack align="start" spacing={2}>
        {items.map((item: string) => (
          <Text key={item} fontSize="sm">• {item}</Text>
        ))}
      </VStack>
    </VStack>
  </Box>
);

const BenefitItem = ({ title, description }: BenefitItemProps) => (
  <Box p={4}>
    <Heading size="sm" mb={2}>{title}</Heading>
    <Text fontSize="sm" color="gray.600">{description}</Text>
  </Box>
);

export default HomePage;