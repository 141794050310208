import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Flex,
    Image,
    Button,
    SimpleGrid,
    Icon,
    VStack,
} from "@chakra-ui/react";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { FaShieldAlt, FaLaptopCode, FaLink, FaWallet, FaCubes } from 'react-icons/fa';

export const AboutTom = () => {
    return (
        <Flex direction="column" minHeight="100vh" bg="gray.50">
            <NavbarWithCallToAction />
            
            <Container maxW="6xl" py={20}>
                <VStack spacing={12} align="stretch">
                    {/* About Tom Section */}
                    <Box 
                        p={8} 
                        backdropFilter="blur(10px)"
                        bg="rgba(255, 255, 255, 0.8)"
                        borderRadius="2xl"
                        boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                    >
                        <Flex direction={{ base: "column", md: "row" }} gap={8} align="center">
                            <Box flex="1">
                                <Heading size="lg" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                                    About Tom Miller
                                </Heading>
                                <Text color="gray.700" fontSize="lg">
                                    With 10 years of blockchain development experience and a pending PhD in Blockchain Security, 
                                    Tom Miller specialises in creating secure, scalable solutions for modern businesses.
                                </Text>
                            </Box>
                            <Box
                                flex="1"
                                height="350px"
                                borderRadius="xl"
                                display="flex"
                                justifyContent="center"
                            >
                                <Image
                                    src="/tomprofile.jpeg"
                                    alt="Tom Miller Profile"
                                    objectFit="contain"
                                    width="auto"
                                    height="100%"
                                    borderRadius="xl"
                                />
                            </Box>
                        </Flex>
                    </Box>

                    {/* Services Grid */}
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} w="full">
                        {/* Custom Website Development Section */}
                        <Box 
                            p={8} 
                            backdropFilter="blur(10px)"
                            bg="rgba(255, 255, 255, 0.8)"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.2s"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Flex direction="column" h="full">
                                <Heading size="md" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                                    Custom Website Development
                                </Heading>
                                <Text color="gray.700" flex="1" mb={4}>
                                    Modern, responsive websites built with the latest technologies. We create custom solutions 
                                    tailored to your business needs, ensuring optimal performance and user experience.
                                </Text>
                                <Button
                                    as="a"
                                    href="/custom-website-development"
                                    bgGradient="linear(to-r, #007AFF, #5856D6)"
                                    color="white"
                                    _hover={{
                                        bgGradient: "linear(to-r, #0066CC, #4845AA)",
                                        transform: "translateY(-2px)",
                                    }}
                                    transition="all 0.2s"
                                    mb={4}
                                >
                                    Learn More About Web Development
                                </Button>
                                <Icon as={FaLaptopCode} boxSize={8} color="blue.500" alignSelf="flex-end" />
                            </Flex>
                        </Box>

                        {/* Supply Chain Section */}
                        <Box 
                            p={8} 
                            backdropFilter="blur(10px)"
                            bg="rgba(255, 255, 255, 0.8)"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.2s"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Flex direction="column" h="full">
                                <Heading size="md" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                                    Supply Chain Tracking Solutions
                                </Heading>
                                <Text color="gray.700" flex="1" mb={4}>
                                    Implement blockchain-based supply chain tracking systems to enhance transparency, 
                                    reduce fraud, and improve efficiency in your logistics operations. Several of our 
                                    supply chain projects have been published in collaboration with Queensland University of Technology.
                                </Text>
                                <Flex align="center" justify="space-between" mt={2} mb={4}>
                                    <Image
                                        src="/brands/qutlogo.png" 
                                        alt="QUT Logo"
                                        height="40px"
                                        objectFit="contain"
                                    />
                                    <Button
                                        as="a"
                                        href="https://eprints.qut.edu.au/view/person/Miller,_Thomas.html"
                                        target="_blank"
                                        bgGradient="linear(to-r, #007AFF, #5856D6)"
                                        color="white"
                                        _hover={{
                                            bgGradient: "linear(to-r, #0066CC, #4845AA)",
                                            transform: "translateY(-2px)",
                                        }}
                                        transition="all 0.2s"
                                    >
                                        View Research Publications
                                    </Button>
                                </Flex>
                                <Icon as={FaLink} boxSize={8} color="blue.500" alignSelf="flex-end" />
                            </Flex>
                        </Box>

                        {/* Crypto Wallet Integration Section */}
                        <Box 
                            p={8} 
                            backdropFilter="blur(10px)"
                            bg="rgba(255, 255, 255, 0.8)"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.2s"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Flex direction="column" h="full">
                                <Heading size="md" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                                    Crypto Wallet Integration
                                </Heading>
                                <Text color="gray.700" flex="1" mb={4}>
                                    Seamlessly integrate cryptocurrency wallets into your applications, enabling secure 
                                    transactions and digital asset management for your users.
                                </Text>
                                <Button
                                    as="a"
                                    href="/hardware-wallets"
                                    bgGradient="linear(to-r, #007AFF, #5856D6)"
                                    color="white"
                                    _hover={{
                                        bgGradient: "linear(to-r, #0066CC, #4845AA)",
                                        transform: "translateY(-2px)",
                                    }}
                                    transition="all 0.2s"
                                    mb={4}
                                >
                                    Explore Hardware Wallets
                                </Button>
                                <Icon as={FaWallet} boxSize={8} color="blue.500" alignSelf="flex-end" />
                            </Flex>
                        </Box>

                        {/* Web3 Implementation Section */}
                        <Box 
                            p={8} 
                            backdropFilter="blur(10px)"
                            bg="rgba(255, 255, 255, 0.8)"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.2s"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                        >
                            <Flex direction="column" h="full">
                                <Heading size="md" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                                    Web3 Implementation
                                </Heading>
                                <Text color="gray.700" flex="1">
                                    Transform your traditional web applications into decentralized Web3 platforms, 
                                    leveraging blockchain technology for enhanced security and functionality.
                                </Text>
                                <Icon as={FaCubes} boxSize={8} color="blue.500" mt={4} />
                            </Flex>
                        </Box>

                        {/* Hardware Wallet Setup Section - Full Width */}
                        <Box 
                            p={8} 
                            backdropFilter="blur(10px)"
                            bg="rgba(255, 255, 255, 0.8)"
                            borderRadius="2xl"
                            boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                            transition="all 0.2s"
                            _hover={{
                                transform: "translateY(-4px)",
                                boxShadow: "0 12px 36px -8px rgba(0, 0, 0, 0.15)"
                            }}
                            gridColumn={{ lg: "span 2" }}
                        >
                            <Flex direction={{ base: "column", md: "row" }} align="center" gap={6}>
                                <Box flex="1">
                                    <Heading size="md" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                                        Hardware Wallet Setup & Security Training
                                    </Heading>
                                    <Text color="gray.700">
                                        Expert guidance on hardware wallet setup and comprehensive security training to protect 
                                        your digital assets. Learn best practices for secure cryptocurrency storage and 
                                        management, including backup procedures and security protocols.
                                    </Text>
                                </Box>
                                <Icon as={FaShieldAlt} boxSize={12} color="blue.500" />
                            </Flex>
                        </Box>
                    </SimpleGrid>

                    {/* Call to Action */}
                    <Box 
                        textAlign="center" 
                        py={10}
                        p={8}
                        backdropFilter="blur(10px)"
                        bg="rgba(255, 255, 255, 0.8)"
                        borderRadius="2xl"
                        boxShadow="0 8px 32px -8px rgba(0, 0, 0, 0.1)"
                    >
                        <Heading size="lg" mb={4} bgGradient="linear(to-r, #007AFF, #5856D6)" bgClip="text">
                            Ready to Transform Your Business?
                        </Heading>
                        <Text mb={6} color="gray.700">
                            Let's discuss how blockchain and web solutions can drive your business forward.
                        </Text>
                        <Button 
                            bgGradient="linear(to-r, #007AFF, #5856D6)"
                            _hover={{
                                bgGradient: "linear(to-r, #0066CC, #4845AA)",
                            }}
                            color="white"
                            size="lg"
                            as="a" 
                            href="https://calendly.com/tom-miller-services"
                            target="_blank"
                            boxShadow="0 4px 12px rgba(0, 122, 255, 0.3)"
                            borderRadius="xl"
                        >
                            Schedule a Meeting
                        </Button>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Flex>
    );
};

export default AboutTom; 