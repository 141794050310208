import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Progress,
  Stat,
  StatLabel,
  StatNumber,
  Badge,
  HStack,
  UnorderedList,
  ListItem,
  OrderedList,
  Checkbox,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { LoginModal } from '../components/LoginModal';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../hooks/useAuth';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const CREATE_CLIENT = gql`
  mutation CreateClient($input: ClientInput!) {
    createClient(input: $input) {
      id
      fName
      lName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;

const GET_CLIENT_BY_EMAIL = gql`
  query GetClientByEmail($email: String!) {
    clientByEmail(email: $email) {
      id
      fName
      lName
      phoneNumber
    }
  }
`;

const GET_MEMBERS_COUNT = gql`
  query GetClients {
    clients {
      id
    }
  }
`;

const phoneInputStyle = {
  '.PhoneInputCountryIcon': {
    width: '1.5em',
    height: '1.5em'
  },
  '.PhoneInputCountrySelectArrow': {
    width: '0.5em',
    height: '0.5em'
  },
  '.PhoneInputInput': {
    height: '2.5rem',
    paddingLeft: '1rem'
  }
};

const HundredMembers = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { email } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState({
    fName: '',
    lName: '',
    email: '',
    phoneNumber: '',
  });

  const [formErrors, setFormErrors] = useState({
    fName: '',
    lName: '',
    email: '',
    phoneNumber: '',
  });

  const [createClient, { loading: submitLoading }] = useMutation(CREATE_CLIENT, {
    refetchQueries: ['GetClients']
  });

  const { data: clientData } = useQuery(GET_CLIENT_BY_EMAIL, {
    variables: { email: email || '' },
    skip: !email,
    onCompleted: (data) => {
      if (data?.clientByEmail) {
        setFormData(prev => ({
          ...prev,
          fName: data.clientByEmail.fName || '',
          lName: data.clientByEmail.lName || '',
          phoneNumber: data.clientByEmail.phoneNumber || ''
        }));
      }
    }
  });

  const { data: membersData } = useQuery(GET_MEMBERS_COUNT);
  
  const TOTAL_LEGACY_MEMBERSHIPS = 100;
  const CURRENT_MEMBERS = membersData?.clients?.length || 0;
  const REMAINING_SPOTS = TOTAL_LEGACY_MEMBERSHIPS - CURRENT_MEMBERS;
  const YOUR_MEMBER_NUMBER = CURRENT_MEMBERS + 1;

  const [agreements, setAgreements] = useState(() => {
    const savedAgreements = localStorage.getItem('memberAgreements');
    return savedAgreements ? JSON.parse(savedAgreements) : {
      benefits: false,
      responsibilities: false,
      principles: false,
      terms: false,
      privacy: false,
      disputes: false,
      updates: false
    };
  });

  useEffect(() => {
    localStorage.setItem('memberAgreements', JSON.stringify(agreements));
  }, [agreements]);

  const allAgreementsChecked = Object.values(agreements).every(value => value === true);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    if (email) {
      setFormData(prev => ({
        ...prev,
        email: email
      }));
    }
  }, [email]);

  const validateForm = () => {
    const errors = {
      fName: '',
      lName: '',
      email: '',
      phoneNumber: '',
    };
    let isValid = true;

    if (!formData.fName.trim()) {
      errors.fName = 'First name is required';
      isValid = false;
    }

    if (!formData.lName.trim()) {
      errors.lName = 'Last name is required';
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email format';
      isValid = false;
    }

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.fName.trim() || !formData.lName.trim() || !formData.email.trim()) {
      toast({
        title: "Missing Information",
        description: "Please fill in all required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.phoneNumber || 
        !formData.phoneNumber.match(/^\+[1-9]\d{1,14}$/)) {
      toast({
        title: "Invalid phone number",
        description: "Please enter a valid phone number in E.164 format (e.g., +12345678901)",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const cleanPhoneNumber = formData.phoneNumber.replace(/\s+/g, '');
      
      const { data } = await createClient({
        variables: {
          input: {
            fName: formData.fName.trim(),
            lName: formData.lName.trim(),
            email: formData.email.trim(),
            phoneNumber: cleanPhoneNumber
          }
        }
      });
      
      if (data?.createClient) {
        localStorage.removeItem('memberAgreements');
        setAgreements({
          benefits: false,
          responsibilities: false,
          principles: false,
          terms: false,
          privacy: false,
          disputes: false,
          updates: false
        });
        
        toast({
          title: "Success!",
          description: "You're now part of our first 100 members!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        
        navigate("/welcome");
      }
    } catch (error) {
      console.error('Error creating client:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to submit form",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.lg" py={10}>
        <VStack spacing={8} align="stretch">
          <Box textAlign="center" py={10}>
            <Heading as="h1" size="2xl" mb={6}>
              Join Our First 100 Members
            </Heading>
            <Text fontSize="xl" mb={4}>
              We're building a revolutionary marketplace for life essentials, and we want you to be part of our founding community.
            </Text>
            
            <Box w="full" maxW="600px" mx="auto" p={8} borderRadius="xl" bg="blue.50" mb={8}>
              <VStack spacing={6}>
                <Heading size="md">Your Legacy Membership Opportunity</Heading>
                <Text textAlign="center">
                  You will be member #{YOUR_MEMBER_NUMBER} of our exclusive founding community
                </Text>
                
                <Stat textAlign="center">
                  <StatLabel fontSize="lg">Available Legacy Memberships</StatLabel>
                  <StatNumber fontSize="4xl" color="blue.600">
                    {REMAINING_SPOTS} of {TOTAL_LEGACY_MEMBERSHIPS}
                  </StatNumber>
                </Stat>

                <Progress 
                  value={CURRENT_MEMBERS} 
                  max={TOTAL_LEGACY_MEMBERSHIPS} 
                  size="lg" 
                  colorScheme="blue" 
                  w="full"
                />
                
                <Text fontSize="sm" color="gray.600">
                  {CURRENT_MEMBERS} members have already joined
                </Text>
              </VStack>
            </Box>

            <Text fontSize="lg" mb={6} color="blue.600" fontWeight="bold">
              The first 100 members get lifetime free access!
            </Text>
          </Box>

          <Box bg="gray.50" p={8} borderRadius="lg">
            <Heading as="h2" size="lg" mb={4}>
              Why Join Our First 100?
            </Heading>
            <VStack align="stretch" spacing={4}>
              <Text>✨ Lifetime free membership</Text>
              <Text>🌟 Early access to new features</Text>
              <Text>💫 Exclusive founding member benefits</Text>
              <Text>🤝 Help shape the future of our marketplace</Text>
            </VStack>
          </Box>

          <Box bg="gray.50" p={8} borderRadius="lg" mt={8}>
            <VStack spacing={8} align="stretch">
              <HStack spacing={4} align="center">
                <Heading as="h2" size="lg">
                  Ecosystem Agreement
                </Heading>
                <Badge 
                  colorScheme="orange" 
                  fontSize="md" 
                  p={2} 
                  borderRadius="md"
                >
                  DRAFT
                </Badge>
              </HStack>
              
              <Text color="gray.600" fontSize="lg">
                Last Updated: {new Date().toLocaleDateString()}
              </Text>

              <Text color="red.500" fontStyle="italic">
                * Subject to further review and updates
              </Text>

              <Text>
                Welcome to the Life Essentials Club Ecosystem. This agreement outlines the principles,
                rights, and responsibilities of being one of our first 100 founding members.
              </Text>

              <VStack spacing={6} align="stretch">
                <Box>
                  <Heading as="h3" size="md">1. Membership Benefits</Heading>
                  <Text mt={2}>
                    As one of our first 100 founding members, you receive exclusive benefits including:
                  </Text>
                  <UnorderedList pl={4} spacing={2} mt={2}>
                    <ListItem>Permanent 15% discount on all products and services</ListItem>
                    <ListItem>Early access to new products and collections</ListItem>
                    <ListItem>Priority support and concierge service</ListItem>
                    <ListItem>Voting rights on future product selections</ListItem>
                    <ListItem>[Placeholder] Additional exclusive benefits</ListItem>
                  </UnorderedList>
                  <Checkbox 
                    colorScheme="green"
                    size="xl"
                    mt={4}
                    isChecked={agreements.benefits}
                    onChange={(e) => setAgreements({...agreements, benefits: e.target.checked})}
                    sx={{
                      'span.chakra-checkbox__control': {
                        w: '32px',
                        h: '32px',
                      },
                      'span.chakra-checkbox__label': {
                        fontSize: 'lg',
                        fontWeight: 'medium',
                      }
                    }}
                  >
                    I understand and agree to the membership benefits terms
                  </Checkbox>
                </Box>

                <Box>
                  <Heading as="h3" size="md">2. Member Responsibilities</Heading>
                  <Text mt={2}>
                    [Placeholder] Details about member responsibilities and expectations
                  </Text>
                  <OrderedList pl={4} spacing={2} mt={2}>
                    <ListItem>Community Engagement Guidelines</ListItem>
                    <ListItem>Ethical Shopping Practices</ListItem>
                    <ListItem>Feedback and Participation</ListItem>
                    <ListItem>[Placeholder] Additional responsibilities</ListItem>
                  </OrderedList>
                  <Checkbox 
                    colorScheme="green"
                    size="xl"
                    mt={4}
                    isChecked={agreements.responsibilities}
                    onChange={(e) => setAgreements({...agreements, responsibilities: e.target.checked})}
                    sx={{
                      'span.chakra-checkbox__control': {
                        w: '32px',
                        h: '32px',
                      },
                      'span.chakra-checkbox__label': {
                        fontSize: 'lg',
                        fontWeight: 'medium',
                      }
                    }}
                  >
                    I understand and accept my responsibilities as a member
                  </Checkbox>
                </Box>

                <Box>
                  <Heading as="h3" size="md">3. Ecosystem Principles</Heading>
                  <Text mt={2}>
                    [Placeholder] Our ecosystem is built on the following core principles:
                  </Text>
                  <UnorderedList pl={4} spacing={2} mt={2}>
                    <ListItem>Sustainability and Environmental Stewardship</ListItem>
                    <ListItem>Fair Trade and Ethical Sourcing</ListItem>
                    <ListItem>Community Support and Development</ListItem>
                    <ListItem>Health and Wellness Promotion</ListItem>
                    <ListItem>[Placeholder] Additional principles</ListItem>
                  </UnorderedList>
                  <Checkbox 
                    colorScheme="green"
                    size="xl"
                    mt={4}
                    isChecked={agreements.principles}
                    onChange={(e) => setAgreements({...agreements, principles: e.target.checked})}
                    sx={{
                      'span.chakra-checkbox__control': {
                        w: '32px',
                        h: '32px',
                      },
                      'span.chakra-checkbox__label': {
                        fontSize: 'lg',
                        fontWeight: 'medium',
                      }
                    }}
                  >
                    I agree to uphold and support these ecosystem principles
                  </Checkbox>
                </Box>

                <Box>
                  <Heading as="h3" size="md">4. Terms of Membership</Heading>
                  <Text mt={2}>
                    [Placeholder] Detailed terms of the founding membership, including:
                  </Text>
                  <UnorderedList pl={4} spacing={2} mt={2}>
                    <ListItem>Lifetime Membership Status</ListItem>
                    <ListItem>Transferability Conditions</ListItem>
                    <ListItem>Account Management</ListItem>
                    <ListItem>[Placeholder] Additional terms</ListItem>
                  </UnorderedList>
                  <Checkbox 
                    colorScheme="green"
                    size="xl"
                    mt={4}
                    isChecked={agreements.terms}
                    onChange={(e) => setAgreements({...agreements, terms: e.target.checked})}
                    sx={{
                      'span.chakra-checkbox__control': {
                        w: '32px',
                        h: '32px',
                      },
                      'span.chakra-checkbox__label': {
                        fontSize: 'lg',
                        fontWeight: 'medium',
                      }
                    }}
                  >
                    I accept the terms of membership
                  </Checkbox>
                </Box>

                <Box>
                  <Heading as="h3" size="md">5. Privacy and Data</Heading>
                  <Text mt={2}>
                    [Placeholder] Information about how we handle member data and privacy
                  </Text>
                  <Checkbox 
                    colorScheme="green"
                    size="xl"
                    mt={4}
                    isChecked={agreements.privacy}
                    onChange={(e) => setAgreements({...agreements, privacy: e.target.checked})}
                    sx={{
                      'span.chakra-checkbox__control': {
                        w: '32px',
                        h: '32px',
                      },
                      'span.chakra-checkbox__label': {
                        fontSize: 'lg',
                        fontWeight: 'medium',
                      }
                    }}
                  >
                    I understand and agree to the privacy and data terms
                  </Checkbox>
                </Box>

                <Box>
                  <Heading as="h3" size="md">6. Dispute Resolution</Heading>
                  <Text mt={2}>
                    [Placeholder] Process for handling disputes and grievances
                  </Text>
                  <Checkbox 
                    colorScheme="green"
                    size="xl"
                    mt={4}
                    isChecked={agreements.disputes}
                    onChange={(e) => setAgreements({...agreements, disputes: e.target.checked})}
                    sx={{
                      'span.chakra-checkbox__control': {
                        w: '32px',
                        h: '32px',
                      },
                      'span.chakra-checkbox__label': {
                        fontSize: 'lg',
                        fontWeight: 'medium',
                      }
                    }}
                  >
                    I agree to the dispute resolution process
                  </Checkbox>
                </Box>

                <Box>
                  <Heading as="h3" size="md">7. Updates and Amendments</Heading>
                  <Text mt={2}>
                    [Placeholder] How changes to this agreement will be handled and communicated
                  </Text>
                  <Checkbox 
                    colorScheme="green"
                    size="xl"
                    mt={4}
                    isChecked={agreements.updates}
                    onChange={(e) => setAgreements({...agreements, updates: e.target.checked})}
                    sx={{
                      'span.chakra-checkbox__control': {
                        w: '32px',
                        h: '32px',
                      },
                      'span.chakra-checkbox__label': {
                        fontSize: 'lg',
                        fontWeight: 'medium',
                      }
                    }}
                  >
                    I understand how updates and amendments will be handled
                  </Checkbox>
                </Box>

                <Text fontStyle="italic" color="gray.600">
                  This ecosystem agreement is a living document that will evolve with input from our 
                  founding members. All placeholder content will be replaced with detailed terms 
                  developed in collaboration with our community.
                </Text>
              </VStack>
            </VStack>
          </Box>

          {!isAuthenticated ? (
            <Box textAlign="center" py={6}>
              <Button
                colorScheme="blue"
                size="lg"
                onClick={onOpen}
              >
                Join the First 100 Members
              </Button>
              <Text mt={4} fontSize="sm" color="gray.600">
                Authentication required to continue
              </Text>
            </Box>
          ) : (
            <Box mt={6}>
              <Heading as="h3" size="md" mb={4} textAlign="center">
                Complete Your Membership Registration
              </Heading>
              <Text textAlign="center" mb={6} color="gray.600">
                You'll be member #{YOUR_MEMBER_NUMBER} after completing registration
              </Text>
              <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                  <FormControl isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      name="fName"
                      value={formData.fName}
                      onChange={handleInputChange}
                      placeholder="Enter first name"
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      name="lName"
                      value={formData.lName}
                      onChange={handleInputChange}
                      placeholder="Enter last name"
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      value={formData.email}
                      isReadOnly
                      bg="gray.50"
                    />
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput
                      international
                      defaultCountry="AU"
                      value={formData.phoneNumber}
                      onChange={(value) => {
                        console.log('Phone input value:', value);
                        setFormData(prev => ({ ...prev, phoneNumber: value || '' }))
                      }}
                      placeholder="Enter phone number"
                      style={phoneInputStyle}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    colorScheme="blue"
                    size="lg"
                    width="full"
                    mt={4}
                    isLoading={submitLoading}
                    isDisabled={!allAgreementsChecked}
                    onClick={handleSubmit}
                  >
                    Complete Registration
                  </Button>

                  {!allAgreementsChecked && (
                    <Text color="red.500" mt={2} fontSize="md" textAlign="center">
                      Please review and accept all terms to continue
                    </Text>
                  )}
                </VStack>
              </form>
            </Box>
          )}
        </VStack>
      </Container>
      <LoginModal isOpen={isOpen} onClose={onClose} />
      <FooterWithFourColumns />
    </>
  );
};

export default HundredMembers; 