import React from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  useToast,
  Box,
  Progress,
  Text,
  Step,
  Wrap,
  WrapItem,
  VStack,
  Badge,
  Grid,
  GridItem,
  Icon,
  Divider,
  useColorModeValue,
  HStack,
  Button,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { FiCalendar, FiUser, FiGlobe, FiFileText } from "react-icons/fi";
import { EditIcon } from "@chakra-ui/icons";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { VerticalProgressBar } from "../components/chakra/VerticalProgressBar/VerticalProgressBar";
import { HDWalletServiceComponent } from "../components/HDWalletServiceComponent/HDWalletServiceComponent";
import { NavigationButtons } from '../components/NavigationButtons';
import { useState, useEffect } from 'react';

const GET_SERVICE = gql`
  query GetService($id: ID!) {
    service(id: $id) {
      ... on HDWalletService {
        id
        type
        serviceStartDate
        startDate
        client {
          fName
          lName
        }
      }
      ... on WebsiteService {
        id
        type
        serviceStartDate
        websiteName
        jobSummary
        client {
          fName
          lName
        }
      }
    }
  }
`;

const WEBSITE_STAGES = [
  { id: "GATHERING", label: "Information Gathering", description: "Collecting requirements and content" },
  { id: "PROTOTYPE", label: "Prototype", description: "Initial design and wireframes" },
  { id: "FEEDBACK_1", label: "First Review", description: "Client feedback on prototype" },
  { id: "DEVELOPMENT_1", label: "Development", description: "Building core functionality" },
  { id: "FEEDBACK_2", label: "Second Review", description: "Client feedback on development" },
  { id: "DEVELOPMENT_2", label: "Refinement", description: "Implementing feedback and polishing" },
  { id: "TESTING", label: "Testing", description: "Quality assurance and testing" },
  { id: "COMPLETE", label: "Launch", description: "Website goes live" }
];

const UPDATE_SERVICE = gql`
  mutation UpdateService($id: ID!, $input: UpdateServiceInput!) {
    updateService(id: $id, input: $input) {
      ... on WebsiteService {
        id
        websiteName
        serviceStartDate
        jobSummary
        client {
          fName
          lName
        }
      }
    }
  }
`;

export default function ServiceDetails() {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    websiteName: "",
    serviceStartDate: "",
    jobSummary: "",
  });

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const pageBg = useColorModeValue('gray.50', 'gray.900');
  const timelineBg = useColorModeValue('gray.50', 'gray.700');
  const timelineHeadingColor = useColorModeValue('gray.600', 'gray.300');

  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAuthorized(storedPassword === "123456");
  }, []);

  const { loading, error, data } = useQuery(GET_SERVICE, {
    variables: { id },
    onError: (error) => {
      toast({
        title: "Error loading service",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  });

  useEffect(() => {
    if (data?.service && data.service.type === "WEBSITE") {
      console.log('GraphQL Response:', data.service);
      console.log('Setting form data with jobSummary:', data.service.jobSummary);
      setFormData({
        websiteName: data.service.websiteName || "",
        serviceStartDate: new Date(data.service.serviceStartDate).toISOString().split('T')[0],
        jobSummary: data.service.jobSummary || "",
      });
    }
  }, [data]);

  const [updateService, { loading: updateLoading }] = useMutation(UPDATE_SERVICE, {
    onCompleted: () => {
      toast({
        title: "Success",
        description: "Service updated successfully",
        status: "success",
        duration: 5000,
      });
      setIsEditing(false);
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    },
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateService({
      variables: {
        id: id,
        input: formData,
      },
    });
  };

  const renderWebsiteService = (service: any) => {
    console.log('Rendering website service with data:', {
      service,
      formData,
      jobSummary: service.jobSummary
    });
    
    return (
      <>
        <NavbarWithCallToAction />
        <Box bg={pageBg} minH="100vh" py={8}>
          <Container maxW="4xl">
            {isAuthorized && (
              <Box mb={6}>
                {/* <NavigationButtons showRefresh /> */}
              </Box>
            )}
            
            <Card
              bg={bgColor}
              boxShadow="xl"
              borderRadius="xl"
              borderWidth="1px"
              borderColor={borderColor}
            >
              <CardHeader pb={0}>
                <VStack align="start" spacing={4} width="100%">
                  <HStack width="100%" justify="space-between">
                    <Badge colorScheme="blue" fontSize="sm" px={3} py={1} borderRadius="full">
                      Website Development
                    </Badge>
                    {isEditing ? (
                      <HStack spacing={2}>
                        <Button
                          onClick={() => setIsEditing(false)}
                          variant="outline"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          isLoading={updateLoading}
                          colorScheme="blue"
                        >
                          Save Changes
                        </Button>
                      </HStack>
                    ) : (
                      <Button
                        leftIcon={<EditIcon />}
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </HStack>
                  <Heading size="lg">Website Service Details</Heading>
                </VStack>
              </CardHeader>

              <CardBody>
                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8}>
                  <GridItem>
                    <VStack align="start" spacing={6}>
                      <Box>
                        <HStack spacing={2} mb={2}>
                          <Icon as={FiUser} color="blue.500" />
                          <Text fontWeight="medium" color="gray.500">Client</Text>
                        </HStack>
                        <Text fontSize="lg" fontWeight="semibold">
                          {`${service.client.fName} ${service.client.lName}`}
                        </Text>
                      </Box>

                      <Box width="100%">
                        <HStack spacing={2} mb={2}>
                          <Icon as={FiGlobe} color="blue.500" />
                          <Text fontWeight="medium" color="gray.500">Website Name</Text>
                        </HStack>
                        {isEditing ? (
                          <Input
                            name="websiteName"
                            value={formData.websiteName}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <Text fontSize="lg" fontWeight="semibold">{service.websiteName}</Text>
                        )}
                      </Box>

                      <Box width="100%">
                        <HStack spacing={2} mb={2}>
                          <Icon as={FiCalendar} color="blue.500" />
                          <Text fontWeight="medium" color="gray.500">Start Date</Text>
                        </HStack>
                        {isEditing ? (
                          <Input
                            type="date"
                            name="serviceStartDate"
                            value={formData.serviceStartDate}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <Text fontSize="lg" fontWeight="semibold">
                            {new Date(service.serviceStartDate).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </Text>
                        )}
                      </Box>

                      <Box width="100%">
                        <HStack spacing={2} mb={2}>
                          <Icon as={FiFileText} color="blue.500" />
                          <Text fontWeight="medium" color="gray.500">Job Summary</Text>
                        </HStack>
                        {isEditing ? (
                          <Textarea
                            name="jobSummary"
                            value={formData.jobSummary}
                            onChange={handleInputChange}
                            rows={4}
                            resize="vertical"
                          />
                        ) : (
                          <Text fontSize="lg">
                            {service.jobSummary || formData.jobSummary || 'No summary provided'}
                          </Text>
                        )}
                      </Box>
                    </VStack>
                  </GridItem>

                  <GridItem>
                    <Box
                      bg={timelineBg}
                      p={4}
                      borderRadius="lg"
                    >
                      <VStack align="start" spacing={4}>
                        <Heading size="sm" color={timelineHeadingColor}>
                          Project Timeline
                        </Heading>
                        
                        <Progress 
                          value={37.5}
                          size="lg"
                          colorScheme="blue"
                          borderRadius="full"
                          mb={4}
                          hasStripe
                          isAnimated
                        />

                        <Wrap spacing={4} justify="space-between">
                          {WEBSITE_STAGES.map((stage, index) => (
                            <WrapItem key={stage.id}>
                              <VStack 
                                spacing={2} 
                                align="center" 
                                maxW="100px"
                                bg={index <= 2 ? 'blue.50' : 'transparent'}
                                p={2}
                                borderRadius="md"
                                transition="all 0.2s"
                                _hover={{ transform: 'translateY(-2px)' }}
                              >
                                <Badge 
                                  colorScheme={index <= 2 ? "blue" : "gray"}
                                  p={2} 
                                  borderRadius="full"
                                  fontSize="sm"
                                >
                                  {index + 1}
                                </Badge>
                                <Text 
                                  fontSize="sm" 
                                  textAlign="center" 
                                  fontWeight={index === 2 ? "bold" : "normal"}
                                  color={index <= 2 ? 'blue.600' : 'gray.500'}
                                >
                                  {stage.label}
                                </Text>
                                <Text
                                  fontSize="xs"
                                  textAlign="center"
                                  color="gray.500"
                                  display={{ base: 'none', md: 'block' }}
                                >
                                  {stage.description}
                                </Text>
                              </VStack>
                            </WrapItem>
                          ))}
                        </Wrap>
                      </VStack>
                    </Box>
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Container>
        </Box>
        <FooterWithFourColumns />
      </>
    );
  };

  if (loading) return <div className="p-4">Loading...</div>;
  if (error) return <div className="p-4 text-red-500">Error: {error.message}</div>;
  if (!data?.service) return <div className="p-4">Service not found</div>;

  return data.service.type === "HDWALLET" 
    ? <HDWalletServiceComponent service={data.service} />
    : renderWebsiteService(data.service);
} 