import React from "react";
import { ChakraProvider, theme, Spinner, Center } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo/client";
import Home from "./pages/Home";

import NewClientForm from "./pages/NewClientForm";
import ClientsList from "./pages/ClientsList";
import ClientDetails from "./pages/ClientDetails";
import WebsiteDetails from "./pages/WebsiteDetails";

import ServicesList from './pages/ServicesList';
import HardwareWallets from "./pages/HardwareWallets";
import CustomWebsiteDevelopment from './pages/CustomWebsiteDevelopment';
import ServiceDetails from './pages/ServiceDetails';
import SendTestEmail from './pages/test/SendTestEmail';
import AdminDashboard from "./pages/AdminDashboard";
import HDWalletServicePage from './pages/HDWalletServicePage';
import WebsiteServicePage from './pages/WebsiteServicePage';
import Bills from './pages/Bills';
import NewWebsiteService from './pages/NewWebsiteService';
import NewHDWalletService from './pages/NewHDWalletService';
import NewBill from './pages/NewBill';
import BillDetails from "./pages/BillDetails";
import TestUploadFile from './pages/test/TestUploadFile';
import TestUploadEncryptedFile from './pages/test/TestUploadEncryptedFile';
import ViewFile from './pages/ViewFile';
import TestUploadUnencryptedFile from './pages/test/TestUploadUnencryptedFile';
import TestAuth from './pages/test/TestAuth';
import NewProductForm from './pages/NewProductForm';
import ProductView from './pages/ProductView';
import AllProducts from './pages/AllProducts';
import TestClientCart from './pages/test/TestClientCart';
import Cart from './pages/Cart';
import OrderDetails from './pages/OrderDetails';
import AboutTom from './pages/AboutTom';
import Blog from './pages/Blog';
import NewProject from './pages/projects/new';
import ProjectPage from './pages/projects/ProjectPage';
import ProjectsList from './pages/projects/ProjectsList';
import TestUploadFileToPinata from './pages/test/TestUploadFileToPinata';
import HundredMembers from './pages/100Members';
import EcosystemAgreement from './pages/EcosystemAgreement';
import AboutUs from "./pages/AboutUs";
import Welcome from './pages/Welcome';



const AppContent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/newclient" element={<NewClientForm />} />
      <Route path="/clients" element={<ClientsList />} />
      <Route path="/client/:id" element={<ClientDetails />} />

      <Route path="/website/:id" element={<WebsiteDetails />} /> // not sure if I need this
      <Route path="/services" element={<ServicesList />} /> 
      <Route path="/service/:id" element={<ServiceDetails />} /> // not sure if I need this

      <Route path="/hdwalletservice/:id" element={<HDWalletServicePage />} /> // hd wallet service page
      <Route path="/websiteservice/:id" element={<WebsiteServicePage />} /> // website service page
      <Route path="/hardware-wallets" element={<HardwareWallets />} />  // sales page

      <Route path="/custom-website-development" element={<CustomWebsiteDevelopment />} /> // sales page

      <Route path="/test/sendemail" element={<SendTestEmail />} /> // admin page to send test emails

      <Route path="/admin" element={<AdminDashboard />} /> // admin dashboard
      <Route path="/bills" element={<Bills />} /> // admin page to view bills

      <Route path="/service/website/new" element={<NewWebsiteService />} /> // new website service
      <Route path="/service/hdwallet/new" element={<NewHDWalletService />} /> // new hd wallet service

      <Route path="/bills/new" element={<NewBill />} />
      <Route path="/bill/:id" element={<BillDetails />} />

      <Route path="/test/upload" element={<TestUploadFile />} />
      <Route path="/test/upload-encrypted" element={<TestUploadEncryptedFile />} />
      <Route path="/test/upload-unencrypted" element={<TestUploadUnencryptedFile />} />
      <Route path="/file/:hash" element={<ViewFile />} />
      <Route path="/test/auth" element={<TestAuth />} />
      <Route path="/products/new" element={<NewProductForm />} />
      <Route path="/products/:id" element={<ProductView />} />
      <Route path="/products" element={<AllProducts />} />
      <Route path="/test/cart" element={<TestClientCart />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/order/:id" element={<OrderDetails />} />
      <Route path="/about/tom" element={<AboutTom />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/projects/new" element={<NewProject />} />
      <Route path="/project/:id" element={<ProjectPage />} />
      <Route path="/projects" element={<ProjectsList />} />
      <Route path="/test/upload-pinata" element={<TestUploadFileToPinata />} />
      <Route path="/100members" element={<HundredMembers />} />
      <Route path="/ecosystem-agreement" element={<EcosystemAgreement />} />

      <Route path="/about" element={<AboutUs />} />
      <Route path="/welcome" element={<Welcome />} />
    </Routes>
  );
};

export const App = () => (
  <Router>
    <ApolloProvider client={apolloClient}>
      <ChakraProvider theme={theme}>
        <AppContent />
      </ChakraProvider>
    </ApolloProvider>
  </Router>
);
