import React, { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Card,
  CardBody,
  Text,
  VStack,
  Button,
  Input,
  useToast,
  HStack,
  Icon,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Skeleton,
} from "@chakra-ui/react";
import {
  FiGlobe,
  FiServer,
  FiUsers,
  FiHardDrive,
  FiCode,
  FiMail,
  FiDollarSign,
  FiUserPlus,
  FiLock,
  FiPackage,
  FiList,
  FiShoppingCart,
} from "react-icons/fi";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { gql, useQuery } from "@apollo/client";
import { motion } from "framer-motion";

interface NavItem {
  path: string;
  title: string;
  description: string;
  icon: React.ComponentType;
}

interface NavCardProps {
  item: NavItem;
}

interface ServiceType {
  __typename: 'WebsiteService' | 'HDWalletService';
  status: 'ACTIVE' | 'PENDING' | 'COMPLETED';
}

const navigationGroups = {
  primary: {
    title: "Primary Management",
    items: [
      {
        path: "/projects",
        title: "Projects List",
        description: "View all projects",
        icon: FiCode,
      },
      {
        path: "/projects/new",
        title: "New Project",
        description: "Create a new project",
        icon: FiCode,
      },
      {
        path: "/services",
        title: "Services List",
        description: "View all available services",
        icon: FiServer,
      },
      {
        path: "/bills",
        title: "Bills",
        description: "View and manage bills",
        icon: FiDollarSign,
      },
      {
        path: "/bills/new",
        title: "Create New Bill",
        description: "Create a new bill for a service",
        icon: FiDollarSign,
      },
    ],
  },
  clients: {
    title: "Client Management",
    items: [
      {
        path: "/clients",
        title: "View All Clients",
        description: "Manage and view all client information",
        icon: FiUsers,
      },
      {
        path: "/newclient",
        title: "New Client",
        description: "Create a new client profile",
        icon: FiUserPlus,
      },
    ],
  },
  services: {
    title: "New Services",
    items: [
      {
        path: "/service/website/new",
        title: "New Website Service",
        description: "Create a new website service",
        icon: FiGlobe,
      },
      {
        path: "/service/hdwallet/new",
        title: "New HD Wallet Service",
        description: "Create a new HD wallet service",
        icon: FiHardDrive,
      },
    ],
  },
  specialPages: {
    title: "Special Pages",
    items: [
      {
        path: "/hardware-wallets",
        title: "Hardware Wallets",
        description: "Hardware wallet information page",
        icon: FiHardDrive,
      },
      {
        path: "/custom-website-development",
        title: "Custom Development",
        description: "Custom website development services",
        icon: FiCode,
      },
      {
        path: "/test/sendemail",
        title: "Email Testing",
        description: "Test email functionality",
        icon: FiMail,
      },
      {
        path: "/test/upload-encrypted",
        title: "Encrypted Upload Test",
        description: "Test encrypted file uploads",
        icon: FiServer,
      },
      {
        path: "/test/upload-unencrypted",
        title: "Unencrypted Upload Test",
        description: "Test unencrypted file uploads",
        icon: FiServer,
      },
      {
        path: "/test/auth",
        title: "Auth Testing",
        description: "Test two-word authentication system",
        icon: FiLock,
      },
      {
        path: "/test/cart",
        title: "Cart Testing",
        description: "Test client cart functionality",
        icon: FiShoppingCart,
      },
      {
        path: "/test/upload-pinata",
        title: "Pinata Upload Test",
        description: "Test file uploads to Pinata",
        icon: FiServer,
      },
    ],
  },
  products: {
    title: "Products",
    items: [
      {
        path: "/products/new",
        title: "New Product",
        description: "Create a new product listing",
        icon: FiPackage,
      },
      {
        path: "/products",
        title: "All Products",
        description: "View and manage all products",
        icon: FiList,
      },
    ],
  },
};

const statsData = [
  {
    label: "Total Websites",
    value: 120,
    helpText: "Active website services",
    icon: FiGlobe,
  },
  {
    label: "Total HD Wallets",
    value: 75,
    helpText: "Active HD Wallet services",
    icon: FiHardDrive,
  },
  {
    label: "Total Clients",
    value: 50,
    helpText: "Registered clients",
    icon: FiUsers,
  },
  {
    label: "Total Services",
    value: 200,
    helpText: "Overall services provided",
    icon: FiServer,
  },
];

// const GET_DASHBOARD_STATS = gql`
//   query GetDashboardStats {
//     services {
//       ... on WebsiteService {
//         __typename
//         id
//         status
//       }
//       ... on HDWalletService {
//         __typename
//         id
//         status
//       }
//     }
//     clients {
//       id
//     }
//   }
// `;

const NavCard: React.FC<NavCardProps> = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Card
      as="button"
      onClick={() => navigate(item.path)}
      cursor="pointer"
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: "lg",
      }}
      transition="all 0.2s"
    >
      <CardBody>
        <VStack align="start" spacing={2}>
          <Icon as={item.icon} boxSize={6} color="blue.500" />
          <Heading size="sm">{item.title}</Heading>
          <Text fontSize="sm" color="gray.600">
            {item.description}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

const StatCard: React.FC<{
  label: string;
  mainValue: number;
  subValues: { label: string; value: number; link?: string; color?: string }[];
  helpText: string;
  icon: React.ComponentType;
  animate?: boolean;
  mainLink?: string;
  mainColor?: string;
}> = ({ label, mainValue, subValues, helpText, icon, animate = false, mainLink, mainColor = "blue.500" }) => {
  const total = mainValue + subValues.reduce((sum, curr) => sum + curr.value, 0);

  return (
    <Stat
      as={motion.div as any}
      initial={animate ? { scale: 0.9, opacity: 0 } : undefined}
      animate={animate ? { scale: 1, opacity: 1 } : undefined}
      transition="all 0.2s"
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={'gray.200'}
      rounded={'lg'}
    >
      <StatLabel fontWeight={'medium'} isTruncated>
        {label}
      </StatLabel>
      <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
        <RouterLink 
          to={mainLink || '#'} 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ color: `var(--chakra-colors-${mainColor})` }}
        >
          {mainValue}
        </RouterLink>
        {subValues.map((subValue, index) => (
          <Text key={index} as="span" fontSize="sm" ml={2}>
            <Text as="span" color={subValue.color || 'gray.500'}>
              {subValue.label}
            </Text>
            :{' '}
            {subValue.link ? (
              <RouterLink 
                to={subValue.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: `var(--chakra-colors-${subValue.color || 'gray.500'})` }}
              >
                {subValue.value}
              </RouterLink>
            ) : (
              <Text as="span" color={subValue.color || 'gray.500'}>
                {subValue.value}
              </Text>
            )}
          </Text>
        ))}
      </StatNumber>
      <HStack justify="space-between">
        <StatHelpText>
          <HStack>
            <Icon as={icon} boxSize={4} />
            <Text>{helpText}</Text>
          </HStack>
        </StatHelpText>
        <StatHelpText>
          <Text fontWeight="bold">
            Total: {total}
          </Text>
        </StatHelpText>
      </HStack>
    </Stat>
  );
};

const AdminDashboard = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAuthorized(storedPassword === "123456");
  }, []);

  const handleLogin = () => {
    if (password === "123456") {
      localStorage.setItem("admin_password", password);
      setIsAuthorized(true);
      toast({
        title: "Welcome Admin!",
        description: "Successfully logged in to the dashboard",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Access Denied",
        description: "Incorrect password",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // const { loading: statsLoading, error: statsError, data: statsData } = useQuery(GET_DASHBOARD_STATS);

  // const calculateStats = () => {
  //   if (!statsData) return null;

  //   const websiteServices = statsData.services.filter(
  //     (service: ServiceType) => service.__typename === 'WebsiteService'
  //   );

  //   return [
  //     {
  //       label: "Websites",
  //       mainValue: websiteServices.filter((s: ServiceType) => s.status === 'ACTIVE').length,
  //       mainLink: "/websites?status=ACTIVE",
  //       mainColor: "orange.500",
  //       subValues: [
  //         { 
  //           label: "Pending", 
  //           value: websiteServices.filter((s: ServiceType) => s.status === 'PENDING').length,
  //           link: "/websites?status=PENDING",
  //           color: "red.500"
  //         },
  //         { 
  //           label: "Completed", 
  //           value: websiteServices.filter((s: ServiceType) => s.status === 'COMPLETED').length,
  //           link: "/websites?status=COMPLETED",
  //           color: "green.500"
  //         }
  //       ],
  //       helpText: "Website services status",
  //       icon: FiGlobe,
  //     },
  //     {
  //       label: "HD Wallets",
  //       mainValue: statsData.services.filter(
  //         (service: ServiceType) => service.__typename === 'HDWalletService'
  //       ).filter((s: ServiceType) => s.status === 'ACTIVE').length,
  //       mainLink: "/hdwalletservices?status=ACTIVE",
  //       mainColor: "orange.500",
  //       subValues: [
  //         { 
  //           label: "Pending", 
  //           value: statsData.services.filter(
  //             (service: ServiceType) => service.__typename === 'HDWalletService'
  //           ).filter((s: ServiceType) => s.status === 'PENDING').length,
  //           link: "/hdwalletservices?status=PENDING",
  //           color: "red.500"
  //         },
  //         { 
  //           label: "Completed", 
  //           value: statsData.services.filter(
  //             (service: ServiceType) => service.__typename === 'HDWalletService'
  //           ).filter((s: ServiceType) => s.status === 'COMPLETED').length,
  //           link: "/hdwalletservices?status=COMPLETED",
  //           color: "green.500"
  //         }
  //       ],
  //       helpText: "HD Wallet services status",
  //       icon: FiHardDrive,
  //     },
  //     {
  //       label: "Clients",
  //       mainValue: statsData.clients.length,
  //       subValues: [],
  //       helpText: "Total registered clients",
  //       icon: FiUsers,
  //     },
  //     {
  //       label: "All Services",
  //       mainValue: statsData.services.filter((s: ServiceType) => s.status === 'ACTIVE').length,
  //       mainLink: "/services?status=ACTIVE",
  //       mainColor: "orange.500",
  //       subValues: [
  //         { 
  //           label: "Pending", 
  //           value: statsData.services.filter((s: ServiceType) => s.status === 'PENDING').length,
  //           link: "/services?status=PENDING",
  //           color: "red.500"
  //         },
  //         { 
  //           label: "Completed", 
  //           value: statsData.services.filter((s: ServiceType) => s.status === 'COMPLETED').length,
  //           link: "/services?status=COMPLETED",
  //           color: "green.500"
  //         }
  //       ],
  //       helpText: "Overall services status",
  //       icon: FiServer,
  //     },
  //   ];
  // };

  // const renderBusinessStats = () => {
  //   if (statsLoading) {
  //     return (
  //       <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
  //         {[...Array(4)].map((_, i) => (
  //           <Skeleton key={i} height="120px" rounded="lg" />
  //         ))}
  //       </SimpleGrid>
  //     );
  //   }

  //   if (statsError) {
  //     return (
  //       <Text color="red.500">
  //         Error loading statistics: {statsError.message}
  //       </Text>
  //     );
  //   }

  //   const stats = calculateStats();
  //   if (!stats) return null;

  //   return (
  //     <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
  //       {stats.map((stat, index) => (
  //         <StatCard 
  //           key={stat.label} 
  //           {...stat} 
  //           animate={true}
  //         />
  //       ))}
  //     </SimpleGrid>
  //   );
  // };

  if (!isAuthorized) {
    return (
      <>
        <NavbarWithCallToAction />
        <Box minH="100vh" bg="gray.50" py={12}>
          <Container maxW="md">
            <VStack spacing={8}>
              <Box bg="white" p={8} rounded="xl" shadow="lg" w="full">
                <VStack spacing={4}>
                  <Heading size="lg">Admin Access Required</Heading>
                  <Input
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    colorScheme="blue"
                    width="full"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </VStack>
              </Box>
            </VStack>
          </Container>
        </Box>
        <FooterWithFourColumns />
      </>
    );
  }

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        {/* Business Statistics Section */}
        {/* <Box mb={8}>
          <Heading size="lg" mb={4}>
            Business Statistics
          </Heading>
          {renderBusinessStats()}
        </Box> */}

        {/* Navigation Sections */}
        <VStack spacing={8} align="stretch">
          {Object.entries(navigationGroups).map(([key, group]) => (
            <Box key={key}>
              <Heading size="md" mb={4}>
                {group.title}
              </Heading>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                {group.items.map((item) => (
                  <NavCard key={item.path} item={item} />
                ))}
              </SimpleGrid>
              <Divider mt={8} />
            </Box>
          ))}
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
};

export default AdminDashboard; 