import React from 'react';
import { Box, Progress, Text, Button } from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';

const UPDATE_SERVICE_PROGRESS = gql`
  mutation UpdateServiceProgress($id: ID!, $input: UpdateServiceProgressInput!) {
    updateServiceProgress(id: $id, input: $input) {
      id
      progress
      type
      status
    }
  }
`;

interface ServiceProgressBarProps {
  serviceId: string;
  currentProgress: number;
  onProgressUpdate?: (newProgress: number) => void;
  isAdmin?: boolean;
}

export const ServiceProgressBar: React.FC<ServiceProgressBarProps> = ({
  serviceId,
  currentProgress,
  onProgressUpdate,
  isAdmin = false
}) => {
  const [updateProgress, { loading }] = useMutation(UPDATE_SERVICE_PROGRESS, {
    onCompleted: (data) => {
      onProgressUpdate?.(data.updateServiceProgress.progress);
    }
  });

  const handleProgressUpdate = async (newProgress: number) => {
    try {
      await updateProgress({
        variables: {
          id: serviceId,
          input: { progress: newProgress }
        }
      });
    } catch (error) {
      console.error('Error updating progress:', error);
    }
  };

  return (
    <Box>
      <Text mb={2}>Overall Progress: {currentProgress}%</Text>
      <Progress 
        value={currentProgress} 
        size="lg" 
        colorScheme="blue" 
        mb={4}
        borderRadius="md"
      />
      {isAdmin && (
        <Box display="flex" gap={2}>
          <Button
            size="sm"
            isLoading={loading}
            onClick={() => handleProgressUpdate(Math.max(0, currentProgress - 10))}
            isDisabled={currentProgress <= 0}
          >
            -10%
          </Button>
          <Button
            size="sm"
            isLoading={loading}
            onClick={() => handleProgressUpdate(Math.min(100, currentProgress + 10))}
            isDisabled={currentProgress >= 100}
          >
            +10%
          </Button>
        </Box>
      )}
    </Box>
  );
}; 