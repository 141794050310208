import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    Select,
    Stack,
    Container,
    useToast,
    Input,
    Textarea,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";

// Query to get all clients for the dropdown
const GET_CLIENTS = gql`
  query GetClients {
    clients {
      id
      fName
      lName
    }
  }
`;

// Mutation to create a new HD wallet service
const CREATE_HDWALLET_SERVICE = gql`
  mutation CreateHDWalletService($input: AddServiceInput!, $clientId: ID!) {
    addService(input: $input, clientId: $clientId) {
      id
      type
      hdWalletService {
        id
        startDate
        walletType
        currentStep
        progress {
          stepId
          step
          status
          completedAt
          notes
        }
      }
      serviceStartDate
      status
      createdAt
      updatedAt
    }
  }
`;

interface FormData {
    clientId: string;
    serviceStartDate: string;
    jobSummary: string;
}

const initialFormData: FormData = {
    clientId: "",
    serviceStartDate: new Date().toISOString().split('T')[0],
    jobSummary: "",
};

const NewHDWalletService = () => {
    const [formData, setFormData] = useState<FormData>(initialFormData);
    const navigate = useNavigate();
    const toast = useToast();

    const { data: clientsData, loading: clientsLoading } = useQuery(GET_CLIENTS);

    const [addHDWalletService, { loading: createLoading }] = useMutation(CREATE_HDWALLET_SERVICE, {
        refetchQueries: ['GetServices'],
        onCompleted: (data) => {
            toast({
                title: "HD Wallet Service Created",
                description: `New HD wallet service has been created successfully`,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            navigate("/services");
        },
        onError: (error) => {
            toast({
                title: "Error creating service",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!formData.clientId) {
            toast({
                title: "Error",
                description: "Please select a client",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const serviceInput = {
            type: "HDWALLET" as const,
            serviceStartDate: new Date(formData.serviceStartDate).toISOString(),
            status: "PENDING" as const,
            jobSummary: formData.jobSummary || undefined
        };

        try {
            await addHDWalletService({
                variables: {
                    input: serviceInput,
                    clientId: formData.clientId,
                },
            });
        } catch (error) {
            console.error("Error creating HD wallet service:", error);
        }
    };

    return (
        <>
            <NavbarWithCallToAction />
            <Container maxW="container.md" py={8}>

                <Card>
                    <CardHeader>
                        <Heading size="lg">New HD Wallet Service</Heading>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Client</FormLabel>
                                    <Stack direction="row" spacing={4}>
                                        <Select
                                            name="clientId"
                                            value={formData.clientId}
                                            onChange={handleChange}
                                            placeholder="Select client"
                                            isDisabled={clientsLoading}
                                        >
                                            {clientsData?.clients?.map((client: any) => (
                                                <option key={client.id} value={client.id}>
                                                    {client.fName} {client.lName}
                                                </option>
                                            ))}
                                        </Select>
                                        <Button
                                            colorScheme="green"
                                            onClick={() => navigate('/newclient')}
                                        >
                                            New Client
                                        </Button>
                                    </Stack>
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Service Start Date</FormLabel>
                                    <Input
                                        type="date"
                                        name="serviceStartDate"
                                        value={formData.serviceStartDate}
                                        onChange={handleChange}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Job Summary</FormLabel>
                                    <Textarea
                                        name="jobSummary"
                                        value={formData.jobSummary}
                                        onChange={handleChange}
                                        placeholder="Enter job summary (optional)"
                                        rows={4}
                                        resize="vertical"
                                    />
                                </FormControl>

                                <Button
                                    type="submit"
                                    colorScheme="blue"
                                    isLoading={createLoading}
                                >
                                    Create HD Wallet Service
                                </Button>
                            </Stack>
                        </form>
                    </CardBody>
                </Card>

            </Container>
            <FooterWithFourColumns />
        </>
    );
};

export default NewHDWalletService;
