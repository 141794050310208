import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
    Box,
    Container,
    Card,
    CardBody,
    CardHeader,
    Heading,
    Text,
    VStack,
    HStack,
    Badge,
    Skeleton,
    useColorModeValue,
    SimpleGrid,
    Avatar,
    Icon,
    Divider,
    Button,
    Input,
    Textarea,
    IconButton,
    useToast,
    Select,
    Image,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Progress,
    Stack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { NavbarWithCallToAction } from '../../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { FiUsers, FiCheckCircle, FiCircle } from 'react-icons/fi';
import { EditIcon, CheckIcon, DeleteIcon } from '@chakra-ui/icons';
import { TaskModal } from '../../components/TaskModal';
import { Document, Page } from 'react-pdf';

const GET_PROJECT = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      id
      projectName
      projectGoal
      progress
      createdAt
      updatedAt
      billingClient {
        id
        fName
        lName
        email
      }
      members {
        id
        fName
        lName
        email
      }
      tasks {
        id
        description
        status
        order
        assignedTo {
          id
          fName
          lName
        }
        media {
          url
          description
          fileType
        }
        billed
      }
      bills {
        id
      }
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $input: ProjectUpdateInput!) {
    updateProject(id: $id, input: $input) {
      id
      projectName
      projectGoal
    }
  }
`;

const UPDATE_TASK_STATUS = gql`
  mutation UpdateTaskStatus($taskId: ID!, $status: TaskStatus!) {
    updateTaskStatus(taskId: $taskId, status: $status) {
      id
      description
      status
      assignedTo {
        id
        email
      }
    }
  }
`;

const DELETE_PHOTO = gql`
  mutation DeleteFile($url: String!) {
    deleteFromPinata(url: $url)
  }
`;

const CREATE_DRAFT_BILL_WITH_TASKS = gql`
  mutation CreateDraftBillWithTasks($projectId: ID!) {
    createDraftBillWithTasks(projectId: $projectId) {
      id
      lineItems {
        id
        description
        amount
 
      }
      service {
        id
        client {
          id
          fName
          lName
        }
      }
   
      isPaid
    }
  }
`;

const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`;

const UPDATE_PROJECT_PROGRESS = gql`
  mutation UpdateProjectProgress($id: ID!, $input: UpdateProjectProgressInput!) {
    updateProjectProgress(id: $id, input: $input) {
      id
      progress
      projectName
      projectGoal
    }
  }
`;

const UPDATE_TASK_ORDER = gql`
  mutation UpdateTaskOrder($taskId: ID!, $order: Int!) {
    updateTaskOrder(taskId: $taskId, order: $order) {
      id
      order
    }
  }
`;

interface Task {
    id: string;
    description: string;
    status: string;
    order: number;
    assignedTo?: {
        id: string;
        fName: string;
        lName: string;
    }[];
    media?: {
        url: string;
        description?: string;
        fileType: string;
    }[];
    billed: boolean;
}

const DELETE_TASK_MEDIA = gql`
  mutation DeleteTaskMedia($taskId: ID!, $photoUrl: String!) {
    deleteTaskMedia(taskId: $taskId, photoUrl: $photoUrl)
  }
`;

interface Member {
    id: string;
    fName: string;
    lName: string;
    email: string;
}

interface Bill {
    id: string;
    amount: number;
    status: string;
    dueDate: string;
}

const isBrowserAdmin = () => {
    return localStorage.getItem('admin_password') === "123456";
};

const ProjectPage = () => {
    const { id } = useParams<{ id: string }>();
    const { loading, error, data, refetch } = useQuery(GET_PROJECT, {
        variables: { id },
        onCompleted: (data) => {
            console.log('Project data loaded:', data);
        },
        onError: (error) => {
            console.error('Project data error:', error);
        }
    });



    // Log state changes
    useEffect(() => {
        console.log('Project ID:', id);
        console.log('Loading state:', loading);
        console.log('Current project data:', data?.project);
        if (error) console.error('Query error:', error);
    }, [id, loading, data, error]);

    const [isEditing, setIsEditing] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [editedGoal, setEditedGoal] = useState('');
    const [updateProject] = useMutation(UPDATE_PROJECT);
    const [isAuthorized, setIsAuthorized] = useState(() => isBrowserAdmin());
    const toast = useToast();
    const [selectedImage, setSelectedImage] = useState<{ url: string; description?: string } | null>(null);
    const [deleteTaskMedia] = useMutation(DELETE_TASK_MEDIA, {
        onCompleted: (data) => console.log('Task media deleted:', data),
        onError: (error) => console.error('Task media deletion error:', error)
    });
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [updateTaskStatus] = useMutation(UPDATE_TASK_STATUS, {
        onCompleted: (data) => console.log('Task status updated:', data),
        onError: (error) => console.error('Task status update error:', error)
    });
    const navigate = useNavigate();
    const [createDraftBill] = useMutation(CREATE_DRAFT_BILL_WITH_TASKS);
    const [deleteTask] = useMutation(DELETE_TASK);
    const [updateProjectProgress] = useMutation(UPDATE_PROJECT_PROGRESS, {
        onCompleted: () => {
            toast({
                title: "Progress Updated",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        onError: (error) => {
            toast({
                title: "Error updating progress",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    });
    const [updateTaskOrder] = useMutation(UPDATE_TASK_ORDER);
    const [tempOrder, setTempOrder] = useState<{ [key: string]: number }>({});

    // Update the values when data is loaded
    useEffect(() => {
        if (data?.project) {
            setEditedName(data.project.projectName);
            setEditedGoal(data.project.projectGoal);
        }
    }, [data?.project]);

    const handleSave = async () => {
        try {
            await updateProject({
                variables: {
                    id,
                    input: {
                        projectName: editedName,
                        projectGoal: editedGoal,
                    },
                },
            });
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    const handleCreateBill = async () => {
        // Add confirmation dialog
        if (window.confirm('Are you sure you want to create a bill for the completed tasks?')) {
            try {
                const { data } = await createDraftBill({
                    variables: { projectId: id }
                });

                toast({
                    title: "Bill Created",
                    description: "Draft bill has been created successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

                // Navigate to the bill details page
                navigate(`/bill/${data.createDraftBillWithTasks.id}`);
            } catch (error) {
                toast({
                    title: "Error",
                    description: error instanceof Error ? error.message : "Failed to create bill",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    // Color mode values
    const bgGradient = useColorModeValue(
        'linear(to-br, gray.50, white)',
        'linear(to-br, gray.900, gray.800)'
    );
    const cardBg = useColorModeValue('white', 'gray.800');

    // Log modal state changes
    useEffect(() => {
        console.log('Add task modal state:', isTaskModalOpen);
        console.log('Selected image:', selectedImage);
    }, [isTaskModalOpen, selectedImage]);

    if (loading) {
        return (
            <>
                <NavbarWithCallToAction />
                <Box bgGradient={bgGradient} minH="100vh" py={8}>
                    <Container maxW="container.xl">
                        <VStack spacing={8}>
                            <Skeleton height="60px" width="full" />
                            <Skeleton height="200px" width="full" />
                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} width="full">
                                <Skeleton height="300px" />
                                <Skeleton height="300px" />
                            </SimpleGrid>
                        </VStack>
                    </Container>
                </Box>
                <FooterWithFourColumns />
            </>
        );
    }

    if (error) {
        console.error('GraphQL Error:', error);
        return <Text>Error: {error.message}</Text>;
    }

    if (!data?.project) {
        return <Text>Project not found</Text>;
    }

    const { project } = data;

    return (
        <>
            <NavbarWithCallToAction />
            <Box bgGradient={bgGradient} minH="100vh" py={8}>
                <Container maxW="container.xl">
                    <Badge
                        mb={4}
                        colorScheme={isAuthorized ? "green" : "blue"}
                        p={2}
                        borderRadius="md"
                        fontSize="sm"
                    >
                        {isAuthorized ? "Admin Access" : "Viewer Access"}
                    </Badge>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <VStack spacing={8} align="stretch">
                            {/* Project Overview Card */}
                            <Card
                                bg={cardBg}
                                borderRadius="2xl"
                                boxShadow="xl"
                                border="1px solid"
                                borderColor="gray.100"
                                overflow="hidden"
                            >
                                <CardHeader>
                                    <VStack align="start" spacing={4}>
                                        <HStack justify="space-between" width="full">
                                            {isEditing ? (
                                                <Input
                                                    value={editedName}
                                                    onChange={(e) => setEditedName(e.target.value)}
                                                    size="lg"
                                                    variant="unstyled"
                                                    fontWeight="bold"
                                                    fontSize={{ base: "xl", md: "2xl" }}
                                                    width="full"
                                                />
                                            ) : (
                                                <Heading
                                                    size={{ base: "md", md: "lg" }}
                                                    wordBreak="break-word"
                                                    pr={{ base: 2, md: 4 }}
                                                >
                                                    {project.projectName}
                                                </Heading>
                                            )}
                                            {isAuthorized && (
                                                <IconButton
                                                    aria-label="Edit project"
                                                    icon={isEditing ? <CheckIcon /> : <EditIcon />}
                                                    onClick={isEditing ? handleSave : () => setIsEditing(true)}
                                                    variant="ghost"
                                                    flexShrink={0}
                                                />
                                            )}
                                        </HStack>
                                        <HStack spacing={4} flexWrap="wrap" gap={2}>
                                            <Badge colorScheme="blue" px={2} py={1} borderRadius="full">
                                                Project #{id}
                                            </Badge>
                                            <Text
                                                color="gray.500"
                                                fontSize={{ base: "sm", md: "md" }}
                                                whiteSpace={{ base: "normal", md: "nowrap" }}
                                            >
                                                Created: {new Date(project.createdAt).toLocaleDateString()}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                </CardHeader>
                                <CardBody>
                                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                                        {/* Left Column */}
                                        <VStack align="stretch" spacing={6}>
                                            <Box>
                                                <Text fontWeight="bold" mb={2}>Project Goal</Text>
                                                {isEditing ? (
                                                    <Textarea
                                                        value={editedGoal}
                                                        onChange={(e) => setEditedGoal(e.target.value)}
                                                        size="sm"
                                                    />
                                                ) : (
                                                    <Text>{project.projectGoal}</Text>
                                                )}
                                            </Box>
                                            <Box>
                                                <Text fontWeight="bold" mb={2}>Client</Text>
                                                <HStack>
                                                    <Avatar size="sm" name={`${project.billingClient.fName} ${project.billingClient.lName}`} />
                                                    <VStack align="start" spacing={0}>
                                                        <Text>{project.billingClient.fName} {project.billingClient.lName}</Text>
                                                        <Text fontSize="sm" color="gray.500">{project.billingClient.email}</Text>
                                                    </VStack>
                                                </HStack>
                                            </Box>
                                        </VStack>

                                        {/* Right Column - Stats */}
                                        <SimpleGrid columns={2} spacing={4}>
                                            {isAuthorized && (
                                                <Card p={4}>
                                                    <VStack>
                                                        <Icon as={FiUsers} boxSize={6} color="blue.500" />
                                                        <Text fontWeight="bold">{project.members.length}</Text>
                                                        <Text fontSize="sm">Team Members</Text>
                                                    </VStack>
                                                </Card>
                                            )}
                                            <Card p={4}>
                                                <VStack>
                                                    <Icon as={FiCheckCircle} boxSize={6} color="green.500" />
                                                    <Text fontWeight="bold">
                                                        {project.tasks.filter((t: Task) => t.status === 'COMPLETED').length}/{project.tasks.length}
                                                    </Text>
                                                    <Text fontSize="sm">Tasks Complete</Text>
                                                </VStack>
                                            </Card>
                                        </SimpleGrid>


                                    </SimpleGrid>

                                    <Box mt={4} p={4}>
                                        <HStack mb={2} justify="space-between">
                                            <Text fontWeight="semibold">Overall Progress</Text>
                                            {isAuthorized && (
                                                <Select
                                                    size="sm"
                                                    value={project.progress || 0}
                                                    onChange={async (e) => {
                                                        const newProgress = parseInt(e.target.value);
                                                        try {
                                                            await updateProjectProgress({
                                                                variables: {
                                                                    id,
                                                                    input: { progress: newProgress }
                                                                }
                                                            });
                                                        } catch (error) {
                                                            console.error('Error updating progress:', error);
                                                        }
                                                    }}
                                                    width="100px"
                                                >
                                                    {Array.from({ length: 11 }, (_, i) => i * 10).map(value => (
                                                        <option key={value} value={value}>{value}%</option>
                                                    ))}
                                                </Select>
                                            )}
                                        </HStack>
                                        <Progress
                                            value={project.progress || 0}
                                            size="lg"
                                            borderRadius="full"
                                            colorScheme="green"
                                            hasStripe
                                            isAnimated
                                        />
                                        <Text mt={2} fontSize="sm" color="gray.500" textAlign="right">
                                            {project.progress || 0}% Complete
                                        </Text>
                                    </Box>
                                </CardBody>
                            </Card>

                            {/* Tasks Section */}
                            <Card bg={cardBg} borderRadius="2xl" boxShadow="xl">
                                <CardHeader>
                                    <Heading size="md">Tasks</Heading>
                                </CardHeader>
                                <CardBody>
                                    <VStack spacing={4} align="stretch">
                                        {[...project.tasks]
                                            .sort((a, b) => a.order - b.order)
                                            .map((task: Task) => (
                                                <Card
                                                    key={task.id}
                                                    p={{ base: 3, md: 6 }}
                                                    variant="outline"
                                                    borderLeft="4px"
                                                    borderLeftColor={
                                                        task.status === 'COMPLETED' ? 'green.400' :
                                                            task.status === 'IN_PROGRESS' ? 'yellow.400' : 'gray.300'
                                                    }
                                                    bg={
                                                        task.status === 'COMPLETED' ? 'green.50' :
                                                            task.status === 'IN_PROGRESS' ? 'yellow.50' : 'white'
                                                    }
                                                >
                                                    <VStack align="stretch" spacing={4}>
                                                        <Stack
                                                            direction={{ base: 'column', md: 'row' }}
                                                            justify="space-between"
                                                            align={{ base: 'stretch', md: 'start' }}
                                                            spacing={{ base: 4, md: 3 }}
                                                        >
                                                            <VStack align="start" spacing={3} flex={1}>
                                                                <HStack spacing={3} width="full" flexWrap="wrap">
                                                                    {isAuthorized && (
                                                                        <HStack>
                                                                            <Input
                                                                                value={tempOrder[task.id] !== undefined ? tempOrder[task.id] : task.order}
                                                                                onChange={(e) => {
                                                                                    const newOrder = parseInt(e.target.value);
                                                                                    if (!isNaN(newOrder)) {
                                                                                        setTempOrder(prev => ({
                                                                                            ...prev,
                                                                                            [task.id]: newOrder
                                                                                        }));
                                                                                    }
                                                                                }}
                                                                                size="sm"
                                                                                width="4rem"
                                                                                textAlign="center"
                                                                                p={1}
                                                                                borderRadius="md"
                                                                                bg="blue.50"
                                                                            />
                                                                            <Button
                                                                                size="sm"
                                                                                colorScheme="blue"
                                                                                onClick={async () => {
                                                                                    const newOrder = tempOrder[task.id];
                                                                                    if (newOrder !== undefined) {
                                                                                        try {
                                                                                            await updateTaskOrder({
                                                                                                variables: {
                                                                                                    taskId: task.id,
                                                                                                    order: newOrder
                                                                                                }
                                                                                            });
                                                                                            setTempOrder(prev => {
                                                                                                const updated = { ...prev };
                                                                                                delete updated[task.id];
                                                                                                return updated;
                                                                                            });
                                                                                            refetch();
                                                                                            toast({
                                                                                                title: "Order updated",
                                                                                                status: "success",
                                                                                                duration: 2000,
                                                                                                isClosable: true,
                                                                                            });
                                                                                        } catch (error) {
                                                                                            toast({
                                                                                                title: "Error updating task order",
                                                                                                description: error instanceof Error ? error.message : "Unknown error occurred",
                                                                                                status: "error",
                                                                                                duration: 3000,
                                                                                                isClosable: true,
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                isDisabled={tempOrder[task.id] === undefined}
                                                                            >
                                                                                Update
                                                                            </Button>
                                                                        </HStack>
                                                                    )}
                                                                    <Text
                                                                        fontSize={{ base: 'md', md: 'xl' }}
                                                                        fontWeight="bold"
                                                                        color={
                                                                            task.status === 'COMPLETED' ? 'green.600' :
                                                                                task.status === 'IN_PROGRESS' ? 'yellow.600' : 'gray.700'
                                                                        }
                                                                        wordBreak="break-word"
                                                                        flex="1"
                                                                        minW={{ base: "full", md: "auto" }}
                                                                    >
                                                                        {task.description}
                                                                    </Text>
                                                                </HStack>
                                                                <Stack
                                                                    direction={{ base: 'column', sm: 'row' }}
                                                                    spacing={2}
                                                                    width="full"
                                                                >
                                                                    {isAuthorized ? (
                                                                        <Select
                                                                            size="sm"
                                                                            value={task.status}
                                                                            onChange={async (e) => {
                                                                                try {
                                                                                    await updateTaskStatus({
                                                                                        variables: {
                                                                                            taskId: task.id,
                                                                                            status: e.target.value
                                                                                        }
                                                                                    });
                                                                                    refetch();
                                                                                } catch (error) {
                                                                                    toast({
                                                                                        title: "Error updating status",
                                                                                        description: error instanceof Error ? error.message : "Unknown error",
                                                                                        status: "error",
                                                                                        duration: 3000,
                                                                                    });
                                                                                }
                                                                            }}
                                                                            width={{ base: "full", sm: "120px" }}
                                                                            variant="filled"
                                                                        >
                                                                            <option value="PENDING">Pending</option>
                                                                            <option value="IN_PROGRESS">In Progress</option>
                                                                            <option value="COMPLETED">Completed</option>
                                                                        </Select>
                                                                    ) : (
                                                                        <Badge
                                                                            px={3}
                                                                            py={1}
                                                                            borderRadius="full"
                                                                            colorScheme={
                                                                                task.status === 'COMPLETED' ? 'green' :
                                                                                task.status === 'IN_PROGRESS' ? 'yellow' : 'gray'
                                                                            }
                                                                        >
                                                                            {task.status === 'COMPLETED' ? 'Completed' :
                                                                             task.status === 'IN_PROGRESS' ? 'In Progress' : 'Pending'}
                                                                        </Badge>
                                                                    )}
                                                                    <HStack spacing={2} flexWrap="wrap">
                                                                        {task.billed && (
                                                                            <Badge colorScheme="blue" px={3} py={1} borderRadius="full">
                                                                                Billed
                                                                            </Badge>
                                                                        )}
                                                                        {task.media && task.media.length > 0 && (
                                                                            <Badge colorScheme="purple" px={3} py={1} borderRadius="full">
                                                                                {task.media.length} photos
                                                                            </Badge>
                                                                        )}
                                                                    </HStack>
                                                                </Stack>
                                                            </VStack>
                                                            <HStack
                                                                spacing={3}
                                                                justify={{ base: 'flex-end', md: 'flex-start' }}
                                                                width={{ base: 'full', md: 'auto' }}
                                                            >
                                                                {isAuthorized && (
                                                                    <>
                                                                        <IconButton
                                                                            aria-label="Edit task"
                                                                            icon={<EditIcon />}
                                                                            size="md"
                                                                            colorScheme="blue"
                                                                            variant="ghost"
                                                                            onClick={() => {
                                                                                setSelectedTask(task);
                                                                                setIsTaskModalOpen(true);
                                                                            }}
                                                                        />
                                                                        <IconButton
                                                                            aria-label="Delete task"
                                                                            icon={<DeleteIcon />}
                                                                            size="md"
                                                                            colorScheme="red"
                                                                            variant="ghost"
                                                                            onClick={async () => {
                                                                                if (window.confirm('Are you sure you want to delete this task?')) {
                                                                                    try {
                                                                                        await deleteTask({
                                                                                            variables: { id: task.id },
                                                                                            refetchQueries: [{ query: GET_PROJECT, variables: { id } }]
                                                                                        });

                                                                                        toast({
                                                                                            title: "Task deleted",
                                                                                            status: "success",
                                                                                            duration: 3000,
                                                                                            isClosable: true,
                                                                                        });
                                                                                    } catch (error) {
                                                                                        toast({
                                                                                            title: "Error deleting task",
                                                                                            description: error instanceof Error ? error.message : "Unknown error occurred",
                                                                                            status: "error",
                                                                                            duration: 5000,
                                                                                            isClosable: true,
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                                {task.assignedTo?.[0] && (
                                                                    <Avatar
                                                                        size="md"
                                                                        name={`${task.assignedTo[0].fName} ${task.assignedTo[0].lName}`}
                                                                        title={`${task.assignedTo[0].fName} ${task.assignedTo[0].lName}`}
                                                                    />
                                                                )}
                                                            </HStack>
                                                        </Stack>

                                                        {task.media && task.media.length > 0 && (
                                                            <Box>
                                                                <Text fontWeight="bold" mb={2}>
                                                                    Task Evidence ({task.media.length})
                                                                </Text>
                                                                {(() => {
                                                                    console.log('Task media:', {
                                                                        taskId: task.id,
                                                                        media: task.media.map(m => ({
                                                                            url: m.url,
                                                                            fileType: m.fileType,
                                                                            isPDF: m.fileType === 'application/pdf' || m.url.toLowerCase().includes('pdf'),
                                                                            isVideo: m.fileType === 'video/mp4' || m.url.endsWith('.mp4')
                                                                        }))
                                                                    });
                                                                    return null;
                                                                })()}
                                                                <SimpleGrid
                                                                    columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
                                                                    spacing={{ base: 2, md: 4 }}
                                                                >
                                                                    {task.media.map((media, index) => {
                                                                        console.log('Rendering media item:', {
                                                                            index,
                                                                            url: media.url,
                                                                            fileType: media.fileType,
                                                                            isPDF: media.fileType === 'application/pdf' || media.url.toLowerCase().includes('pdf'),
                                                                            isVideo: media.fileType === 'video/mp4' || media.url.endsWith('.mp4')
                                                                        });

                                                                        return (
                                                                            <Box
                                                                                key={index}
                                                                                position="relative"
                                                                                cursor="pointer"
                                                                                borderRadius="md"
                                                                                overflow="hidden"
                                                                                boxShadow="sm"
                                                                                border="1px solid"
                                                                                borderColor={task.status === 'COMPLETED' ? 'green.200' : 'gray.200'}
                                                                            >
                                                                                {(media.fileType === 'application/pdf' || media.url.toLowerCase().includes('pdf')) ? (
                                                                                    <Box maxH="150px" overflow="hidden" position="relative">
                                                                                        <Document file={media.url}>
                                                                                            <Page pageNumber={1} width={200} />
                                                                                        </Document>
                                                                                        <Button
                                                                                            position="absolute"
                                                                                            bottom={2}
                                                                                            right={2}
                                                                                            size="sm"
                                                                                            onClick={() => window.open(media.url, '_blank')}
                                                                                        >
                                                                                            Download PDF
                                                                                        </Button>
                                                                                    </Box>
                                                                                ) : (media.fileType === 'video/mp4' || media.url.endsWith('.mp4')) ? (
                                                                                    <Box maxH="150px" overflow="hidden">
                                                                                        {(() => {
                                                                                            console.log('Rendering video:', media.url);
                                                                                            return null;
                                                                                        })()}
                                                                                        <video
                                                                                            src={media.url}
                                                                                            controls
                                                                                            style={{ width: '100%', maxHeight: '150px', objectFit: 'cover' }}
                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                        >
                                                                                            Your browser does not support the video tag.
                                                                                        </video>
                                                                                    </Box>
                                                                                ) : (
                                                                                    <>
                                                                                        {(() => {
                                                                                            console.log('Rendering image:', media.url);
                                                                                            return null;
                                                                                        })()}
                                                                                        <Image
                                                                                            src={media.url}
                                                                                            alt={media.description || `Evidence ${index + 1}`}
                                                                                            height="150px"
                                                                                            width="100%"
                                                                                            objectFit="cover"
                                                                                            onClick={() => setSelectedImage(media)}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                                <Badge
                                                                                    position="absolute"
                                                                                    top={2}
                                                                                    left={2}
                                                                                    colorScheme={task.status === 'COMPLETED' ? 'green' : 'gray'}
                                                                                    fontSize="xs"
                                                                                >
                                                                                    {media.description ? 'Proof of Work' : 'Screenshot'}
                                                                                </Badge>
                                                                                <IconButton
                                                                                    aria-label="Delete evidence"
                                                                                    icon={<DeleteIcon />}
                                                                                    size="sm"
                                                                                    position="absolute"
                                                                                    right={2}
                                                                                    top={2}
                                                                                    zIndex={2}
                                                                                    colorScheme="red"
                                                                                    opacity={0.7}
                                                                                    _hover={{ opacity: 1 }}
                                                                                    onClick={async (e) => {
                                                                                        e.stopPropagation();
                                                                                        try {
                                                                                            await deleteTaskMedia({
                                                                                                variables: {
                                                                                                    taskId: task.id,
                                                                                                    photoUrl: media.url
                                                                                                }
                                                                                            });
                                                                                            toast({
                                                                                                title: "Media deleted",
                                                                                                status: "success",
                                                                                                duration: 3000,
                                                                                                isClosable: true,
                                                                                            });
                                                                                            refetch();
                                                                                        } catch (error) {
                                                                                            toast({
                                                                                                title: "Error deleting photo",
                                                                                                description: error instanceof Error ? error.message : "Unknown error occurred",
                                                                                                status: "error",
                                                                                                duration: 5000,
                                                                                                isClosable: true,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        );
                                                                    })}
                                                                </SimpleGrid>
                                                            </Box>
                                                        )}
                                                    </VStack>
                                                </Card>
                                            ))}
                                        {isAuthorized && (
                                            <Box position="relative" pt={4} mb={30}>
                                                <Button
                                                    colorScheme="blue"
                                                    size="sm"
                                                    position="absolute"
                                                    right={0}
                                                    onClick={() => {
                                                        setSelectedTask(null);
                                                        setIsTaskModalOpen(true);
                                                    }}
                                                >
                                                    Add Task
                                                </Button>
                                            </Box>
                                        )}
                                    </VStack>
                                </CardBody>
                            </Card>

                            {/* Team Members Section */}
                            {isAuthorized && (
                                <Card bg={cardBg} borderRadius="2xl" boxShadow="xl">
                                    <CardHeader>
                                        <HStack justify="space-between">
                                            <Heading size="md">Team Members</Heading>
                                            <Button colorScheme="blue" size="sm">Add Member</Button>
                                        </HStack>
                                    </CardHeader>
                                    <CardBody>
                                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                            {project.members.map((member: Member) => (
                                                <Card key={member.id} p={4} variant="outline">
                                                    <HStack spacing={4}>
                                                        <Avatar name={`${member.fName} ${member.lName}`} />
                                                        <VStack align="start" spacing={0}>
                                                            <Text fontWeight="bold">{member.fName} {member.lName}</Text>
                                                            <Text fontSize="sm" color="gray.500">{member.email}</Text>
                                                        </VStack>
                                                    </HStack>
                                                </Card>
                                            ))}
                                        </SimpleGrid>
                                    </CardBody>
                                </Card>
                            )}

                            {/* Bills Section */}
                            <Card bg={cardBg} borderRadius="2xl" boxShadow="xl">
                                <CardHeader>
                                    <HStack justify="space-between">
                                        <Heading size="md">Bills</Heading>
                                        {isAuthorized && (
                                            <Button
                                                colorScheme="blue"
                                                size="sm"
                                                onClick={handleCreateBill}
                                                isDisabled={!project.tasks.some((task: Task) => !task.billed && task.status === 'COMPLETED')}
                                            >
                                                Create Bill
                                            </Button>
                                        )}
                                    </HStack>
                                </CardHeader>
                                <CardBody>
                                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                        {project.bills.map((bill: Bill) => (
                                            <Card key={bill.id} p={4} variant="outline">
                                                <VStack align="start" spacing={2}>
                                                    <HStack justify="space-between" width="full">
                                                        <Text fontWeight="bold">${bill.amount}</Text>
                                                        <Badge colorScheme={
                                                            bill.status === 'PAID' ? 'green' :
                                                                bill.status === 'PENDING' ? 'yellow' : 'red'
                                                        }>
                                                            {bill.status}
                                                        </Badge>
                                                    </HStack>
                                                    <Text fontSize="sm" color="gray.500">
                                                        Due: {new Date(bill.dueDate).toLocaleDateString()}
                                                    </Text>
                                                </VStack>
                                            </Card>
                                        ))}
                                    </SimpleGrid>
                                </CardBody>
                            </Card>
                        </VStack>
                    </motion.div>
                </Container>
            </Box>
            <FooterWithFourColumns />
            {id && <TaskModal
                isOpen={isTaskModalOpen}
                onClose={() => {
                    setIsTaskModalOpen(false);
                    setSelectedTask(null);
                }}
                projectId={id}
                existingTask={selectedTask ? {
                    id: selectedTask.id,
                    description: selectedTask.description,
                    status: selectedTask.status,
                    assignedTo: selectedTask.assignedTo,
                    media: selectedTask.media,
                    billed: selectedTask.billed
                } : undefined}
                onTaskUpdated={() => {
                    refetch();
                }}
            />}
            <Modal isOpen={!!selectedImage} onClose={() => setSelectedImage(null)} size="6xl">
                <ModalOverlay onClick={() => setSelectedImage(null)} />
                <ModalContent>
                    <ModalCloseButton zIndex="popover" />
                    <ModalBody p={0}>
                        <Image
                            src={selectedImage?.url}
                            alt={selectedImage?.description || 'Task evidence'}
                            width="100%"
                            height="auto"
                            maxH="90vh"
                            objectFit="contain"
                            cursor="pointer"
                            onClick={() => setSelectedImage(null)}
                        />
                        {selectedImage?.description && (
                            <Text p={4} textAlign="center">
                                {selectedImage.description}
                            </Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ProjectPage;