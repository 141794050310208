import React, { useState, useEffect } from 'react';
import { Button, Drawer, DrawerBody, DrawerContent, DrawerProps, Spacer, Stack } from '@chakra-ui/react';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export const MobileDrawer = (props: Omit<DrawerProps, 'children'>) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAuthorized(storedPassword === "123456");
  }, []);

  const handleNavigation = (path: string) => {
    navigate(path);
    props.onClose();
  };

  const handleAuthAction = async () => {
    if (user) {
      await logout();
      navigate('/');
    } else {
      navigate('/login');
    }
    props.onClose();
  };

  return (
    <Drawer placement="top" {...props}>
      <DrawerContent>
        <DrawerBody mt="16">
          <Stack spacing="6" align="stretch">
            {isAuthorized && (
              <>
                <Button onClick={() => handleNavigation('/clients')} size="lg" variant="text" colorScheme="gray">
                  Clients
                </Button>
                
                <Button onClick={() => handleNavigation('/projects')} size="lg" variant="text" colorScheme="gray">
                  Projects
                </Button>
                
                <Button onClick={() => handleNavigation('/hardware-wallets')} size="lg" variant="text" colorScheme="gray">
                  Hardware Wallet Range
                </Button>
                
                <Button onClick={() => handleNavigation('/admin')} size="lg" variant="text" colorScheme="gray">
                  Admin
                </Button>
                
                <Button onClick={() => handleNavigation('/bills')} size="lg" variant="text" colorScheme="gray">
                  Bills
                </Button>
              </>
            )}

            <Button onClick={handleAuthAction} colorScheme='blue'>
              {user ? 'Logout' : 'Start'}
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};