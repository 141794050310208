import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Container,
  Grid,
  Image,
  Text,
  Button,
  Heading,
  Stack,
  VStack,
  HStack,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  IconButton,
  SimpleGrid,
  useToast,
  Switch,
  Tag,
  Wrap,
  WrapItem,
  Badge,
  UnorderedList,
  ListItem,
  Checkbox,
  CheckboxGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TagInput } from '../components/TagInput';
import { useAuth } from '../hooks/useAuth';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import SortableImage from '../components/SortableImage';
import { FiShoppingCart } from 'react-icons/fi';
import { CartPreview } from '../components/CartPreview';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { client } from '../contexts/AuthContext';
import { AuthGuard } from '../components/AuthGuard';

// Define interfaces
interface ProductVariant {
  variantId: string;
  title: string;
  price: number;
  sku?: string;
  stockLevel: number;
}

interface ShippingDetails {
  weight: number;
  dimensions: string;
  availableShippingMethods: string[];
  requiresSpecialHandling: boolean;
}

interface Product {
  id: string;
  name: string;
  description: string;
  price: number;
  type: string;
  pricingModel: string;
  stockLevel: number;
  sku: string;
  isActive: boolean;
  images: string[];
  variants: ProductVariant[];
  categories: string[];
  tags: string[];
  ingredients: string[];
  howToUse?: string;
  benefits?: string;
  isFeatured: boolean;
  isBestSeller: boolean;
  shipping: ShippingDetails;
  shippingInfo?: string;
  availableFrom?: Date;
  availableUntil?: Date;
}

const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      id
      name
      description
      price
      type
      pricingModel
      stockLevel
      sku
      isActive
      images
      variants {
        variantId
        title
        price
        sku
        stockLevel
      }
      categories
      tags
      ingredients
      howToUse
      benefits
      isFeatured
      isBestSeller
      shipping {
        weight
        dimensions
        availableShippingMethods
        requiresSpecialHandling
      }
      shippingInfo
      availableFrom
      availableUntil
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $input: ProductInput!) {
    updateProduct(id: $id, input: $input) {
      id
      name
      description
      price
      type
      pricingModel
      stockLevel
      sku
      isActive
      images
      variants {
        variantId
        title
        price
        sku
        stockLevel
      }
      categories
      tags
      ingredients
      howToUse
      benefits
      isFeatured
      isBestSeller
      shipping {
        weight
        dimensions
        availableShippingMethods
        requiresSpecialHandling
      }
      shippingInfo
      availableFrom
      availableUntil
    }
  }
`;

export enum ShippingMethod {
  STANDARD = "STANDARD",
  EXPRESS = "EXPRESS",
  INTERNATIONAL = "INTERNATIONAL",
  LOCAL_PICKUP = "LOCAL_PICKUP"
}

const UPLOAD_UNENCRYPTED_FILE = gql`
  mutation UploadUnencrypted($file: Upload!) {
    uploadUnencryptedFile(file: $file)
  }
`;

const ADD_TO_CART = gql`
  mutation AddToCart($input: CartItemInput!) {
    addToCart(input: $input) {
      id
      items {
        id
        product {
          id
          name
          price
        }
        quantity
        selectedVariantId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

const GET_ACTIVE_CART = gql`
  query GetActiveCart {
    activeCartByClient {
      id
      items {
        id
        product {
          id
          name
          price
        }
        quantity
        selectedVariantId
      }
    }
  }
`;

const GET_CART = gql`
  query GetCart($cartId: ID!) {
    cart(id: $cartId) {
      id
      items {
        id
        product {
          id
          name
          price
        }
        quantity
        selectedVariantId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      expiresAt
    }
  }
`;

type DraggableImage = {
  id: string;
  url: string;
};

const ProductView: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const toast = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState<Product | null>(null);
  const [mainImage, setMainImage] = useState<string>('');
  const [uploadFile] = useMutation(UPLOAD_UNENCRYPTED_FILE);

  const [draggableImages, setDraggableImages] = useState<DraggableImage[]>([]);

  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState<string | null>(null);
  const [cartId, setCartId] = useState<string | null>(localStorage.getItem('cart_id'));

  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { id },
    skip: !id,
  });

  const [updateProduct, { loading: updateLoading }] = useMutation(UPDATE_PRODUCT);
  const [addToCart, { loading: addingToCart }] = useMutation(ADD_TO_CART, {
    onCompleted: (data) => {
      toast({
        title: "Added to cart",
        description: "Item has been added to your cart",
        status: "success",
        duration: 3000,
      });
      setCartId(data.addToCart.id);
      localStorage.setItem('cart_id', data.addToCart.id);
      // Update Apollo cache with the new cart data
      client.writeQuery({
        query: GET_ACTIVE_CART,
        data: {
          activeCartByClient: data.addToCart
        }
      });
    },
    refetchQueries: [{ query: GET_ACTIVE_CART }], // This will refetch the cart data
    onError: (error) => {
      // toast({
      //   title: "Error adding to cart",
      //   description: error.message,
      //   status: "error",
      //   duration: 5000,
      // });
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (editedProduct?.images) {
      const images = editedProduct.images.map((url, index) => ({
        id: `img-${Date.now()}-${index}`,
        url
      }));
      setDraggableImages(images);
    }
  }, [editedProduct?.images]);

  useEffect(() => {
    if (data?.product) {
      setEditedProduct(data.product);
      setMainImage(data.product.images[0] || '');
    }
  }, [data]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    try {
      const file = e.target.files[0];
      const { data } = await uploadFile({
        variables: { file },
        context: {
          headers: {
            'apollo-require-preflight': 'true',
            'x-apollo-operation-name': 'UploadUnencryptedFile'
          }
        }
      });

      const hash = data.uploadUnencryptedFile;
      const newImageUrl = `https://gateway.lighthouse.storage/ipfs/${hash}`;

      const newImage = {
        id: `img-${Date.now()}`,
        url: newImageUrl
      };

      setDraggableImages(prev => [...prev, newImage]);
      setEditedProduct(prev =>
        prev ? {
          ...prev,
          images: [...prev.images, newImageUrl]
        } : null
      );

      toast({
        title: "Image uploaded successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: "Upload failed",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 5000,
      });
    }
  };
  const handleDeleteImage = (index: number) => {
    setDraggableImages(prev => prev.filter((_, i) => i !== index));
    setEditedProduct(prev =>
      prev ? {
        ...prev,
        images: prev.images.filter((_, i) => i !== index)
      } : null
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const oldIndex = editedProduct!.images.findIndex((url, index) =>
      `${index}-${url}` === active.id
    );
    const newIndex = editedProduct!.images.findIndex((url, index) =>
      `${index}-${url}` === over.id
    );

    setEditedProduct(prev => {
      if (!prev) return null;
      const newImages = arrayMove(prev.images, oldIndex, newIndex);
      return {
        ...prev,
        images: newImages
      };
    });
  };

  const handleSave = async () => {
    if (!editedProduct) return;

    try {
      // Clean up the shipping object by removing __typename
      const cleanShipping = editedProduct.shipping ? {
        weight: editedProduct.shipping.weight,
        dimensions: editedProduct.shipping.dimensions,
        availableShippingMethods: editedProduct.shipping.availableShippingMethods,
        requiresSpecialHandling: editedProduct.shipping.requiresSpecialHandling
      } : undefined;
      const { data } = await updateProduct({
        variables: {
          id,
          input: {
            name: editedProduct.name,
            description: editedProduct.description,
            price: editedProduct.price,
            type: editedProduct.type,
            pricingModel: editedProduct.pricingModel,
            sku: editedProduct.sku,
            stockLevel: editedProduct.stockLevel,
            images: editedProduct.images,
            variants: editedProduct.variants,
            categories: editedProduct.categories,
            tags: editedProduct.tags,
            ingredients: editedProduct.ingredients,
            howToUse: editedProduct.howToUse,
            benefits: editedProduct.benefits,
            isFeatured: editedProduct.isFeatured,
            isBestSeller: editedProduct.isBestSeller,
            shipping: cleanShipping,
            shippingInfo: editedProduct.shippingInfo,
            availableFrom: editedProduct.availableFrom,
            availableUntil: editedProduct.availableUntil
          }
        }
      });

      if (data?.updateProduct) {
        toast({
          title: "Product updated successfully",
          status: "success",
          duration: 3000,
        });
        setIsEditing(false);
      }
    } catch (error) {
      toast({
        title: "Error updating product",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleAddToCart = () => {
    console.log('Adding to cart with variables:', {
      productId: id,
      quantity,
      selectedVariant
    });

    addToCart({
      variables: {
        input: {
          productId: id,
          quantity,
          ...(selectedVariant ? { selectedVariantId: selectedVariant } : {})
        },
      },
    }).then(result => {
      console.log('Add to cart success:', result);
    }).catch(error => {
      console.error('Add to cart error:', error);
      console.error('Error details:', {
        id,
        quantity,
        selectedVariant,
        errorMessage: error.message
      });
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data?.product) return <div>No product found</div>;

  return (
    <Box>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        {isAuthenticated && (
          <HStack justify="flex-end" mb={4}>
            <Button
              leftIcon={isEditing ? <CheckIcon /> : <EditIcon />}
              onClick={isEditing ? handleSave : () => setIsEditing(true)}
              isLoading={updateLoading}
              colorScheme={isEditing ? "green" : "blue"}
            >
              {isEditing ? "Save Changes" : "Edit Product"}
            </Button>
          </HStack>
        )}

        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={10}>
          {/* Left side - Images */}
          <Stack spacing={4}>
            {isEditing ? (
              <VStack spacing={4} width="100%">
                <FormControl>
                  <FormLabel>Product Images</FormLabel>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </FormControl>

                {isEditing && editedProduct?.images && editedProduct.images.length > 0 && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SimpleGrid columns={2} spacing={4} width="100%">
                      <SortableContext
                        items={editedProduct.images.map((url, index) => `${index}-${url}`)}
                        strategy={verticalListSortingStrategy}
                      >
                        {editedProduct.images.map((url, index) => (
                          <SortableImage
                            key={`${index}-${url}`}
                            id={`${index}-${url}`}
                            url={url}
                            index={index}
                            onDelete={() => handleDeleteImage(index)}
                          />
                        ))}
                      </SortableContext>
                    </SimpleGrid>
                  </DndContext>
                )}
              </VStack>
            ) : (
              <Stack spacing={4}>
                {/* Main Image */}
                <Box>
                  <Image
                    src={mainImage}
                    alt={data.product.name}
                    width="100%"
                    height="auto"
                  />
                </Box>

                {/* Thumbnail Gallery */}
                {data.product.images && data.product.images.length > 1 && (
                  <SimpleGrid columns={4} spacing={2}>
                    {data.product.images.map((img: string, index: number) => (
                      <Box
                        key={img}
                        cursor="pointer"
                        borderWidth={mainImage === img ? "2px" : "0"}
                        borderColor="blue.500"
                        onClick={() => setMainImage(img)}
                      >
                        <Image
                          src={img}
                          alt={`Product view ${index + 1}`}
                          width="100%"
                          height="auto"
                          opacity={mainImage === img ? 1 : 0.6}
                          _hover={{ opacity: 1 }}
                          transition="opacity 0.2s"
                        />
                      </Box>
                    ))}
                  </SimpleGrid>
                )}
              </Stack>
            )}
          </Stack>

          {/* Right side - Product Details */}
          <Stack spacing={6}>
            {isEditing ? (
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel>Product Name</FormLabel>
                  <Input
                    value={editedProduct?.name}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, name: e.target.value } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    value={editedProduct?.description}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, description: e.target.value } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Price</FormLabel>
                  <Input
                    type="number"
                    value={editedProduct?.price}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, price: parseFloat(e.target.value) } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Stock Level</FormLabel>
                  <Input
                    type="number"
                    value={editedProduct?.stockLevel}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, stockLevel: parseInt(e.target.value) } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>SKU</FormLabel>
                  <Input
                    value={editedProduct?.sku}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, sku: e.target.value } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Featured Product</FormLabel>
                  <Switch
                    isChecked={editedProduct?.isFeatured}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, isFeatured: e.target.checked } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Best Seller</FormLabel>
                  <Switch
                    isChecked={editedProduct?.isBestSeller}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, isBestSeller: e.target.checked } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Categories</FormLabel>
                  <TagInput
                    tags={editedProduct?.categories || []}
                    onChange={(newTags) => setEditedProduct(prev =>
                      prev ? { ...prev, categories: newTags } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Tags</FormLabel>
                  <TagInput
                    tags={editedProduct?.tags || []}
                    onChange={(newTags) => setEditedProduct(prev =>
                      prev ? { ...prev, tags: newTags } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Ingredients</FormLabel>
                  <TagInput
                    tags={editedProduct?.ingredients || []}
                    onChange={(newTags) => setEditedProduct(prev =>
                      prev ? { ...prev, ingredients: newTags } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>How to Use</FormLabel>
                  <Textarea
                    value={editedProduct?.howToUse}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, howToUse: e.target.value } : null
                    )}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Benefits</FormLabel>
                  <Textarea
                    value={editedProduct?.benefits}
                    onChange={(e) => setEditedProduct(prev =>
                      prev ? { ...prev, benefits: e.target.value } : null
                    )}
                  />
                </FormControl>

                {/* Shipping Information */}
                <Box borderWidth="1px" borderRadius="lg" p={4}>
                  <Heading size="md" mb={4}>Shipping Information</Heading>
                  <VStack spacing={4}>
                    <FormControl>
                      <FormLabel>Weight (kg)</FormLabel>
                      <Input
                        type="number"
                        value={editedProduct?.shipping?.weight}
                        onChange={(e) => setEditedProduct(prev =>
                          prev ? {
                            ...prev,
                            shipping: {
                              ...prev.shipping,
                              weight: parseFloat(e.target.value) || 0 // Ensure it's always a number
                            }
                          } : null
                        )}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Dimensions</FormLabel>
                      <Input
                        value={editedProduct?.shipping?.dimensions}
                        onChange={(e) => setEditedProduct(prev =>
                          prev ? {
                            ...prev,
                            shipping: {
                              ...prev.shipping,
                              dimensions: e.target.value || '' // Ensure it's always a string
                            }
                          } : null
                        )}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Requires Special Handling</FormLabel>
                      <Switch
                        isChecked={editedProduct?.shipping?.requiresSpecialHandling}
                        onChange={(e) => setEditedProduct(prev =>
                          prev ? {
                            ...prev,
                            shipping: {
                              ...prev.shipping,
                              requiresSpecialHandling: e.target.checked
                            }
                          } : null
                        )}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Available Shipping Methods</FormLabel>
                      <CheckboxGroup
                        value={editedProduct?.shipping?.availableShippingMethods}
                        onChange={(values) => setEditedProduct(prev =>
                          prev ? {
                            ...prev,
                            shipping: {
                              ...prev.shipping,
                              availableShippingMethods: values as ShippingMethod[]
                            }
                          } : null
                        )}
                      >
                        <VStack align="start">
                          {Object.values(ShippingMethod).map((method) => (
                            <Checkbox key={method} value={method}>
                              {method.replace('_', ' ')}
                            </Checkbox>
                          ))}
                        </VStack>
                      </CheckboxGroup>
                    </FormControl>
                  </VStack>
                </Box>

                {/* Variants Section */}
                <Box borderWidth="1px" borderRadius="lg" p={4}>
                  <Heading size="md" mb={4}>Variants</Heading>
                  <VStack spacing={4}>
                    {editedProduct?.variants?.map((variant, idx) => (
                      <Box key={idx} p={4} borderWidth="1px" borderRadius="md" width="100%">
                        <VStack spacing={3}>
                          <FormControl>
                            <FormLabel>Variant Title</FormLabel>
                            <Input
                              value={variant.title}
                              onChange={(e) => {
                                const newVariants = [...(editedProduct?.variants || [])];
                                newVariants[idx] = { ...variant, title: e.target.value };
                                setEditedProduct(prev =>
                                  prev ? { ...prev, variants: newVariants } : null
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel>Price</FormLabel>
                            <Input
                              type="number"
                              value={variant.price}
                              onChange={(e) => {
                                const newVariants = [...(editedProduct?.variants || [])];
                                newVariants[idx] = { ...variant, price: parseFloat(e.target.value) };
                                setEditedProduct(prev =>
                                  prev ? { ...prev, variants: newVariants } : null
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel>SKU</FormLabel>
                            <Input
                              value={variant.sku}
                              onChange={(e) => {
                                const newVariants = [...(editedProduct?.variants || [])];
                                newVariants[idx] = { ...variant, sku: e.target.value };
                                setEditedProduct(prev =>
                                  prev ? { ...prev, variants: newVariants } : null
                                );
                              }}
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel>Stock Level</FormLabel>
                            <Input
                              type="number"
                              value={variant.stockLevel}
                              onChange={(e) => {
                                const newVariants = [...(editedProduct?.variants || [])];
                                newVariants[idx] = { ...variant, stockLevel: parseInt(e.target.value) };
                                setEditedProduct(prev =>
                                  prev ? { ...prev, variants: newVariants } : null
                                );
                              }}
                            />
                          </FormControl>

                          <Button
                            colorScheme="red"
                            onClick={() => {
                              const newVariants = editedProduct?.variants.filter((_, i) => i !== idx);
                              setEditedProduct(prev =>
                                prev ? { ...prev, variants: newVariants } : null
                              );
                            }}
                          >
                            Delete Variant
                          </Button>
                        </VStack>
                      </Box>
                    ))}
                    <Button
                      leftIcon={<AddIcon />}
                      onClick={() => {
                        const newVariant: ProductVariant = {
                          variantId: Date.now().toString(), // Temporary ID
                          title: '',
                          price: 0,
                          sku: '',
                          stockLevel: 0
                        };
                        setEditedProduct(prev =>
                          prev ? {
                            ...prev,
                            variants: [...(prev.variants || []), newVariant]
                          } : null
                        );
                      }}
                    >
                      Add Variant
                    </Button>
                  </VStack>
                </Box>
              </VStack>
            ) : (
              // Buyer View / Display Mode
              <VStack align="stretch" spacing={6}>
                <Box>
                  <Heading size="xl">{data.product.name}</Heading>
                  <HStack spacing={2} mt={2}>
                    {data.product.isBestSeller && (
                      <Badge colorScheme="orange">Best Seller</Badge>
                    )}
                    {data.product.isFeatured && (
                      <Badge colorScheme="purple">Featured</Badge>
                    )}
                  </HStack>
                </Box>

                <Box>
                  <Heading size="lg" color="blue.600">
                    ${data.product.price.toFixed(2)}
                  </Heading>
                  <Text color={data.product.stockLevel > 0 ? "green.500" : "red.500"}>
                    {data.product.stockLevel > 0 ? "In Stock" : "Out of Stock"}
                  </Text>
                </Box>

                <Box>
                  <Heading size="md">Description</Heading>
                  <Text mt={2}>{data.product.description}</Text>
                </Box>

                {data.product.variants && data.product.variants.length > 0 && (
                  <Box>
                    <Heading size="md">Available Variants</Heading>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={2}>
                      {data.product.variants.map((variant: ProductVariant) => (
                        <Box
                          key={variant.variantId}
                          p={4}
                          border="1px"
                          borderColor="gray.200"
                          borderRadius="md"
                        >
                          <Text fontWeight="bold">{variant.title}</Text>
                          <Text>${variant.price.toFixed(2)}</Text>
                          <Text>Stock: {variant.stockLevel}</Text>
                          {variant.sku && <Text>SKU: {variant.sku}</Text>}
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                )}

                {data.product.ingredients && data.product.ingredients.length > 0 && (
                  <Box>
                    <Heading size="md">Ingredients</Heading>
                    <UnorderedList mt={2}>
                      {data.product.ingredients.map((ingredient: string, index: number) => (
                        <ListItem key={index}>{ingredient}</ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                )}

                {data.product.howToUse && (
                  <Box>
                    <Heading size="md">How to Use</Heading>
                    <Text mt={2}>{data.product.howToUse}</Text>
                  </Box>
                )}

                {data.product.benefits && (
                  <Box>
                    <Heading size="md">Benefits</Heading>
                    <Text mt={2}>{data.product.benefits}</Text>
                  </Box>
                )}

                {data.product.shipping && (
                  <Box>
                    <Heading size="md">Shipping Information</Heading>
                    <VStack align="stretch" mt={2} spacing={2}>
                      <Text>Weight: {data.product.shipping.weight}kg</Text>
                      <Text>Dimensions: {data.product.shipping.dimensions}</Text>
                      <Text>
                        Shipping Methods: {data.product.shipping.availableShippingMethods.join(", ")}
                      </Text>
                      {data.product.shipping.requiresSpecialHandling && (
                        <Text color="orange.500">Requires Special Handling</Text>
                      )}
                    </VStack>
                  </Box>
                )}

                {data.product.categories && data.product.categories.length > 0 && (
                  <Box>
                    <Heading size="md">Categories</Heading>
                    <Wrap mt={2}>
                      {data.product.categories.map((category: string, index: number) => (
                        <WrapItem key={index}>
                          <Tag size="md" variant="subtle" colorScheme="blue">
                            {category}
                          </Tag>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </Box>
                )}

                {data.product.tags && data.product.tags.length > 0 && (
                  <Box>
                    <Heading size="md">Tags</Heading>
                    <Wrap mt={2}>
                      {data.product.tags.map((tag: string, index: number) => (
                        <WrapItem key={index}>
                          <Tag size="md" variant="subtle" colorScheme="gray">
                            {tag}
                          </Tag>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </Box>
                )}

                {/* Add to Cart Section */}
                <Box borderWidth="1px" borderRadius="lg" p={4}>
                  <VStack spacing={4}>
                    {data.product.variants && data.product.variants.length > 0 && (
                      <FormControl>
                        <FormLabel>Select Variant</FormLabel>
                        <Select
                          value={selectedVariant || ''}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedVariant(e.target.value)}
                        >
                          <option value="">Select a variant</option>
                          {data.product.variants.map((variant: ProductVariant) => (
                            <option key={variant.variantId} value={variant.variantId}>
                              {variant.title} - ${variant.price.toFixed(2)}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <FormControl>
                      <FormLabel>Quantity</FormLabel>
                      <NumberInput
                        min={1}
                        value={quantity}
                        onChange={(_, value) => setQuantity(value)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>

                    <AuthGuard onlyTriggerWhenClicked={true}>
                      <Button
                        colorScheme="blue"
                        leftIcon={<FiShoppingCart />}
                        onClick={handleAddToCart}
                        isLoading={addingToCart}
                        loadingText="Adding to cart..."
                      >
                        Add to Cart
                      </Button>
                    </AuthGuard>
                  </VStack>
                </Box>



              </VStack>
            )}
          </Stack>
        </Grid>
      </Container>
      <CartPreview />
      <FooterWithFourColumns />
    </Box>
  );
};

export default ProductView; 