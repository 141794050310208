import React from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  useToast,
  HStack,
  Box,
  Grid,
  Select,
  Badge,
  Switch,
} from "@chakra-ui/react";
import {FooterWithFourColumns} from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";

interface Domain {
  name: string;
  status: "ACTIVE" | "PENDING" | "EXPIRED";
  registrationDate: string;
  expiryDate: string;
  autoRenew: boolean;
}

interface Hosting {
  type: "STATIC" | "DYNAMIC";
  status: "ACTIVE" | "STOPPED";
  region: string;
}

interface Website {
  id: string;
  name: string;
  status: "ACTIVE" | "SUSPENDED" | "ARCHIVED";
  client: {
    id: string;
    name: string;
  };
  domain: Domain;
  hosting: Hosting;
  createdAt: string;
  updatedAt: string;
}

interface FormData {
  name: string;
  status: "ACTIVE" | "SUSPENDED" | "ARCHIVED";
  domain: Domain;
  hosting: Hosting;
}

const GET_WEBSITE = gql`
  query GetWebsite($id: ID!) {
    website(id: $id) {
      id
      name
      status
      client {
        id
        name
      }
      domain {
        name
        status
        registrationDate
        expiryDate
        autoRenew
      }
      hosting {
        type
        status
        region
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_WEBSITE = gql`
  mutation UpdateWebsite($id: ID!, $input: UpdateWebsiteInput!) {
    updateWebsite(id: $id, input: $input) {
      id
      name
      status
      domain {
        name
        status
        autoRenew
      }
      hosting {
        type
        status
        region
      }
      updatedAt
    }
  }
`;

export default function WebsiteDetails() {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const [isEditing, setIsEditing] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    status: "ACTIVE",
    domain: {
      name: "",
      status: "ACTIVE",
      autoRenew: true,
    },
    hosting: {
      type: "STATIC",
      status: "ACTIVE",
      region: "",
    },
  });

  const { loading, error, data } = useQuery(GET_WEBSITE, {
    variables: { id },
    onCompleted: (data) => {
      const website = data.website;
      setFormData({
        name: website.name,
        status: website.status,
        domain: {
          name: website.domain.name,
          status: website.domain.status,
          autoRenew: website.domain.autoRenew,
        },
        hosting: {
          type: website.hosting.type,
          status: website.hosting.status,
          region: website.hosting.region,
        },
      });
    },
  });

  const [updateWebsite, { loading: updateLoading }] = useMutation(
    UPDATE_WEBSITE,
    {
      onCompleted: () => {
        toast({
          title: "Success",
          description: "Website information updated successfully",
          status: "success",
          duration: 5000,
        });
        setIsEditing(false);
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
        });
      },
    }
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const nameParts = name.split(".");

    if (nameParts.length === 2) {
      const [section, field] = nameParts as [keyof FormData, string];
      if (section === "domain" || section === "hosting") {
        setFormData((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            [field]: value,
          },
        }));
      }
    } else {
      // Add this else block to handle top-level fields
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAutoRenewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      domain: {
        ...prev.domain,
        autoRenew: e.target.checked,
      },
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateWebsite({
      variables: {
        id,
        input: formData,
      },
    });
  };

  const getStatusColor = (status: string) => {
    const colors = {
      ACTIVE: "green",
      SUSPENDED: "orange",
      ARCHIVED: "gray",
      PENDING: "yellow",
      EXPIRED: "red",
      STOPPED: "red",
    };
    return colors[status as keyof typeof colors] || "gray";
  };

  if (loading) return <div className="p-4">Loading...</div>;
  if (error)
    return <div className="p-4 text-red-500">Error: {error.message}</div>;

  const website = data?.website;
  if (!website) return <div className="p-4">Website not found</div>;

  return (
    <Container className="max-w-4xl py-8">
      <Card>
        <CardHeader>
          <HStack className="justify-between">
            <Box>
              <Heading size="lg">{website.name}</Heading>
              <Text className="mt-2 text-gray-500">
                Client: {website.client.name}
              </Text>
            </Box>
            <Button
              onClick={() => setIsEditing(!isEditing)}
              variant={isEditing ? "destructive" : "default"}
            >
              {isEditing ? "Cancel" : "Edit"}
            </Button>
          </HStack>
        </CardHeader>

        <CardBody>
          <form onSubmit={handleSubmit}>
            <Stack className="space-y-6">
              <Grid className="grid-cols-1 md:grid-cols-2 gap-6">
                {/* Basic Information */}
                <Box className="space-y-4">
                  <Heading size="sm">Website Information</Heading>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    >
                      <option value="ACTIVE">Active</option>
                      <option value="SUSPENDED">Suspended</option>
                      <option value="ARCHIVED">Archived</option>
                    </Select>
                  </FormControl>
                </Box>

                {/* Domain Information */}
                <Box className="space-y-4">
                  <Heading size="sm">Domain Information</Heading>
                  <FormControl>
                    <FormLabel>Domain Name</FormLabel>
                    <Input
                      name="domain.name"
                      value={formData.domain.name}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Domain Status</FormLabel>
                    <Select
                      name="domain.status"
                      value={formData.domain.status}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    >
                      <option value="ACTIVE">Active</option>
                      <option value="PENDING">Pending</option>
                      <option value="EXPIRED">Expired</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Auto Renew</FormLabel>
                    <Switch
                      isChecked={formData.domain.autoRenew}
                      onChange={handleAutoRenewChange}
                      isDisabled={!isEditing}
                    />
                  </FormControl>
                </Box>

                {/* Hosting Information */}
                <Box className="space-y-4">
                  <Heading size="sm">Hosting Information</Heading>
                  <FormControl>
                    <FormLabel>Hosting Type</FormLabel>
                    <Select
                      name="hosting.type"
                      value={formData.hosting.type}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    >
                      <option value="STATIC">Static</option>
                      <option value="DYNAMIC">Dynamic</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Hosting Status</FormLabel>
                    <Select
                      name="hosting.status"
                      value={formData.hosting.status}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    >
                      <option value="ACTIVE">Active</option>
                      <option value="STOPPED">Stopped</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Region</FormLabel>
                    <Input
                      name="hosting.region"
                      value={formData.hosting.region}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </FormControl>
                </Box>
              </Grid>

              {isEditing && (
                <Box className="flex justify-end pt-4">
                  <Button type="submit" disabled={updateLoading}>
                    Save Changes
                  </Button>
                </Box>
              )}

              {!isEditing && (
                <Box className="pt-4 text-sm text-gray-500">
                  <Text>
                    Created: {new Date(website.createdAt).toLocaleDateString()}
                  </Text>
                  <Text>
                    Last Updated:{" "}
                    {new Date(website.updatedAt).toLocaleDateString()}
                  </Text>
                </Box>
              )}
            </Stack>
          </form>
        </CardBody>
      </Card>
      <FooterWithFourColumns />
    </Container>
  );
}
