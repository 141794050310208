import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Grid,
  GridItem,
  Skeleton,
  Alert,
  AlertIcon,
  Divider,
  HStack,
  Progress,
  Tag,
  Textarea,
  Button,
  Input,
  Select,
  SimpleGrid,
  UnorderedList,
  ListItem,
  FormControl,
  FormLabel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Link,
  IconButton,
  Image,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { DeleteIcon, AddIcon, AttachmentIcon, ChatIcon, EditIcon, CheckIcon } from '@chakra-ui/icons';
import { FilePreviewList } from '../components/FilePreviewList';
import { ServiceProgressBar } from '../components/ServiceProgressBar';
import { motion } from 'framer-motion'; // Add this import
import { useColorModeValue } from '@chakra-ui/react'; //
interface WebsiteService {
  id: string;
  websiteName: string;
  type: string;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
}

enum WebsiteSetupStep {
  REQUIREMENTS_GATHERING = 'REQUIREMENTS_GATHERING',
  DESIGN_MOCKUP = 'DESIGN_MOCKUP',
  DESIGN_APPROVAL = 'DESIGN_APPROVAL',
  DEVELOPMENT = 'DEVELOPMENT',
  CONTENT_CREATION = 'CONTENT_CREATION',
  TESTING = 'TESTING',
  CLIENT_REVIEW = 'CLIENT_REVIEW',
  DEPLOYMENT = 'DEPLOYMENT',
  FINAL_APPROVAL = 'FINAL_APPROVAL'
}

enum StepStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED"
}

interface Attachment {
  id: string;
  url: string;
  type: AttachmentType;
  caption?: string;
  createdAt: string;
  updatedAt: string;
  tenantId: string;
}

enum AttachmentType {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  OTHER = 'OTHER'
}

interface WorkItemComment {
  id: string;
  content: string;
  author: string;
  createdAt: string;
  updatedAt: string;
  tenantId: string;
}

interface WebsiteServiceLineItem {
  id: string;
  description: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  tenantId: string;
}

enum WorkItemStatus {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  COMPLETED = 'COMPLETED'
}

interface WorkItem {
  id: string;
  title: string;
  description?: string;
  status: WorkItemStatus;
  completedAt?: string;
  createdAt: string;
  updatedAt: string;
  tenantId: string;
  attachments?: Attachment[];
  comments?: WorkItemComment[];
  lineItems?: WebsiteServiceLineItem[];
}

interface WebsiteMilestone {
  id: string;
  title: string;
  description?: string;
  status: MilestoneStatus;
  order: number;
  dueDate?: string;
  createdAt: string;
  updatedAt: string;
  workItems?: WorkItem[];
}

enum MilestoneStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  BLOCKED = 'BLOCKED'
}

const GET_SERVICE = gql`
  query GetService($id: ID!) {
    service(id: $id) {
      id
      type
      status
      serviceStartDate
      jobSummary
      tenantId
      createdAt
      updatedAt
      progress
      client {
        id
        fName
        lName
        email
        phoneNumber
        tenantId
        createdAt
        updatedAt
      }
      websiteService {
        id
        websiteName
        type
        tenantId
        createdAt
        updatedAt
        milestones {
          id
          title
          description
          status
          order
          dueDate
          workItems {
            id
            title
            description
            status
            completedAt
            createdAt
            updatedAt
            attachments {
              id
              url
              type
              caption
              createdAt
              updatedAt
            }
            comments {
              id
              content
              author
              createdAt
              updatedAt
            }
            lineItems {
              id
              description
              amount
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

const WEBSITE_SETUP_STEPS = [
  {
    step: WebsiteSetupStep.REQUIREMENTS_GATHERING,
    label: "Requirements Gathering",
    description: "Collect and document website requirements",
    defaultNotes: "Gathered initial requirements and project scope."
  },
  {
    step: WebsiteSetupStep.DESIGN_MOCKUP,
    label: "Design Mockup",
    description: "Create initial website design mockups",
    defaultNotes: "Created design mockups for client review."
  },
  {
    step: WebsiteSetupStep.DESIGN_APPROVAL,
    label: "Design Approval",
    description: "Get client approval on design",
    defaultNotes: "Design approved by client."
  },
  {
    step: WebsiteSetupStep.DEVELOPMENT,
    label: "Development",
    description: "Build website according to approved design",
    defaultNotes: "Website development completed."
  },
  {
    step: WebsiteSetupStep.CONTENT_CREATION,
    label: "Content Creation",
    description: "Create and populate website content",
    defaultNotes: "Website content created and implemented."
  },
  {
    step: WebsiteSetupStep.TESTING,
    label: "Testing",
    description: "Test website functionality and responsiveness",
    defaultNotes: "Website testing completed successfully."
  },
  {
    step: WebsiteSetupStep.CLIENT_REVIEW,
    label: "Client Review",
    description: "Client review of completed website",
    defaultNotes: "Client review completed."
  },
  {
    step: WebsiteSetupStep.DEPLOYMENT,
    label: "Deployment",
    description: "Deploy website to production",
    defaultNotes: "Website successfully deployed."
  },
  {
    step: WebsiteSetupStep.FINAL_APPROVAL,
    label: "Final Approval",
    description: "Get final client approval",
    defaultNotes: "Final client approval received."
  }
];


interface ClientInformationProps {
  tenantId: string;
  id: string;
  isAdmin: boolean;
}

interface StepProgress {
  stepId: string;
  step: WebsiteSetupStep;
  status: StepStatus;
  completedAt?: string;
  notes?: string;
}

const ClientInformation: React.FC<ClientInformationProps> = ({ tenantId, id, isAdmin }) => {
  const { loading, error, data } = useQuery(GET_SERVICE, {
    variables: { id }
  });

  if (!id) return null;
  if (loading) return <Skeleton height="100px" />;
  if (error) return (
    <Alert status="error">
      <AlertIcon />
      Error loading client information: {error.message}
    </Alert>
  );

  if (!data?.service) {
    return (
      <Alert status="info">
        <AlertIcon />
        No client information found
      </Alert>
    );
  }

  const client = data.service.client;

  return (
    <VStack align="stretch" spacing={3}>
      {/* ... existing client information JSX ... */}
    </VStack>
  );
};

const ADD_MILESTONE = gql`
  mutation AddMilestone($websiteServiceId: ID!, $input: WebsiteMilestoneInput!) {
    addMilestone(websiteServiceId: $websiteServiceId, input: $input) {
      id
      websiteName
      milestones {
        id
        title
        description
        status
        order
        dueDate
      }
    }
  }
`;

interface MilestoneInput {
  title: string;
  description: string;
  order: number;
  dueDate: string;
}

const AddMilestoneForm: React.FC<{ serviceId: string }> = ({ serviceId }) => {
  const [addMilestone, { loading }] = useMutation(ADD_MILESTONE);
  const [milestoneData, setMilestoneData] = useState<MilestoneInput>({
    title: '',
    description: '',
    order: 1,
    dueDate: new Date().toISOString()
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formattedData = {
        ...milestoneData,
        dueDate: new Date(milestoneData.dueDate).toISOString()
      };

      await addMilestone({
        variables: {
          websiteServiceId: serviceId,
          input: formattedData
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: serviceId }
          }
        ]
      });

      // Reset form
      setMilestoneData({
        title: '',
        description: '',
        order: 1,
        dueDate: new Date().toISOString().split('T')[0]
      });
    } catch (error) {
      console.error('Error adding milestone:', error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Add New Milestone</Heading>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                value={milestoneData.title}
                onChange={(e) => setMilestoneData(prev => ({ ...prev, title: e.target.value }))}
                placeholder="Milestone title"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={milestoneData.description}
                onChange={(e) => setMilestoneData(prev => ({ ...prev, description: e.target.value }))}
                placeholder="Milestone description"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Due Date</FormLabel>
              <Input
                type="date"
                value={milestoneData.dueDate.split('T')[0]}
                onChange={(e) => setMilestoneData(prev => ({
                  ...prev,
                  dueDate: e.target.value
                }))}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Order</FormLabel>
              <Input
                type="number"
                value={milestoneData.order}
                onChange={(e) => setMilestoneData(prev => ({ ...prev, order: parseInt(e.target.value) }))}
                min={1}
              />
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              isLoading={loading}
              width="full"
            >
              Add Milestone
            </Button>
          </VStack>
        </form>
      </CardBody>
    </Card>
  );
};

const DELETE_MILESTONE = gql`
  mutation DeleteWebsiteMilestone($serviceId: ID!, $milestoneId: ID!) {
    deleteWebsiteMilestone(serviceId: $serviceId, milestoneId: $milestoneId)
  }
`;

interface DeleteMilestoneButtonProps {
  serviceId: string;
  milestoneId: string;
}

const DeleteMilestoneButton: React.FC<DeleteMilestoneButtonProps> = ({ serviceId, milestoneId }) => {
  const [deleteMilestone, { loading }] = useMutation(DELETE_MILESTONE);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleDelete = async () => {
    try {
      await deleteMilestone({
        variables: {
          serviceId,
          milestoneId
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: serviceId }
          }
        ]
      });
      setIsConfirmOpen(false);
    } catch (error) {
      console.error('Error deleting milestone:', error);
      // You might want to add toast notification here 
    }
  };

  return (
    <>
      <Button
        size="sm"
        colorScheme="red"
        variant="ghost"
        isLoading={loading}
        onClick={() => setIsConfirmOpen(true)}
      >
        <Icon as={DeleteIcon} />
      </Button>

      <AlertDialog
        isOpen={isConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsConfirmOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Milestone
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This will delete the milestone and all associated work items, comments, and attachments.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3} isLoading={loading}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const ADD_WORK_ITEM = gql`
  mutation AddWorkItem($milestoneId: ID!, $input: WorkItemInput!) {
    addWorkItem(milestoneId: $milestoneId, input: $input) {
      id
      websiteName
      milestones {
        id
        title
        description
        status
        order
        dueDate
        workItems {
          id
          title
          description
          status
          attachments {
            id
            url
            type
            caption
          }
          comments {
            id
            content
            author
            createdAt
          }
          lineItems {
            id
            description
            amount
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

interface AddWorkItemFormProps {
  milestoneId: string;
  serviceId: string;
  onClose: () => void;
}

const AddWorkItemForm: React.FC<AddWorkItemFormProps> = ({ milestoneId, serviceId, onClose }) => {
  const [addWorkItem, { loading }] = useMutation(ADD_WORK_ITEM);
  const [workItemData, setWorkItemData] = useState({
    title: '',
    description: '',
    status: WorkItemStatus.TODO,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await addWorkItem({
        variables: {
          milestoneId,
          input: workItemData
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: milestoneId }
          }
        ]
      });

      // Console log the response
      console.log('New Work Item Added:', {
        workItemData,
        response: response.data.addWorkItem
      });

      onClose();
    } catch (error) {
      console.error('Error adding work item:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            value={workItemData.title}
            onChange={(e) => setWorkItemData(prev => ({ ...prev, title: e.target.value }))}
            placeholder="Work item title"
          />
        </FormControl>

        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            value={workItemData.description}
            onChange={(e) => setWorkItemData(prev => ({ ...prev, description: e.target.value }))}
            placeholder="Work item description"
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Status</FormLabel>
          <Select
            value={workItemData.status}
            onChange={(e) => setWorkItemData(prev => ({
              ...prev,
              status: e.target.value as WorkItemStatus
            }))}
          >
            {Object.values(WorkItemStatus).map(status => (
              <option key={status} value={status}>
                {status.replace(/_/g, ' ')}
              </option>
            ))}
          </Select>
        </FormControl>

        <Button
          type="submit"
          colorScheme="blue"
          isLoading={loading}
          width="full"
        >
          Add Work Item
        </Button>
      </VStack>
    </form>
  );
};

// Update the mutation to match the exact pattern
const UPDATE_MILESTONE = gql`
  mutation UpdateMilestone($websiteServiceId: ID!, $milestoneId: ID!, $status: MilestoneStatus, $dueDate: String) {
    updateMilestone(
      websiteServiceId: $websiteServiceId
      milestoneId: $milestoneId
      status: $status
      dueDate: $dueDate
    ) {
      id
      milestones {
        id
        title
        status
        dueDate
      }
    }
  }
`;

// Update the MilestoneCard component
interface MilestoneCardProps {
  milestone: WebsiteMilestone;
  websiteServiceId: string;
  isAdmin: boolean;
}

const MilestoneCard: React.FC<MilestoneCardProps> = ({ milestone, websiteServiceId, isAdmin }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateMilestone, { loading: updateLoading }] = useMutation(UPDATE_MILESTONE);
  const [deleteWorkItem] = useMutation(DELETE_WORK_ITEM);

  const handleStatusChange = async (status: MilestoneStatus) => {
    try {
      await updateMilestone({
        variables: {
          websiteServiceId,
          milestoneId: milestone.id,
          status
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: websiteServiceId }
          }
        ]
      });
    } catch (error) {
      console.error('Error updating milestone:', error);
    }
  };

  const handleDueDateChange = async (dueDate: string) => {
    try {
      await updateMilestone({
        variables: {
          websiteServiceId,
          milestoneId: milestone.id,
          dueDate: dueDate // No need to convert to ISO string since we're sending as string
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: websiteServiceId }
          }
        ]
      });
    } catch (error) {
      console.error('Error updating milestone due date:', error);
    }
  };

  interface WorkItemActionsProps {
    workItem: WorkItem;
    milestoneId: string;
    serviceId: string;
    isAdmin: boolean; // Add this
  }

  const WorkItemActions: React.FC<WorkItemActionsProps> = ({ workItem, milestoneId, serviceId, isAdmin }) => {
    console.log('WorkItem Details:', {
      id: workItem.id,
      title: workItem.title,
      status: workItem.status,
      attachments: workItem.attachments,
      comments: workItem.comments,
      lineItems: workItem.lineItems
    });

    const attachmentDisclosure = useDisclosure();
    const commentDisclosure = useDisclosure();
    const lineItemDisclosure = useDisclosure();
    const editDisclosure = useDisclosure();
    const [deleteWorkItemAttachment] = useMutation(DELETE_WORK_ITEM_ATTACHMENT);
    const [deleteWorkItemComment] = useMutation(DELETE_WORK_ITEM_COMMENT);
    const [deleteWorkItemLineItem] = useMutation(DELETE_WORK_ITEM_LINE_ITEM);

    return (
      <>
        {/* Update the action buttons layout */}
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 4 }}
          spacing={2}
          width="100%"
        >
          {isAdmin && ( // Only show these buttons if isAdmin is true
            <>
              <Button
                size="sm"
                leftIcon={<EditIcon />}
                onClick={editDisclosure.onOpen}
                width="100%"
              >
                Edit Work Item
              </Button>
              <Button
                size="sm"
                leftIcon={<AttachmentIcon />}
                onClick={attachmentDisclosure.onOpen}
                width="100%"
              >
                Add Attachment
              </Button>
              <Button
                size="sm"
                leftIcon={<ChatIcon />}
                onClick={commentDisclosure.onOpen}
                width="100%"
              >
                Add Comment
              </Button>
              <Button
                size="sm"
                leftIcon={<AddIcon />}
                onClick={lineItemDisclosure.onOpen}
                width="100%"
              >
                Add Line Item
              </Button>
            </>
          )}
        </SimpleGrid>

        {/* Existing attachments */}
        {workItem.attachments && workItem.attachments.length > 0 && (
          <Box mt={4}>
            <Text fontWeight="bold" mb={2}>Attachments</Text>
            <FilePreviewList
              hashes={workItem.attachments.map(attachment => attachment.url)}
            />
          </Box>
        )}


        {/* Existing comments */}
        {workItem.comments && workItem.comments.length > 0 && (
          <Box mt={4}>
            <Text fontWeight="bold" mb={2}>Comments</Text>
            <VStack align="stretch" spacing={2}>
              {workItem.comments?.map(comment => (
                <Box
                  key={comment.id}
                  p={3}
                  bg="gray.50"
                  borderRadius="md"
                >
                  <VStack align="stretch" spacing={1}>
                    <HStack justify="space-between" wrap="wrap">
                      <Text fontSize="sm" fontWeight="bold">
                        {comment.author}
                      </Text>
                      <IconButton
                        aria-label="Delete comment"
                        icon={<DeleteIcon />}
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => {
                          if (window.confirm('Delete this comment?')) {
                            deleteWorkItemComment({
                              variables: {
                                workItemId: workItem.id,
                                commentId: comment.id
                              }
                            });
                          }
                        }}
                      />
                    </HStack>
                    <Text fontSize="sm">{comment.content}</Text>
                    <Text fontSize="xs" color="gray.500">
                      {new Date(comment.createdAt).toLocaleString()}
                    </Text>
                  </VStack>
                </Box>
              ))}
            </VStack>
          </Box>
        )}

        {/* Existing line items */}
        {workItem.lineItems && workItem.lineItems.length > 0 && (
          <Box mt={4}>
            <Text fontWeight="bold" mb={2}>Line Items</Text>
            <VStack align="stretch" spacing={2}>
              {workItem.lineItems?.map(item => (
                <Box
                  key={item.id}
                  p={3}
                  bg="gray.50"
                  borderRadius="md"
                >
                  <HStack
                    justify="space-between"
                    wrap="wrap"
                    spacing={3}
                  >
                    <VStack align="start" spacing={0}>
                      <Text fontSize="sm">{item.description}</Text>
                      <Text fontWeight="bold">${item.amount.toFixed(2)}</Text>
                    </VStack>
                    <IconButton
                      aria-label="Delete line item"
                      icon={<DeleteIcon />}
                      size="sm"
                      colorScheme="red"
                      variant="ghost"
                      onClick={() => {
                        if (window.confirm('Delete this line item?')) {
                          deleteWorkItemLineItem({
                            variables: {
                              workItemId: workItem.id,
                              lineItemId: item.id
                            }
                          });
                        }
                      }}
                    />
                  </HStack>
                </Box>
              ))}
            </VStack>
          </Box>
        )}

        {/* Modals */}
        <EditWorkItemModal
          isOpen={editDisclosure.isOpen}
          onClose={editDisclosure.onClose}
          workItem={workItem}
          milestoneId={milestoneId}
          serviceId={serviceId}
        />
        <AddAttachmentModal
          isOpen={attachmentDisclosure.isOpen}
          onClose={attachmentDisclosure.onClose}
          workItemId={workItem.id}
        />
        <AddCommentModal
          isOpen={commentDisclosure.isOpen}
          onClose={commentDisclosure.onClose}
          workItemId={workItem.id}
        />
        <AddLineItemModal
          isOpen={lineItemDisclosure.isOpen}
          onClose={lineItemDisclosure.onClose}
          workItemId={workItem.id}
        />
      </>
    );
  };

  return (
    <Card variant="outline">
      <CardBody>
        <VStack align="stretch" spacing={2}>
          {/* Update the header section to be more responsive */}
          <VStack align="stretch" spacing={2}>
            <Heading size="sm">{milestone.title}</Heading>

            {/* Controls in a responsive grid */}
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={2}
              width="100%"
            >
              {/* Status and Date Controls */}
              {/* Status and Date Controls */}
              <HStack spacing={2}>
                {isAdmin ? ( // Only show dropdown if admin
                  <Select
                    size="sm"
                    value={milestone.status}
                    onChange={(e) => handleStatusChange(e.target.value as MilestoneStatus)}
                    isDisabled={updateLoading}
                    maxW={{ base: "150px", md: "200px" }}
                  >
                    {Object.values(MilestoneStatus).map((status) => (
                      <option key={status} value={status}>
                        {status.replace(/_/g, ' ')}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Badge colorScheme={
                    milestone.status === MilestoneStatus.COMPLETED ? 'green' :
                      milestone.status === MilestoneStatus.IN_PROGRESS ? 'yellow' :
                        milestone.status === MilestoneStatus.BLOCKED ? 'red' : 'gray'
                  }>
                    {milestone.status.replace(/_/g, ' ')}
                  </Badge>
                )}
              </HStack>

              {/* Date Input and Delete Button */}
              <HStack spacing={2} justify={{ base: "flex-start", md: "flex-end" }}>
                {isAdmin && ( // Only show date input and delete button if admin
                  <>
                    <Input
                      type="date"
                      size="sm"
                      value={milestone.dueDate?.split('T')[0] || ''}
                      onChange={(e) => handleDueDateChange(e.target.value)}
                      isDisabled={updateLoading}
                      maxW={{ base: "150px", md: "150px" }}
                    />
                    <DeleteMilestoneButton
                      serviceId={websiteServiceId}
                      milestoneId={milestone.id}
                    />
                  </>
                )}
              </HStack>
            </SimpleGrid>
          </VStack>

          <Text color="gray.600">{milestone.description}</Text>
          {milestone.dueDate && (
            <Text fontSize="sm" color="gray.500">
              Due: {new Date(milestone.dueDate).toLocaleDateString()}
            </Text>
          )}

          {/* Work Items Section */}
          <Box mt={4}>
            <HStack justify="space-between" mb={2}>
              <Text fontWeight="bold">Work Items</Text>
              {isAdmin && ( // Only show Add Work Item button if admin
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={onOpen}
                >
                  Add Work Item
                </Button>
              )}
            </HStack>

            {milestone.workItems?.length ? (
              <VStack align="stretch" spacing={2}>
                {milestone.workItems.map(workItem => (
                  <Card key={workItem.id} size="sm">
                    <CardBody>
                      <VStack align="stretch" spacing={3}>
                        <HStack justify="space-between">
                          <VStack align="start" spacing={1}>
                            <Text fontWeight="bold">{workItem.title}</Text>
                            <Text fontSize="sm" color="gray.600">
                              {workItem.description}
                            </Text>
                          </VStack>
                          <HStack>
                            <Badge colorScheme={
                              workItem.status === WorkItemStatus.COMPLETED ? 'green' :
                                workItem.status === WorkItemStatus.IN_PROGRESS ? 'yellow' :
                                  workItem.status === WorkItemStatus.IN_REVIEW ? 'orange' : 'gray'
                            }>
                              {workItem.status}
                            </Badge>
                            {isAdmin && ( // Only show delete button if admin
                              <IconButton
                                aria-label="Delete work item"
                                icon={<DeleteIcon />}
                                size="sm"
                                colorScheme="red"
                                variant="ghost"
                                onClick={() => {
                                  if (window.confirm('Are you sure you want to delete this work item?')) {
                                    deleteWorkItem({
                                      variables: {
                                        milestoneId: milestone.id,
                                        workItemId: workItem.id
                                      },
                                      refetchQueries: [
                                        {
                                          query: GET_SERVICE,
                                          variables: { id: websiteServiceId }
                                        }
                                      ]
                                    });
                                  }
                                }}
                              />
                            )}
                          </HStack>
                        </HStack>

                        <WorkItemActions
                          workItem={workItem}
                          milestoneId={milestone.id}
                          serviceId={websiteServiceId}
                          isAdmin={isAdmin}
                        />


                      </VStack>
                    </CardBody>
                  </Card>

                ))}
              </VStack>
            ) : (
              <Text color="gray.500">No work items yet</Text>
            )}
          </Box>
        </VStack>
      </CardBody>

      {/* Move the Modal here, outside of CardBody but still inside the Card */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Work Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <AddWorkItemForm
              milestoneId={milestone.id}
              serviceId={websiteServiceId}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
};

// Update the mutations to match the exact pattern
const ADD_WORK_ITEM_ATTACHMENT = gql`
  mutation AddWorkItemAttachment($workItemId: ID!, $input: AttachmentInput!) {
    addWorkItemAttachment(workItemId: $workItemId, input: $input) {
      id
      milestones {
        id
        workItems {
          id
          attachments {
            id
            url
            type
            caption
            createdAt
          }
        }
      }
    }
  }
`;

const ADD_WORK_ITEM_COMMENT = gql`
  mutation AddWorkItemComment($workItemId: ID!, $input: WorkItemCommentInput!) {
    addWorkItemComment(workItemId: $workItemId, input: $input) {
      id
      milestones {
        workItems {
          id
          comments {
            id
            content
            author
          }
        }
      }
    }
  }
`;

const ADD_WORK_ITEM_LINE_ITEM = gql`
  mutation AddWorkItemLineItem($workItemId: ID!, $input: WebsiteServiceLineItemInput!) {
    addWorkItemLineItem(workItemId: $workItemId, input: $input) {
      id
      milestones {
        workItems {
          id
          lineItems {
            id
            description
            amount
          }
        }
      }
    }
  }
`;

const UPLOAD_ENCRYPTED_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;

const GET_FILE = gql`
  query GetFile($hash: String!) {
    getFile(hash: $hash)
  }
`;

// Update the query to use the batch endpoint


interface AddAttachmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  workItemId: string;
}

interface AttachmentInput {
  url: string;
  type: AttachmentType;
  caption?: string;
}

// Update the modal components to use the exact input types
interface AttachmentInput {
  url: string;
  type: AttachmentType;
  caption?: string;
}

interface WorkItemCommentInput {
  content: string;
}

interface WebsiteServiceLineItemInput {
  description: string;
  amount: number;
}

// Add these interface definitions before the modal components
interface AddAttachmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  workItemId: string;
}

interface AddCommentModalProps {
  isOpen: boolean;
  onClose: () => void;
  workItemId: string;
}

interface AddLineItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  workItemId: string;
}

// First, let's update the AddAttachmentModal component
const AddAttachmentModal: React.FC<AddAttachmentModalProps> = ({ isOpen, onClose, workItemId }) => {
  const [file, setFile] = useState<File | null>(null);
  const [caption, setCaption] = useState('');
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const toast = useToast();

  // Add the upload file mutation
  const [uploadFile] = useMutation(UPLOAD_ENCRYPTED_FILE);
  const [addAttachment, { loading }] = useMutation(ADD_WORK_ITEM_ATTACHMENT);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);

      // Create preview URL
      const url = URL.createObjectURL(selectedFile);
      setPreviewUrl(url);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!file) {
      toast({
        title: "No file selected",
        status: "error",
        duration: 3000,
      });
      return;
    }

    try {
      console.log('📤 Uploading file for work item:', workItemId);

      // First upload the file
      const uploadResponse = await uploadFile({
        variables: { file },
        context: {
          headers: {
            'apollo-require-preflight': 'true',
            'x-apollo-operation-name': 'UploadFile'
          }
        }
      });

      console.log('📁 File upload response:', uploadResponse.data);

      // Then create the attachment
      const attachmentInput = {
        url: uploadResponse.data.uploadFile, // This should be the file hash/URL
        type: file.type.startsWith('image/') ? 'IMAGE' : 'DOCUMENT',
        caption: caption || undefined
      };

      console.log('📎 Creating attachment with input:', attachmentInput);

      const response = await addAttachment({
        variables: {
          workItemId,
          input: attachmentInput
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: workItemId }
          }
        ]
      });

      console.log('✅ Attachment created:', response.data);

      toast({
        title: "Success",
        description: "Attachment added successfully",
        status: "success",
        duration: 3000,
      });

      onClose();
    } catch (error) {
      console.error('❌ Error adding attachment:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to add attachment",
        status: "error",
        duration: 5000,
      });
    }
  };

  // Cleanup preview URL when component unmounts or modal closes
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Attachment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>File</FormLabel>
                <Input
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*,application/pdf"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Caption</FormLabel>
                <Input
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                  placeholder="Optional caption"
                />
              </FormControl>

              {/* Preview section */}
              {previewUrl && file?.type.startsWith('image/') && (
                <Box mt={4}>
                  <Text fontWeight="bold" mb={2}>Preview:</Text>
                  <Image
                    src={previewUrl}
                    alt="Preview"
                    maxH="200px"
                    objectFit="contain"
                  />
                </Box>
              )}

              <Button
                type="submit"
                colorScheme="blue"
                isLoading={loading}
                width="full"
              >
                Add Attachment
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// Update the AddCommentModal component
const AddCommentModal: React.FC<AddCommentModalProps> = ({ isOpen, onClose, workItemId }) => {
  const [addComment, { loading }] = useMutation(ADD_WORK_ITEM_COMMENT);
  const [input, setInput] = useState<WorkItemCommentInput>({
    content: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await addComment({
        variables: {
          workItemId,
          input
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: workItemId }
          }
        ]
      });
      console.log('Added comment:', response.data);
      onClose();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Comment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Comment</FormLabel>
                <Textarea
                  value={input.content}
                  onChange={(e) => setInput({ content: e.target.value })}
                  placeholder="Enter your comment"
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" isLoading={loading}>
                Add Comment
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// Update the AddLineItemModal component
const AddLineItemModal: React.FC<AddLineItemModalProps> = ({ isOpen, onClose, workItemId }) => {
  const [addLineItem, { loading }] = useMutation(ADD_WORK_ITEM_LINE_ITEM);
  const [input, setInput] = useState<WebsiteServiceLineItemInput>({
    description: '',
    amount: 0
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await addLineItem({
        variables: {
          workItemId,
          input
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id: workItemId }
          }
        ]
      });
      console.log('Added line item:', response.data);
      onClose();
    } catch (error) {
      console.error('Error adding line item:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Line Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Description</FormLabel>
                <Input
                  value={input.description}
                  onChange={(e) => setInput(prev => ({ ...prev, description: e.target.value }))}
                  placeholder="Line item description"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Amount</FormLabel>
                <Input
                  type="number"
                  value={input.amount}
                  onChange={(e) => setInput(prev => ({ ...prev, amount: parseFloat(e.target.value) }))}
                  placeholder="0.00"
                  step="0.01"
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" isLoading={loading}>
                Add Line Item
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// Update the UPDATE_WORK_ITEM mutation to match the backend response
const UPDATE_WORK_ITEM = gql`
  mutation UpdateWorkItem($milestoneId: ID!, $workItemId: ID!, $input: WorkItemInput!) {
    updateWorkItem(
      milestoneId: $milestoneId
      workItemId: $workItemId
      input: $input
    ) {
      id
      title
      description
      status
      updatedAt
    }
  }
`;

// Update the EditWorkItemModal component to handle the simpler response
const EditWorkItemModal: React.FC<EditWorkItemModalProps> = ({ isOpen, onClose, workItem, milestoneId, serviceId }) => {
  const [updateWorkItem, { loading }] = useMutation(UPDATE_WORK_ITEM);
  const [input, setInput] = useState({
    title: workItem.title,
    description: workItem.description || '',
    status: workItem.status
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Log the variables we're sending
    console.log('Updating work item with:', {
      milestoneId,
      workItemId: workItem.id,
      input,
      serviceId
    });

    try {
      const response = await updateWorkItem({
        variables: {
          milestoneId,
          workItemId: workItem.id,
          input
        },
        // refetchQueries: [
        //   {
        //     query: GET_SERVICE,
        //     variables: { id: serviceId } // Use the service ID here
        //   }
        // ]
      });
      console.log('Update response:', response.data);
      onClose();
    } catch (error) {
      console.error('Error updating work item:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Work Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Title</FormLabel>
                <Input
                  value={input.title}
                  onChange={(e) => setInput(prev => ({ ...prev, title: e.target.value }))}
                  placeholder="Work item title"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  value={input.description}
                  onChange={(e) => setInput(prev => ({ ...prev, description: e.target.value }))}
                  placeholder="Work item description"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Status</FormLabel>
                <Select
                  value={input.status}
                  onChange={(e) => setInput(prev => ({
                    ...prev,
                    status: e.target.value as WorkItemStatus
                  }))}
                >
                  {Object.values(WorkItemStatus).map(status => (
                    <option key={status} value={status}>
                      {status.replace(/_/g, ' ')}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Button type="submit" colorScheme="blue" isLoading={loading}>
                Update Work Item
              </Button>
            </VStack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface EditWorkItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  workItem: WorkItem;
  milestoneId: string;
  serviceId: string;
}

// Add the delete mutations
const DELETE_WORK_ITEM_ATTACHMENT = gql`
  mutation DeleteWorkItemAttachment($workItemId: ID!, $attachmentId: ID!) {
    deleteWorkItemAttachment(workItemId: $workItemId, attachmentId: $attachmentId)
  }
`;

const DELETE_WORK_ITEM_COMMENT = gql`
  mutation DeleteWorkItemComment($workItemId: ID!, $commentId: ID!) {
    deleteWorkItemComment(workItemId: $workItemId, commentId: $commentId)
  }
`;

const DELETE_WORK_ITEM_LINE_ITEM = gql`
  mutation DeleteWorkItemLineItem($workItemId: ID!, $lineItemId: ID!) {
    deleteWorkItemLineItem(workItemId: $workItemId, lineItemId: $lineItemId)
  }
`;

const UPDATE_WEBSITE_NAME = gql`
  mutation UpdateWebsiteServiceName($websiteServiceId: ID!, $websiteName: String!) {
    updateWebsiteServiceName(websiteServiceId: $websiteServiceId, websiteName: $websiteName) {
      id
      websiteName
    }
  }
`;

// Add this mutation definition
const DELETE_WORK_ITEM = gql`
  mutation DeleteWorkItem($milestoneId: ID!, $workItemId: ID!) {
    deleteWorkItem(milestoneId: $milestoneId, workItemId: $workItemId)
  }
`;

// Add this component to handle file preview
// const FilePreview: React.FC<{ hash: string }> = ({ hash }) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);
//   const [getFiles] = useLazyQuery(GET_FILES);

//   const [imageData, setImageData] = useState<string | null>(null);

//   useEffect(() => {
//     const loadFile = async () => {
//       setIsLoading(true);
//       setError(null);

//       try {
//         const { data } = await getFiles({
//           variables: { hashes: [hash] }
//         });

//         if (data?.getFiles?.[0]) {
//           // Directly use the base64 data
//           setImageData(`data:image/jpeg;base64,${data.getFiles[0]}`);
//         } else {
//           setError('No file data received');
//         }
//       } catch (error) {
//         console.error('Error loading file:', error);
//         setError('Failed to load file');
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     loadFile();
//   }, [hash, getFiles]);

//   if (isLoading) {
//     return (
//       <Box 
//         height="120px" 
//         bg="gray.100" 
//         display="flex" 
//         alignItems="center" 
//         justifyContent="center"
//       >
//         <Spinner size="md" />
//       </Box>
//     );
//   }

//   if (error || !imageData) {
//     return (
//       <Box 
//         height="120px" 
//         bg="gray.100" 
//         display="flex" 
//         alignItems="center" 
//         justifyContent="center"
//       >
//         <Icon as={AttachmentIcon} boxSize="6" color="gray.500" />
//       </Box>
//     );
//   }

//   return (
//     <Image
//       src={imageData}
//       alt="Attachment preview"
//       objectFit="cover"
//       width="100%"
//       height="100%"
//       onClick={() => window.open(imageData, '_blank')}
//       cursor="pointer"
//       onError={() => setError('Error displaying image')}
//     />
//   );
// };

const WebsiteServicePage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [updateWebsiteName, { loading: updateLoading }] = useMutation(UPDATE_WEBSITE_NAME);
  const bgGradient = useColorModeValue(
    'linear(to-br, gray.50, white)',
    'linear(to-br, gray.900, gray.800)'
  );
  const cardBg = useColorModeValue('white', 'gray.800');
  const shadowColor = useColorModeValue('gray.200', 'gray.900');
  const { loading, error, data, refetch } = useQuery(GET_SERVICE, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAdmin(storedPassword === "123456");
  }, []);

  useEffect(() => {
    if (data?.service?.websiteService?.websiteName) {
      setEditedName(data.service.websiteService.websiteName);
    }
  }, [data?.service?.websiteService?.websiteName]);

  const handleSave = async () => {
    try {
      await updateWebsiteName({
        variables: {
          websiteServiceId: data.service.websiteService.id,
          websiteName: editedName
        },
        refetchQueries: [
          {
            query: GET_SERVICE,
            variables: { id }
          }
        ]
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating website name:', error);
    }
  };

  // Now we can have our conditional returns
  if (!id) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Alert status="error">
            <AlertIcon />
            No service ID provided
          </Alert>
        </Container>
      </>
    );
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  if (loading) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <VStack spacing={4} align="stretch">
            <Skeleton height="40px" />
            <Skeleton height="200px" />
          </VStack>
        </Container>
      </>
    );
  }

  if (error) {
    console.error('GraphQL Error:', error);
    return <></>;
  }

  if (!data?.service || !data.service.websiteService) {
    console.log('No service data found:', { data });
    return <></>;
  }

  const service = data.service;
  const website = service.websiteService;

  // Log structured data for easier viewing
  console.log('Rendered Service Data:', {
    serviceId: service.id,
    serviceType: service.type,
    serviceStatus: service.status,
    websiteName: website.websiteName,
    websiteType: website.type,
    milestonesCount: website.milestones?.length,
    milestones: website.milestones?.map((m: WebsiteMilestone) => ({
      id: m.id,
      title: m.title,
      status: m.status,
      workItemsCount: m.workItems?.length,
      workItems: m.workItems?.map(wi => ({
        id: wi.id,
        title: wi.title,
        status: wi.status,
        attachments: wi.attachments?.map(a => ({
          id: a.id,
          url: a.url,
          type: a.type,
          caption: a.caption
        })),
        comments: wi.comments?.map(c => ({
          id: c.id,
          content: c.content,
          author: c.author
        })),
        lineItems: wi.lineItems?.map(li => ({
          id: li.id,
          description: li.description,
          amount: li.amount
        }))
      }))
    }))
  });

  return (
    <>
      <NavbarWithCallToAction />
      <Box
        bgGradient={bgGradient}
        minH="100vh"
        py={8}
      >
        <Container maxW="container.xl" >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <VStack spacing={8} align="stretch">
              {/* Existing Service Details Card */}
              <Card
                bg={cardBg}
                backdropFilter="blur(10px)"
                borderRadius="2xl" 
                boxShadow={`0 8px 32px rgba(0, 0, 0, 0.12)`}
                border="1px solid"
                borderColor="gray.100"
                overflow="hidden"
                transition="all 0.2s"
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "xl"
                }}
              >
                <CardHeader>
                  <HStack justify="space-between" align="center" wrap="wrap">
                    <VStack align="start" spacing={2}>
                      <Text 
                        fontSize="sm" 
                        color="gray.500"
                        textTransform="uppercase"
                        letterSpacing="wider"
                      >
                        Website Service
                      </Text>
                      <Heading size="lg">
                        {isEditing ? (
                          <Input
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                            size="lg"
                            variant="unstyled"
                            fontWeight="bold"
                            fontSize="2xl"
                          />
                        ) : (
                          <motion.span
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2 }}
                          >
                            {data?.service?.websiteService?.websiteName}
                          </motion.span>
                        )}
                      </Heading>
                    </VStack>
                    {isAdmin && (
                      <IconButton
                        aria-label="Edit name"
                        icon={isEditing ? <CheckIcon /> : <EditIcon />}
                        variant="ghost"
                        onClick={isEditing ? handleSave : () => setIsEditing(true)}
                        isLoading={updateLoading}
                        _hover={{
                          bg: "gray.100"
                        }}
                      />
                    )}
                  </HStack>
                </CardHeader>
                <CardBody>
                  <Grid templateColumns={{
                    base: "1fr",
                    md: "repeat(2, 1fr)"
                  }} gap={8}>
                    {/* Left Column */}
                    <GridItem>
                      <VStack align="stretch" spacing={6}>
                        <Box 
                          p={6}
                          bg="gray.50" 
                          borderRadius="xl"
                        >
                          <Box mb={4}>
                            <HStack
                              justify="space-between"
                              align="center"
                              width="100%"
                              flexWrap="wrap"
                              spacing={2}
                            >
                              {isEditing ? (
                                <Input
                                  value={editedName}
                                  onChange={(e) => setEditedName(e.target.value)}
                                  maxW={{ base: "100%", sm: "300px", md: "400px" }}
                                  size="sm"
                                />
                              ) : (
                                <div></div>
                              )}
                            </HStack>
                          </Box>
                          <Box mb={4}>
                            <Text fontWeight="semibold" color="gray.700" mb={2}>Type</Text>
                            <Text>{website.type}</Text>
                          </Box>
                        </Box>

                        {/* Service Details Section */}
                        <Box 
                          p={6}
                          bg="gray.50"
                          borderRadius="xl"
                        >
                          <Text fontWeight="semibold" fontSize="lg" mb={4}>Service Details</Text>
                          <VStack spacing={4} align="stretch">
                            <Box>
                              <Text fontWeight="semibold" color="gray.700" mb={2}>Status</Text>
                              <Badge 
                                colorScheme="blue" 
                                px={3} 
                                py={1} 
                                borderRadius="full"
                              >
                                {service.status}
                              </Badge>
                            </Box>
                            <Box>
                              <Text fontWeight="semibold" color="gray.700" mb={2}>Start Date</Text>
                              <Text>{formatDate(service.serviceStartDate)}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight="semibold" color="gray.700" mb={2}>Job Summary</Text>
                              <Text>{service.jobSummary || 'No summary available'}</Text>
                            </Box>
                          </VStack>
                        </Box>
                      </VStack>
                    </GridItem>

                    {/* Right Column */}
                    <GridItem>
                      <Box
                        p={6}
                        bg="gray.50"
                        borderRadius="xl"
                        h="100%"
                      >
                        <Text fontWeight="semibold" fontSize="lg" mb={4}>Client Information</Text>
                        {service.client ? (
                          <VStack align="stretch" spacing={4}>
                            <Box>
                              <Text fontWeight="semibold" color="gray.700" mb={2}>Name</Text>
                              <Text>{service.client.fName} {service.client.lName}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight="semibold" color="gray.700" mb={2}>Email</Text>
                              <Text>{service.client.email}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight="semibold" color="gray.700" mb={2}>Phone</Text>
                              <Text>{service.client.phoneNumber}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight="semibold" color="gray.700" mb={2}>Client ID</Text>
                              <Text>{service.client.id}</Text>
                            </Box>
                          </VStack>
                        ) : (
                          <Text color="gray.500">No client information available</Text>
                        )}
                      </Box>
                    </GridItem>
                  </Grid>
                </CardBody>

                {data?.service && (
                  <Box mb={6} pb={6} px={6}>
                    <ServiceProgressBar
                      serviceId={data?.service?.id || ''}
                      currentProgress={data?.service?.progress || 0}
                      onProgressUpdate={() => refetch()}
                      isAdmin={isAdmin}
                    />
                  </Box>
                )}
              </Card>

              {/* Add Progress Bar near the top */}


              {/* Add this new card to display milestones */}
              <Card
                bg={cardBg}
                backdropFilter="blur(10px)"
                borderRadius="2xl"
                boxShadow={`0 8px 32px rgba(0, 0, 0, 0.12)`}
                border="1px solid"
                borderColor="gray.100"
                overflow="hidden"
                transition="all 0.2s"
                _hover={{
                  transform: "translateY(-2px)",
                  boxShadow: "xl"
                }}
              >
                <CardHeader>
                  <HStack justify="space-between" align="center">
                    <VStack align="start" spacing={2}>
                      <Text
                        fontSize="sm"
                        color="gray.500" 
                        textTransform="uppercase"
                        letterSpacing="wider"
                      >
                        Project Timeline
                      </Text>
                      <Heading size="lg">Milestones</Heading>
                    </VStack>
                  </HStack>
                </CardHeader>
                <CardBody>
                  {data?.service?.websiteService?.milestones?.length ? (
                    <VStack spacing={6} align="stretch">
                      {[...data.service.websiteService.milestones]
                        .sort((a: WebsiteMilestone, b: WebsiteMilestone) => a.order - b.order)
                        .map((milestone: WebsiteMilestone) => (
                          <motion.div
                            key={milestone.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.4 }}
                          >
                            <MilestoneCard
                              milestone={milestone}
                              websiteServiceId={data.service.websiteService.id}
                              isAdmin={isAdmin}
                            />
                          </motion.div>
                        ))}
                    </VStack>
                  ) : (
                    <Box
                      p={8}
                      textAlign="center"
                      bg="gray.50"
                      borderRadius="xl"
                    >
                      <Text color="gray.500" fontSize="lg">No milestones added yet.</Text>
                    </Box>
                  )}
                </CardBody>
              </Card>

              {/* Add Milestone Form */}
              {isAdmin && data?.service?.websiteService?.id && (
                <AddMilestoneForm
                  serviceId={data.service.websiteService.id}
                />
              )}


            </VStack>
          </motion.div>
        </Container>
      </Box>
      <FooterWithFourColumns />
    </>
  );
};

export default WebsiteServicePage; 