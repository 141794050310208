import { BoxProps, Divider, Stack, Text, Badge, Box } from '@chakra-ui/react'
import { StepCircle } from './StepCircle'
import { Step as StepType } from './data'

interface StepProps extends BoxProps {
  step: StepType;
  isActive: boolean;
  isCompleted: boolean;
  isLastStep: boolean;
}

export const Step = (props: StepProps) => {
  const { 
    step,
    isActive, 
    isCompleted, 
    isLastStep,
    ...stackProps 
  } = props;

  return (
    <Stack spacing="4" direction="row" {...stackProps}>
      <Stack spacing="0" align="center">
        <StepCircle isActive={isActive} isCompleted={isCompleted} />
        <Divider
          orientation="vertical"
          borderWidth="1px"
          borderColor={isCompleted ? 'accent' : isLastStep ? 'transparent' : 'inherit'}
        />
      </Stack>
      <Stack spacing="0.5" pb={isLastStep ? '0' : '8'} width="full">
        <Text color="fg.emphasized" fontWeight="medium">
          {step.title}
        </Text>
        <Text color="fg.muted">{step.description}</Text>
        
        {step.completedDate && (
          <Text color="fg.muted">
            Completed: {new Date(step.completedDate).toLocaleDateString()}
          </Text>
        )}

        <Badge colorScheme={isCompleted ? 'green' : isActive ? 'blue' : 'gray'}>
          {step.status.charAt(0).toUpperCase() + step.status.slice(1).toLowerCase()}
        </Badge>
      </Stack>
    </Stack>
  )
}
