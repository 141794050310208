import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Divider,
  Grid,
  GridItem,
  Skeleton,
  Alert,
  AlertIcon,
  Button,
  useToast,
  Textarea,
  HStack,
  Select,
  Input,
  Image,
  Link as ChakraLink,
  Link as RouterLink,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { Link } from 'react-router-dom';

enum StepStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED'
}

interface StepProgress {
  stepId: string;
  step: HDWalletSetupStep;
  status: StepStatus;
  completedAt?: string;
  notes?: string;
}



const GET_SERVICE = gql`
  query GetService($id: ID!) {
    service(id: $id) {
      id
      status
      serviceStartDate
      jobSummary
      type
      tenantId
      createdAt
      updatedAt
      client {
        id
        fName
        lName
        email
        phoneNumber
        tenantId
        createdAt
        updatedAt
      }
      hdWalletService {
        id
        startDate
        type
        currentStepId
        progress {
          stepId
          step
          status
          completedAt
          notes
        }
        completionHash
        completedAt
        walletType
        currentStep
        createdAt
        updatedAt
        tenantId
      }
    }
  }
`;

const COMPLETE_STEP = gql`
  mutation CompleteStep($hdWalletServiceId: ID!, $input: CompleteStepInput!) {
    completeStep(hdWalletServiceId: $hdWalletServiceId, input: $input) {
      id
      currentStep
      progress {
        stepId
        step
        status
        completedAt
        notes
      }
    }
  }
`;

enum HDWalletSetupStep {
  WALLET_CONSULTATION = 'WALLET_CONSULTATION',
  WALLET_SELECTION = 'WALLET_SELECTION',
  WALLET_RECEIVED = 'WALLET_RECEIVED',
  WALLET_AUTHENTICATION = 'WALLET_AUTHENTICATION',
  WALLET_SETUP = 'WALLET_SETUP',
  SEED_GENERATION = 'SEED_GENERATION',
  ADDRESS_GENERATION = 'ADDRESS_GENERATION',
  FIRST_DEPOSIT = 'FIRST_DEPOSIT',
  FIRST_TRANSACTION = 'FIRST_TRANSACTION'
}

const WALLET_SETUP_STEPS: StepConfig[] = [
  {
    step: HDWalletSetupStep.WALLET_CONSULTATION,
    label: "Wallet Consultation",
    description: "Initial consultation to determine wallet needs",
    defaultNotes: "Client consultation completed. Discussed security needs and budget constraints."
  },
  {
    step: HDWalletSetupStep.WALLET_SELECTION,
    label: "Wallet Selection",
    description: "Choose appropriate hardware wallet",
    defaultNotes: "Selected appropriate wallet based on client needs"
  },
  {
    step: HDWalletSetupStep.WALLET_RECEIVED,
    label: "Wallet Received",
    description: "Confirm wallet delivery and authenticity",
    defaultNotes: "Wallet received and authenticity verified"
  },
  {
    step: HDWalletSetupStep.WALLET_AUTHENTICATION,
    label: "Wallet Authentication",
    description: "Set up wallet authentication",
    defaultNotes: "Authentication configured securely"
  },
  {
    step: HDWalletSetupStep.WALLET_SETUP,
    label: "Wallet Setup",
    description: "Configure wallet settings",
    defaultNotes: "Basic wallet configuration completed"
  },
  {
    step: HDWalletSetupStep.SEED_GENERATION,
    label: "Seed Generation",
    description: "Generate and secure seed phrase",
    defaultNotes: "Seed phrase generated and secured"
  },
  {
    step: HDWalletSetupStep.ADDRESS_GENERATION,
    label: "Address Generation",
    description: "Generate wallet addresses",
    defaultNotes: "First addresses generated successfully"
  },
  {
    step: HDWalletSetupStep.FIRST_DEPOSIT,
    label: "First Deposit",
    description: "Complete first test deposit",
    defaultNotes: "Test deposit completed successfully"
  },
  {
    step: HDWalletSetupStep.FIRST_TRANSACTION,
    label: "First Transaction",
    description: "Complete first test transaction",
    defaultNotes: "Test transaction completed successfully"
  }
];

interface StepConfig {
  step: HDWalletSetupStep;
  label: string;
  description: string;
  defaultNotes: string;
}

enum WalletBrand {
  TREZOR = 'TREZOR',
  SAFEPAL = 'SAFEPAL',
  COOLWALLET = 'COOLWALLET',
  BITBOX = 'BITBOX',
  CRYPTOTAG = 'CRYPTOTAG',
  CRYPTOSTEEL = 'CRYPTOSTEEL',
  DCENT = 'DCENT',
  ELLIPAL = 'ELLIPAL',
  KEEPKEY = 'KEEPKEY',
  NGRAVE = 'NGRAVE',
  GRIDPLUS = 'GRIDPLUS',
  CYPHEROCK = 'CYPHEROCK',
  TANGEM = 'TANGEM',
  LEDGER = 'LEDGER'
}

const WALLET_DESCRIPTIONS: Record<WalletBrand, string> = {
  [WalletBrand.TREZOR]: 'One of the pioneers in hardware wallets, offering models like the Trezor Model T and Trezor Model One.',
  [WalletBrand.SAFEPAL]: 'Supports over 100 blockchain networks with self-destruct mechanism for added security.',
  [WalletBrand.COOLWALLET]: 'Credit card-sized hardware wallet convenient for mobile users.',
  [WalletBrand.BITBOX]: 'Compact and secure wallet focusing on ease of use and security.',
  [WalletBrand.CRYPTOTAG]: 'Titanium backup solution for hardware wallets.',
  [WalletBrand.CRYPTOSTEEL]: 'Stainless-steel backup tool for seed phrases.',
  [WalletBrand.DCENT]: 'Biometric hardware wallets with fingerprint scanning.',
  [WalletBrand.ELLIPAL]: 'Air-gapped hardware wallet with anti-tamper technology.',
  [WalletBrand.KEEPKEY]: 'Integrates with ShapeShift platform for easy trading.',
  [WalletBrand.NGRAVE]: 'High-security wallet for institutional investors.',
  [WalletBrand.GRIDPLUS]: 'Lattice1 hardware wallet focusing on user experience.',
  [WalletBrand.CYPHEROCK]: 'Combines security with ease of use.',
  [WalletBrand.TANGEM]: 'Smart card-based hardware wallet solution.',
  [WalletBrand.LEDGER]: 'Hardware wallet with a focus on simplicity and security.'
};

const UPDATE_SERVICE = gql`
  mutation UpdateService($id: ID!, $input: UpdateServiceInput!) {
  
   
    updateService(id: $id, input: $input) {
      id
      status
      serviceStartDate
      jobSummary
    }
  }
`;

const UPDATE_HD_WALLET_SERVICE = gql`
  mutation UpdateHDWalletService($id: ID!, $input: UpdateHDWalletServiceInput!) {
    updateHDWalletService(id: $id, input: $input) {
      id
      walletType
      currentStep
      currentStepId
      progress {
        stepId
        step
        status
        completedAt
        notes
      }
      completedAt
      completionHash
      startDate
    }
  }
`;

const UPDATE_WALLET_TYPE = gql`
  mutation UpdateWalletType($id: ID!, $input: UpdateWalletTypeInput!) {
    updateWalletType(id: $id, input: $input) {
      id
      walletType
      updatedAt
    }
  }
`;


const GET_BILLS = gql`
  query GetBillsByServiceId($serviceId: ID!) {
    billsByServiceId(serviceId: $serviceId) {
      id
      isPaid
      paymentMethod
      totalAmount
      createdAt
      updatedAt
      lineItems {
        id
        description
        amount
      }
      service {
        id
        client {
          id
          fName
          lName
          email
          phoneNumber
        }
      }
    }
  }
`;


interface ClientInformationProps {
  tenantId: string;
  id: string;
  isAdmin: boolean;
}

const ClientInformation = ({ tenantId, id, isAdmin }: ClientInformationProps) => {
  const { loading, error, data } = useQuery(GET_SERVICE, {
    variables: { id }
  });

  if (!id) return null;
  if (loading) return <Skeleton height="100px" />;
  if (error) return (
    <Alert status="error">
      <AlertIcon />
      Error loading client information: {error.message}
    </Alert>
  );

  if (!data?.service) {
    return (
      <Alert status="info">
        <AlertIcon />
        No client information found
      </Alert>
    );
  }

  const client = data.service.client;

  return (
    <VStack align="stretch" spacing={3}>
      <Box>
        <Text fontSize="sm" color="gray.600">Name</Text>
        {isAdmin ? (
          <Link to={`/client/${client.id}`}>
            <Text
              color="blue.500"
              fontWeight="medium"
              _hover={{ textDecoration: 'underline' }}
            >
              {client.fName} {client.lName}
            </Text>
          </Link>
        ) : (
          <Text fontWeight="medium">
            {client.fName} {client.lName}
          </Text>
        )}
      </Box>
      <Box>
        <Text fontSize="sm" color="gray.600">Email</Text>
        <Text fontWeight="medium">{client.email}</Text>
      </Box>
      <Box>
        <Text fontSize="sm" color="gray.600">Phone</Text>
        <Text fontWeight="medium">{client.phoneNumber}</Text>
      </Box>
      <Box>
        <Text fontSize="sm" color="gray.600">Client Since</Text>
        <Text fontWeight="medium">
          {new Date(client.createdAt).toLocaleDateString()}
        </Text>
      </Box>
      <Divider />
      <Text fontSize="xs" color="gray.500">
        Client ID: {client.id}
      </Text>
      <Text fontSize="xs" color="gray.500">
        Tenant ID: {tenantId}
      </Text>



    </VStack>
  );
};

enum ServiceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  SUSPENDED = "SUSPENDED",
  PENDING = "PENDING",
  TERMINATED = "TERMINATED",
  COMPLETED = "COMPLETED"
}

const formatDateForInput = (dateString: string) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ''; // Return empty string if invalid date
  return date.toISOString().slice(0, 16); // Format as YYYY-MM-DDTHH:mm
};

const HDWalletServicePage = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useQuery(GET_SERVICE, {
    variables: { id }
  });

  const [completeStep] = useMutation(COMPLETE_STEP);
  const [updateService] = useMutation(UPDATE_SERVICE);
  const [updateHDWalletService] = useMutation(UPDATE_HD_WALLET_SERVICE, {
    onCompleted: (data) => {
      console.log('Server response:', data);
    }
  });
  const [updateWalletType] = useMutation(UPDATE_WALLET_TYPE);
  const toast = useToast();

  const [customNotes, setCustomNotes] = useState<string>("");
  const [selectedWallet, setSelectedWallet] = useState<WalletBrand | ''>('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedService, setEditedService] = useState({
    walletType: '',
    status: '',
    startDate: '',
    jobSummary: ''
  });
  const [isEditingHDWallet, setIsEditingHDWallet] = useState(false);
  const [isEditingServiceDetails, setIsEditingServiceDetails] = useState(false);
  const [editedHDWallet, setEditedHDWallet] = useState({
    walletType: ''
  });
  const [editedServiceDetails, setEditedServiceDetails] = useState({
    jobSummary: '',
    serviceStartDate: '',
    status: ''
  });

  const [isAdmin, setIsAdmin] = useState(false);

  const { data: billsData, loading: billsLoading } = useQuery(GET_BILLS, {
    variables: { serviceId: id }
  });

  // Add this useEffect after the other state declarations
  useEffect(() => {
    const storedPassword = localStorage.getItem("admin_password");
    setIsAdmin(storedPassword === "123456");
  }, []);

  if (!id) {
    return <div>No ID provided</div>;
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const getStepStatus = (status: string) => {
    switch (status) {
      case 'COMPLETED':
        return 'complete';
      case 'ACTIVE':
        return 'current';
      case 'VERIFIED':
        return 'complete';
      default:
        return 'incomplete';
    }
  };

  const handleCompleteStep = async (stepConfig: StepConfig) => {
    try {
      const stepId = `${stepConfig.step.toLowerCase()}-1`;
      const defaultNotes = WALLET_SETUP_STEPS.find(s => s.step === stepConfig.step)?.defaultNotes || '';

      const notes = stepConfig.step === HDWalletSetupStep.WALLET_SELECTION && selectedWallet
        ? `Selected ${selectedWallet} wallet. ${customNotes || defaultNotes}`
        : customNotes || defaultNotes;

      // Create the new progress step
      const newProgress = {
        stepId,
        step: stepConfig.step,
        status: StepStatus.COMPLETED,
        completedAt: new Date().toISOString(),
        notes
      };

      // Prepare variables for the mutation
      const variables = {
        id: data.service.hdWalletService.id,
        input: {
          currentStep: stepConfig.step,
          currentStepId: stepId,
          progress: [newProgress], // This will be pushed to the existing progress array
          // Preserve existing values
          walletType: hdWallet.walletType,
          startDate: hdWallet.startDate,
          completedAt: hdWallet.completedAt,
          completionHash: hdWallet.completionHash
        }
      };

      console.log('Sending step completion to server:', variables);

      const result = await updateHDWalletService({
        variables
      });

      setCustomNotes("");
      if (stepConfig.step === HDWalletSetupStep.WALLET_SELECTION) {
        setSelectedWallet('');
      }

      toast({
        title: "Step Completed",
        description: `Successfully completed ${stepConfig.label}`,
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error('Error completing step:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to complete step",
        status: "error",
        duration: 5000,
      });
    }
  };

  if (loading) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <VStack spacing={4} align="stretch">
            <Skeleton height="40px" />
            <Skeleton height="200px" />
            <Skeleton height="200px" />
          </VStack>
        </Container>
      </>
    );
  }

  if (data?.service) {
    console.log('Full Service Object:', {
      ...data.service,
      _typename: undefined
    });
  }


  if (error) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Alert status="error">
            <AlertIcon />
            Error: {error.message}
          </Alert>
        </Container>
      </>
    );
  }

  if (!data?.service) {
    return (
      <>
        <NavbarWithCallToAction />
        <Container maxW="container.xl" py={8}>
          <Alert status="warning">
            <AlertIcon />
            HD Wallet Service not found
          </Alert>
        </Container>
      </>
    );
  }

  const service = data.service;
  const hdWallet = service.hdWalletService;
  const client = service.client;

  return (
    <>
      <NavbarWithCallToAction />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={6} align="stretch">
          <Card>
            <CardHeader>
              <VStack align="start" spacing={2}>
                <Heading size="lg">HD Wallet Service Details</Heading>
                <Text fontSize="sm" color="gray.500">
                  Service ID: {hdWallet.id}
                </Text>
              </VStack>
            </CardHeader>
            <CardBody>
              <Grid 
                templateColumns={{
                  base: "1fr",            // Single column on mobile
                  md: "repeat(2, 1fr)"    // Two columns on medium screens and up
                }} 
                gap={6}
              >
                <GridItem>
                  <VStack align="stretch" spacing={4}>
                    <Box>
                      <HStack justify="space-between" mb={4}>
                        <Text fontWeight="bold">HD Wallet Details</Text>
                        {!isEditingHDWallet && isAdmin ? (
                          <Button
                            size="sm"
                            leftIcon={<EditIcon />}
                            onClick={() => {
                              setIsEditingHDWallet(true);
                              setEditedHDWallet({
                                walletType: hdWallet.walletType
                              });
                            }}
                          >
                            Edit HD Wallet Details
                          </Button>
                        ) : isEditingHDWallet && (
                          <HStack>
                            <Button
                              size="sm"
                              colorScheme="green"
                              onClick={async () => {
                                try {
                                  const variables = {
                                    id: hdWallet.id,
                                    input: {
                                      walletType: editedHDWallet.walletType
                                    }
                                  };
                                  console.log('Sending wallet type update to server:', variables);
                                  await updateWalletType({ variables });
                                  setIsEditingHDWallet(false);
                                  toast({
                                    title: "Success",
                                    description: "Wallet type updated successfully",
                                    status: "success",
                                  });
                                } catch (error) {
                                  toast({
                                    title: "Error",
                                    description: error instanceof Error ? error.message : "Failed to update wallet type",
                                    status: "error",
                                  });
                                }
                              }}
                            >
                              Save Changes
                            </Button>
                            <Button
                              size="sm"
                              onClick={() => setIsEditingHDWallet(false)}
                            >
                              Cancel
                            </Button>
                          </HStack>
                        )}
                      </HStack>

                      <Box>
                        <Text fontWeight="bold">Wallet Type</Text>
                        {isEditingHDWallet ? (
                          <Select
                            value={editedHDWallet.walletType}
                            onChange={(e) => setEditedHDWallet({
                              ...editedHDWallet,
                              walletType: e.target.value
                            })}
                          >
                            {Object.values(WalletBrand).map((type) => (
                              <option key={type} value={type}>{type}</option>
                            ))}
                          </Select>
                        ) : (
                          <VStack align="start" spacing={2}>
                            <Badge colorScheme="purple" fontSize="0.9em">
                              {hdWallet.walletType}
                            </Badge>
                            {hdWallet.walletType && (
                              <Box mt={2}>
                                <Image
                                  src={`/wallets/${hdWallet.walletType.toLowerCase()}.${['COOLWALLET', 'KEEPKEY', 'TANGEM'].includes(hdWallet.walletType) ? 'jpg' : 'png'
                                    }`}
                                  alt={hdWallet.walletType}
                                  maxH="200px"
                                  objectFit="contain"
                                  fallback={
                                    <Text color="gray.500" fontSize="sm">
                                      Image not available
                                    </Text>
                                  }
                                />
                                <Text mt={2} fontSize="sm" color="gray.600">
                                  {WALLET_DESCRIPTIONS[hdWallet.walletType as WalletBrand]}
                                </Text>
                              </Box>
                            )}
                          </VStack>
                        )}
                      </Box>
                    </Box>

                    {/* Service Details Section */}
                    <Box mt={6}>
                      <HStack justify="space-between" mb={4}>
                        <Text fontWeight="bold">Service Details</Text>
                        {!isEditingServiceDetails && isAdmin && (
                          <Button
                            size="sm"
                            leftIcon={<EditIcon />}
                            onClick={() => {
                              setIsEditingServiceDetails(true);
                              setEditedServiceDetails({
                                jobSummary: service.jobSummary || '',
                                serviceStartDate: service.serviceStartDate,
                                status: service.status
                              });
                            }}
                          >
                            Edit Service Details
                          </Button>
                        )}
                        {isEditingServiceDetails && (
                          <HStack>
                            <Button
                              size="sm"
                              colorScheme="green"
                              onClick={async () => {
                                try {
                                  await updateService({
                                    variables: {
                                      id: service.id,
                                      input: {
                                        jobSummary: editedServiceDetails.jobSummary,
                                        serviceStartDate: editedServiceDetails.serviceStartDate,
                                        status: editedServiceDetails.status
                                      }
                                    }
                                  });
                                  setIsEditingServiceDetails(false);
                                  toast({
                                    title: "Success",
                                    description: "Service details updated",
                                    status: "success",
                                  });
                                } catch (error) {
                                  toast({
                                    title: "Error",
                                    description: "Failed to update service details",
                                    status: "error",
                                  });
                                }
                              }}
                            >
                              Save Changes
                            </Button>
                            <Button
                              size="sm"
                              onClick={() => setIsEditingServiceDetails(false)}
                            >
                              Cancel
                            </Button>
                          </HStack>
                        )}
                      </HStack>

                      <Box>
                        <Text fontWeight="bold">Status</Text>
                        {isEditingServiceDetails ? (
                          <Select
                            value={editedServiceDetails.status}
                            onChange={(e) => setEditedServiceDetails({
                              ...editedServiceDetails,
                              status: e.target.value
                            })}
                          >
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                            <option value="SUSPENDED">SUSPENDED</option>
                            <option value="PENDING">PENDING</option>
                            <option value="TERMINATED">TERMINATED</option>
                            <option value="COMPLETED">COMPLETED</option>
                          </Select>
                        ) : (
                          <Badge colorScheme="blue" fontSize="0.9em">
                            {service.status}
                          </Badge>
                        )}
                      </Box>

                      <Box mt={4}>
                        <Text fontWeight="bold">Start Date</Text>
                        {isEditingServiceDetails ? (
                          <Input
                            type="datetime-local"
                            value={formatDateForInput(editedServiceDetails.serviceStartDate)}
                            onChange={(e) => setEditedServiceDetails({
                              ...editedServiceDetails,
                              serviceStartDate: e.target.value
                            })}
                          />
                        ) : (
                          <Text>
                            {service.serviceStartDate ?
                              new Date(service.serviceStartDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                timeZoneName: 'short'
                              })
                              : 'No date available'}
                          </Text>
                        )}
                      </Box>

                      <Box mt={4}>
                        <Text fontWeight="bold">Job Summary</Text>
                        {isEditingServiceDetails ? (
                          <Textarea
                            value={editedServiceDetails.jobSummary}
                            onChange={(e) => setEditedServiceDetails({
                              ...editedServiceDetails,
                              jobSummary: e.target.value
                            })}
                            placeholder="Enter job summary"
                          />
                        ) : (
                          <Text>{service.jobSummary || 'No summary available'} </Text>
                        )}
                      </Box>
                    </Box>
                  </VStack>
                </GridItem>
                <GridItem>
                  <VStack align="stretch" spacing={4}>
                    {hdWallet.tenantId && (
                      <Box>
                        <Text fontWeight="bold" mb={4}>Client Information</Text>
                        <ClientInformation
                          tenantId={hdWallet.tenantId}
                          id={id}
                          isAdmin={isAdmin}
                        />
                      </Box>
                    )}
                  </VStack>
                </GridItem>
              </Grid>

              <Divider my={6} />

              <Box>
                <Heading size="md" mb={4}>Wallet Setup Progress</Heading>
                <VStack spacing={4} align="stretch">
                  {WALLET_SETUP_STEPS.map((stepConfig, index) => {
                    const stepProgress = hdWallet.progress.find(
                      (p: StepProgress) => p.step === stepConfig.step
                    );
                    const isCompleted = stepProgress?.status === StepStatus.COMPLETED;
                    const previousStepCompleted = index === 0 || hdWallet.progress.find(
                      (p: StepProgress) => p.step === WALLET_SETUP_STEPS[index - 1].step
                    )?.status === StepStatus.COMPLETED;
                    const isActive = !isCompleted && previousStepCompleted;

                    return (
                      <Card
                        key={stepConfig.step}
                        borderWidth="1px"
                        borderColor={
                          isCompleted ? "green.500" :
                            isActive ? "blue.500" :
                              "gray.200"
                        }
                        bg={
                          isCompleted ? "green.50" :
                            isActive ? "blue.50" :
                              "gray.50"
                        }
                        opacity={!isCompleted && !isActive ? 0.7 : 1}
                      >
                        <CardBody>
                          <HStack justify="space-between" align="start">
                            <VStack align="start" spacing={2}>
                              <Badge
                                colorScheme={isCompleted ? "green" : isActive ? "blue" : "gray"}
                                fontSize="sm"
                              >
                                {isCompleted ? "✓ Completed" : isActive ? "Current Step" : `Step ${index + 1}`}
                              </Badge>
                              <Heading size="sm">{stepConfig.label}</Heading>
                              <Text color="gray.600">{stepConfig.description}</Text>
                              {stepProgress?.notes && (
                                <Text fontSize="sm" color="gray.500">
                                  Notes: {stepProgress.notes}
                                </Text>
                              )}
                              {stepProgress?.completedAt && (
                                <Text fontSize="sm" color="gray.500">
                                  Completed: {formatDate(stepProgress.completedAt)}
                                </Text>
                              )}
                            </VStack>
                            {isActive && !isCompleted && (
                              <VStack spacing={4} width="300px">
                                <Textarea
                                  placeholder="Add custom notes (optional)"
                                  value={customNotes}
                                  onChange={(e) => setCustomNotes(e.target.value)}
                                  size="sm"
                                />
                                <Button
                                  colorScheme="blue"
                                  onClick={() => handleCompleteStep(stepConfig)}
                                  width="full"
                                >
                                  Complete Step
                                </Button>
                              </VStack>
                            )}
                          </HStack>
                        </CardBody>
                      </Card>
                    );
                  })}
                </VStack>
              </Box>
            </CardBody>
          </Card>

          <Divider my={6} />
          <Box>
            <Heading size="md" mb={4}>Billing History</Heading>
            {billsLoading ? (
              <Skeleton height="100px" />
            ) : billsData?.billsByServiceId?.length ? (
              <VStack spacing={4} align="stretch">
                {billsData.billsByServiceId.map((bill: any) => (
                  <Card 
                    key={bill.id}
                    as={Link}
                    to={`/bill/${bill.id}`}
                    _hover={{ 
                      shadow: 'md',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.2s'
                    }}
                  >
                    <CardBody>
                      <HStack justify="space-between" mb={3}>
                        <VStack align="start" spacing={1}>
                          <Badge colorScheme={bill.isPaid ? "green" : "red"}>
                            {bill.isPaid ? "Paid" : "Unpaid"}
                          </Badge>
                          <Text fontSize="sm" color="gray.600">
                            {new Date(bill.createdAt).toLocaleDateString()}
                          </Text>
                        </VStack>
                        <Text fontWeight="bold">
                          Total: ${bill.totalAmount.toFixed(2)}
                        </Text>
                      </HStack>
                      
                      {bill.lineItems?.length > 0 && (
                        <Box>
                          <Text fontSize="sm" fontWeight="medium" mb={2}>Line Items:</Text>
                          <VStack align="stretch" spacing={2}>
                            {bill.lineItems.map((item: any) => (
                              <HStack key={item.id} justify="space-between">
                                <Text fontSize="sm">{item.description}</Text>
                                <Text fontSize="sm">${item.amount.toFixed(2)}</Text>
                              </HStack>
                            ))}
                          </VStack>
                        </Box>
                      )}

                      {bill.paymentMethod && (
                        <Text fontSize="sm" color="gray.600" mt={2}>
                          Payment Method: {bill.paymentMethod}
                        </Text>
                      )}
                    </CardBody>
                  </Card>
                ))}
              </VStack>
            ) : (
              <Alert status="info">
                <AlertIcon />
                No bills found for this service
              </Alert>
            )}
          </Box>
        </VStack>
      </Container>
      <FooterWithFourColumns />
    </>
  );
};

export default HDWalletServicePage; 