import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { Center, Spinner, Image, useToast, Box, Alert, AlertIcon } from '@chakra-ui/react';

const GET_FILE = gql`
  query GetFile($hash: String!) {
    getFile(hash: $hash)
  }
`;

const ViewFile: React.FC = () => {
  const { hash } = useParams();
  const [fileContent, setFileContent] = useState<string | null>(null);
  const toast = useToast();
  
  const [getFile, { loading, error }] = useLazyQuery(GET_FILE, {
    onCompleted: (data) => {
      if (data?.getFile) {
        setFileContent(data.getFile);
      }
    },
    onError: (error) => {
      console.error('Download error:', error);
      toast({
        title: "Failed to load file",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    }
  });

  useEffect(() => {
    if (hash) {
      getFile({ variables: { hash } });
    }
  }, [hash, getFile]);

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center h="100vh">
        <Alert status="error">
          <AlertIcon />
          Error loading file: {error.message}
        </Alert>
      </Center>
    );
  }

  return (
    <Box w="100%" h="100vh" bg="gray.100">
      {fileContent && (
        <Center h="100%">
          <Box boxShadow="lg" bg="white" p={2}>
            <Image
              src={`data:image/png;base64,${fileContent}`}
              alt="File content"
              maxH="90vh"
              maxW="90vw"
              objectFit="contain"
            />
          </Box>
        </Center>
      )}
    </Box>
  );
};

export default ViewFile; 