import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Container,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavigationButtons } from "../components/NavigationButtons";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";

interface FormData {
  email: string;
  fName: string;
  lName: string;
  phoneNumber: string;
}

const initialFormData: FormData = {
  email: "",
  fName: "",
  lName: "",
  phoneNumber: "",
};

const CREATE_CLIENT = gql`
  mutation CreateClient($input: ClientInput!) {
    createClient(input: $input) {
      id
      fName
      lName
      email
      phoneNumber
      createdAt
      updatedAt
    }
  }
`;

const phoneInputStyle = {
  '.PhoneInputCountryIcon': {
    width: '1.5em',
    height: '1.5em'
  },
  '.PhoneInputCountrySelectArrow': {
    width: '0.5em',
    height: '0.5em'
  },
  '.PhoneInputInput': {
    height: '2.5rem',
    paddingLeft: '1rem'
  }
}

const NewClientForm = () => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [createClient, { loading }] = useMutation(CREATE_CLIENT, {
    refetchQueries: ['GetClients']
  });
  const toast = useToast();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.fName.trim() || !formData.lName.trim() || !formData.email.trim()) {
      toast({
        title: "Missing Information",
        description: "Please fill in all required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.phoneNumber || 
        !formData.phoneNumber.match(/^\+[1-9]\d{1,14}$/)) {
      toast({
        title: "Invalid phone number",
        description: "Please enter a valid phone number in E.164 format (e.g., +12345678901)",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const cleanPhoneNumber = formData.phoneNumber.replace(/\s+/g, '');
      
      await createClient({
        variables: {
          input: {
            fName: formData.fName.trim(),
            lName: formData.lName.trim(),
            email: formData.email.trim(),
            phoneNumber: cleanPhoneNumber
          }
        }
      });
      
      toast({
        title: "Success!",
        description: "New client has been successfully added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      navigate("/clients");
    } catch (error) {
      console.error('Error creating client:', error);
      toast({
        title: "Error creating client",
        description: error instanceof Error 
          ? error.message 
          : "There was an error creating the client. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return ( 
    <>
    <NavbarWithCallToAction />
    <Container maxW="container.md" py={8}>
      {/* <NavigationButtons /> */}
      <Card mt={4}>
        <CardHeader>
          <Heading size="lg">New Client</Heading>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  name="fName"
                  value={formData.fName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  name="lName"
                  value={formData.lName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Phone Number</FormLabel>
                <PhoneInput
                  international
                  defaultCountry="AU"
                  value={formData.phoneNumber}
                  onChange={(value) => {
                    console.log('Phone input value:', value);
                    setFormData(prev => ({ ...prev, phoneNumber: value || '' }))
                  }}
                  placeholder="Enter phone number"
                  style={phoneInputStyle}
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="blue"
                isLoading={loading}
              >
                Create Client
              </Button>
            </Stack>
          </form>
        </CardBody>
        </Card>
      </Container>
      <FooterWithFourColumns />
    </>
  );
};

export default NewClientForm;
