import React from "react";
import {
    Box,
    Container,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Heading,
    Card,
    CardHeader,
    CardBody,
    Button,
    Skeleton,
    useToast,
    Badge,
    HStack,
    IconButton,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Tooltip,
} from "@chakra-ui/react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { ViewIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { NavigationButtons } from '../components/NavigationButtons';
import { FiDollarSign, FiCheckCircle, FiClock, FiCalendar } from "react-icons/fi";

const GET_ALL_BILLS = gql`
  query GetAllBills {
    bills {
      id
    #   status
      isPaid
      lineItems {
        id
        description
        amount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      tenantId
    }
  }
`;

const DELETE_BILL = gql`
  mutation DeleteBill($id: ID!) {
    deleteBill(id: $id)
  }
`;

interface LineItem {
    description: string;
    amount: number;
}

interface Bill {
    id: string;
    status: 'DRAFT' | 'SENT';
    isPaid: boolean;
    lineItems: {
        id: string;
        description: string;
        amount: number;
        createdAt: string;
        updatedAt: string;
    }[];
    createdAt: string;
    updatedAt: string;
    tenantId: string;
}

interface GetBillsResponse {
    bills: Bill[];
}

const StatCard = ({
    title,
    stat,
    icon,
    color
}: {
    title: string;
    stat: number | string;
    icon: React.ReactNode;
    color: string;
}) => {
    return (
        <Box
            p={6}
            bg="white"
            borderRadius="lg"
            boxShadow="sm"
            border="1px"
            borderColor="gray.100"
        >
            <HStack spacing={4}>
                <Box color={color}>
                    {icon}
                </Box>
                <Stat>
                    <StatLabel color="gray.500">{title}</StatLabel>
                    <StatNumber fontSize="2xl" fontWeight="bold">
                        {stat}
                    </StatNumber>
                </Stat>
            </HStack>
        </Box>
    );
};

const Bills = () => {
    const navigate = useNavigate();
    const { loading, error, data, refetch } = useQuery<GetBillsResponse>(GET_ALL_BILLS);
    const [deleteBill] = useMutation(DELETE_BILL);
    const toast = useToast();

    React.useEffect(() => {
        if (data) {
            console.log('GET_ALL_BILLS response:', data);
        }
    }, [data]);

    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });
    };

    const calculateTotalAmount = () => {
        return 0;
    };

    const getTotalRevenue = () => {
        return 0;
    };

    const getPaidBillsCount = () => {
        return data?.bills?.filter(bill => bill.isPaid).length || 0;
    };

    const getUnpaidBillsCount = () => {
        return data?.bills?.filter(bill => !bill.isPaid).length || 0;
    };

    const handleViewBill = (bill: Bill) => {
        navigate(`/bill/${bill.id}`);
    };

    const handleDeleteBill = async (billId: string) => {
        if (window.confirm('Are you sure you want to delete this bill?')) {
            try {
                await deleteBill({
                    variables: { id: billId }
                });
                toast({
                    title: "Bill deleted",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                refetch(); // Refresh the bills list
            } catch (error) {
                toast({
                    title: "Error deleting bill",
                    description: error instanceof Error ? error.message : "Unknown error occurred",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    if (error) {
        toast({
            title: "Error loading bills",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }

    return (
        <>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <Card>
                    <CardHeader>
                        <HStack justify="space-between">
                            <Heading size="lg">Bills</Heading>
                            <Button
                                colorScheme="blue"
                                onClick={() => navigate('/bills/new')}
                                leftIcon={<AddIcon />}
                            >
                                New Bill
                            </Button>
                        </HStack>
                    </CardHeader>

                    <Box px={6} pb={6}>
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
                            <StatCard
                                title="Total Revenue"
                                stat={`$${getTotalRevenue().toFixed(2)}`}
                                icon={<FiDollarSign size="3em" />}
                                color="green.500"
                            />
                            <StatCard
                                title="Paid Bills"
                                stat={getPaidBillsCount()}
                                icon={<FiCheckCircle size="3em" />}
                                color="blue.500"
                            />
                            <StatCard
                                title="Unpaid Bills"
                                stat={getUnpaidBillsCount()}
                                icon={<FiClock size="3em" />}
                                color="orange.500"
                            />
                            <StatCard
                                title="Total Bills"
                                stat={data?.bills?.length || 0}
                                icon={<FiCalendar size="3em" />}
                                color="purple.500"
                            />
                        </SimpleGrid>
                    </Box>

                    <CardBody>
                        <Box overflowX="auto">
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Bill ID</Th>
                                        <Th>Status</Th>
                                        <Th>Total Amount</Th>
                                        <Th>Items</Th>
                                        <Th>Payment Status</Th>
                                        <Th>Date</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {loading ? (
                                        [...Array(3)].map((_, index) => (
                                            <Tr key={`skeleton-${index}`}>
                                                <Td><Skeleton height="20px" /></Td>
                                                <Td><Skeleton height="20px" /></Td>
                                                <Td><Skeleton height="20px" /></Td>
                                                <Td><Skeleton height="20px" /></Td>
                                                <Td><Skeleton height="20px" /></Td>
                                                <Td><Skeleton height="20px" /></Td>
                                                <Td><Skeleton height="20px" /></Td>
                                            </Tr>
                                        ))
                                    ) : data?.bills?.length ? (
                                        data.bills.map((bill: Bill) => (
                                            <Tr key={bill.id}>
                                                <Td>
                                                    <Text>{bill.id}</Text>
                                                </Td>
                                                <Td>
                                                    <Badge
                                                        colorScheme={bill.status === 'SENT' ? 'blue' : 'gray'}
                                                    >
                                                        {bill.status}
                                                    </Badge>
                                                </Td>
                                                <Td>
                                                    <Text>${bill.lineItems.reduce((sum, item) => sum + item.amount, 0).toFixed(2)}</Text>
                                                </Td>
                                                <Td>
                                                    <Text>{bill.lineItems.length} items</Text>
                                                </Td>
                                                <Td>
                                                    <Badge
                                                        colorScheme={bill.isPaid ? "green" : "orange"}
                                                    >
                                                        {bill.isPaid ? "Paid" : "Pending"}
                                                    </Badge>
                                                </Td>
                                                <Td>
                                                    <Text>{formatDate(bill.createdAt)}</Text>
                                                </Td>
                                                <Td>
                                                    <HStack spacing={2}>
                                                        <Tooltip label="View bill details" hasArrow>
                                                            <IconButton
                                                                aria-label="View bill details"
                                                                icon={<ViewIcon />}
                                                                size="sm"
                                                                colorScheme="teal"
                                                                onClick={() => handleViewBill(bill)}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip label="Delete bill" hasArrow>
                                                            <IconButton
                                                                aria-label="Delete bill"
                                                                icon={<DeleteIcon />}
                                                                size="sm"
                                                                colorScheme="red"
                                                                onClick={() => handleDeleteBill(bill.id)}
                                                            />
                                                        </Tooltip>
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))
                                    ) : (
                                        <Tr>
                                            <Td colSpan={7} textAlign="center" py={8}>
                                                <Text color="gray.500">No bills found</Text>
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </Box>
                    </CardBody>
                </Card>
                <FooterWithFourColumns />
            </Container>
        </>
    );
};

export default Bills;